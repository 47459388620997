import React from 'react';
import Holder from './../Holder';
import './promoBanner.css';

export default () => <div className="promoBanner">
  <Holder className="promoBanner_holder">
    <span className="promoBanner_highlight">$40 Bonus</span>
    &nbsp;
    in Nerdify Balance
  </Holder>
</div>
