import React from 'react';

import Landing from './pages/Landing';
import Landing1a from './pages/Landing1a';
import Landing4 from './pages/Landing4';
import Landing5 from './pages/Landing5';
import Landing7 from './pages/Landing7';
import Landing7a from './pages/Landing7a';
import Landing8a from './pages/Landing8a';
import LandingForStudents from './pages/LandingForStudents';
import LandingForBusinesses from './pages/LandingForBusinesses';
import LandingForAcademia from './pages/LandingForAcademia';
import LandingClp3 from './pages/LandingClp3';
import LandingClp4 from './pages/LandingClp4';
import LandingClp4Video from './pages/LandingClp4/LandingClp4Video';

import About from './pages/About';
import Become from './pages/Become';
import Cookies from './pages/Cookies';
import HonorCode from './pages/HonorCode';
import HowItWorks from './pages/HowItWorks';
import Maintenance from './pages/Maintenance';
import NerdifyReviews from './pages/NerdifyReviews';
import NerdyBot from './pages/NerdyBot';
import Privacy from './pages/Privacy';
import Refunds from './pages/Refunds';
import ReportAbuse from './pages/ReportAbuse';
import Terms from './pages/Terms';
import TopNerds from './pages/TopNerds';
import MathonLp from './pages/Mathon/MathonLp';
import MathonPrivacyPolicy from './pages/Mathon/MathonPrivacyPolicy';
import MathonTerms from './pages/Mathon/MathonTerms';

import ThankYou from './pages/ThankYou';
import NotFound from './pages/NotFound';

import { IS_PROD } from '../../config';
import { lpdTitle } from '../../ldpTitles';
import {
  slider1Items_main,
  slider1Items_for_students,
  slider1Items_math_help,
  slider1Items_statistics_help,
  slider1Items_natural_sciences_help,
  slider1Items_business_help,
  slider1Items_social_science_help,
  slider1Items_finance_accounting_help,
  slider1Items_humanities_help,
  slider1Items_english_literature_help,
  slider1Items_students_1,
  slider1Items_students_2,
  slider1Items_students_3,
  slider1Items_students_4,
  slider1Items_students_5,
  slider1Items_students_6,
  slider1Items_rt_students,
  slider1Items_cbl_math_help,
} from "./blocks/SlickSlider1";

import '../../themes/nerdify/assets/css/index.css';

export const viewport = 'width=device-width';
const dynamicHeadline = lpdTitle();

export const favicons = [
  {
    type: 'image/x-icon',
    sizes: false,
    href: '/img/nerdifyit/favicon.ico',
  },
  {
    type: 'image/png',
    sizes: '16x16',
    href: '/img/nerdifyit/favicon-16x16.png',
  },
  {
    type: 'image/png',
    sizes: '32x32',
    href: '/img/nerdifyit/favicon-32x32.png',
  },
  {
    type: 'image/png',
    sizes: '96x96',
    href: '/img/nerdifyit/favicon-96x96.png',
  },
  {
    type: 'image/png',
    sizes: '192x192',
    href: '/img/nerdifyit/favicon-192x192.png',
  },
];

export const routes = [
  {
    path: '/about',
    meta: {
      title: 'Nerdify | About us',
      description:
        'Nerdify is a chat-based platform that provides access to 1000s of experienced Nerds who can help with on-demand requests.',
      keywords:
        'on-demand research platform, on-demand research, collect data for analysis, college help service, business presentation, find a tutor, college advisor, market research, study help, find internship, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
    },
    component: About,
    pageCfg: {},
  },
  {
    path: '/become',
    meta: {
      title: 'Nerdify | Become a Nerd',
      description:
        'Interested in becoming a Nerd? Submit an application form, pass the examination, get approved & get started!',
      keywords:
        'nerds, best nerds, get help from nerds, homework nerds, writing nerds, find a nerd, on-demand research, collect data for analysis, college help service, business presentation, find a tutor, college advisor, market research, study help, find internship, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
    },
    component: Become,
    pageCfg: {},
  },
  {
    path: '/cookies',
    meta: {
      title: 'Nerdify | Cookies',
      description:
        'This section will help you better understand what is cookies and how Nerdify and our partners use them.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Cookies,
    pageCfg: {},
  },
  {
    path: '/honor-code',
    meta: {
      title: 'Nerdify | Honor Code',
      description:
        'The Honor Code is a policy promoting Nerdify Platform usage integrity.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: HonorCode,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/how-it-works',
    meta: {
      title: 'Nerdify | How it works',
      description:
        'Text us what you need help with or send a pic with instructions. Get the best-fit Nerd assigned to assist you as soon as possible.',
      keywords:
        'on-demand research platform, on-demand research, collect data for analysis, college help service, business presentation, find a tutor, college advisor, market research, study help, find internship, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
    },
    component: HowItWorks,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/maintenance',
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Maintenance,
    pageCfg: {},
  },

  {
    path: '/top-nerds',
    meta: {
      title: 'Nerdify | Top Nerds',
      description:
        'Our Nerds are among the TOP 2% freelancers in their fields. Trust the experience of 1000s of freelance expert Nerds.',
      keywords:
        'nerds, best nerds, get help from nerds, homework nerds, writing nerds, find a nerd, on-demand research, collect data for analysis, college help service, business presentation, find a tutor, college advisor, market research, study help, find internship, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
    },
    component: TopNerds,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/refunds',
    meta: {
      title: 'Nerdify | Refund policy',
      description:
        'If the received service did not meet your initial requirements - let us know ASAP.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Refunds,
    pageCfg: {},
  },
  {
    path: '/terms-and-conditions',
    meta: {
      title: 'Nerdify | Terms and conditions',
      description:
        'By using Nerdify the User has agreed with Terms & Conditions, applicable laws and regulations, and is responsible for compliance with them.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Terms,
    pageCfg: {},
  },
  {
    path: '/privacy-policy',
    meta: {
      title: 'Nerdify | Privacy Policy',
      description:
        'This section will help you better understand how Nerdify collects, stores, processes and uses your personal information. ',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Privacy,
    pageCfg: {},
  },
  {
    path: '/nerdybot',
    meta: {
      title: 'Nerdify | Nerdy bot',
      description:
        'Get an instant answer to any question! All you need is a Messenger',
      keywords:
        'bot, study help, student help service, concierge service for colleges',
      robots: 'noindex, nofollow',
    },
    component: NerdyBot,
    pageCfg: {},
  },
  {
    path: '/report-abuse',
    meta: {
      title: 'Nerdify | Report abuse',
      description:
        'If you believe that the Nerd assigned to your task agreed to provide unethical help, please, report abuse.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: ReportAbuse,
    exact: true,
    pageCfg: {},
  },

  {
    path: '/',
    meta: {
      title:
        'Nerdify | #1 On-Demand Research Platform for Businesses, Students & Academia',
      description:
        'Nerdify is a chat-based platform. Personal Nerds help businesses, students & academia with tutoring, data collection, research & analysis 24/7',
      keywords:
        'Nerdify, nerdifyit, on-demand research platform, on-demand research, collect data for analysis, college help service, business presentation, find a tutor, college advisor, market research, study help, find internship, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      AntiFlicker: 4,
      headline: 'Overwhelmed by urgent tasks?',
      hasNav: true,
      slider1Items: slider1Items_main,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_main_en',
        },
      }),
    },
  },
  {
    path: '/tr',
    meta: {
      title:
        'Nerdify | #1 On-Demand Research Platform for Businesses, Students & Academia',
      description:
        'Nerdify is a chat-based platform. Personal Nerds help businesses, students & academia with data collection, research & analysis 24/7.',
      keywords:
        'Nerdify, nerdifyit, on-demand research platform, on-demand research, collect data for analysis, college help service, business presentation, find a tutor, college advisor, market research, study help, find internship, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
    },
    component: Landing1a,
    exact: true,
    pageCfg: {
      AntiFlicker: 4,
      headline: 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_main,
      sliderReviewsItems: 'sliderReviewsItems14',
      hasNav: true,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_main_en',
        },
      }),
    },
  },

  {
    path: '/students-1',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Send your essay and get writing tips from Expert Nerds. Instant reply. Text us now!',
      keywords:
        'essay, essays, help with essay, proofread my essay, a written essay, outline help, essay writing tips, do my essay, title help, essay tutoring, essay papers, essay writing tutoring, online essay tutor, university essay, best essay samples, analytical essays',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_students_1,
      demoCards: 'records_students_1',
      FAQ_Items: 'FAQ_students_1',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en',
        },
      }),
    },
  },
  {
    path: '/tr-students-1',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Send your essay and get writing tips from Expert Nerds. Instant reply. Text us now!',
      keywords:
        'essay, essays, help with essay, proofread my essay, a written essay, outline help, essay writing tips, do my essay, title help, essay tutoring, essay papers, essay writing tutoring, online essay tutor, university essay, best essay samples, analytical essays',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_students_1,
      sliderReviewsItems: 'sliderReviewsItems9',
      FAQ_Items: 'FAQ_students_1',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en',
        },
      }),
    },
  },
  {
    path: '/cbl-students-1',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your essay deadlines with Nerds. Text us your essay task and get writing help from our Top Nerds. Instant reply. Try Now!',
      keywords:
        'essay, essays, help with essay, proofread my essay, a written essay, outline help, essay writing tips, do my essay, title help, essay tutoring, essay papers, essay writing tutoring, online essay tutor, university essay, best essay samples, analytical essays',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/students-1-short',
    meta: {
      title: 'Nerdify | #1 Personal Assistant for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'essay, essays, help with essay, proofread my essay, scholarship essay writing, outline help, pay someone, essay writing tips, do my essay, my essay, title help, essay tutoring, essay papers, essay writing tutoring, online essay tutor, essay consultation, university essay, best essay examples',
      robots: 'noindex, nofollow',
    },
    component: Landing8a,
    exact: true,
    pageCfg: {
      hasNav: true,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com6_en',
        },
      }),
    },
  },
  {
    path: '/students-2',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Send your paper and get writing tips from Expert Nerds. Instant reply. Text us now!',
      keywords:
        'research paper, thesis paper, writing research paper, someone to edit my paper, do my paper, research paper tutoring, paper tutor, outline help, title help, paper writing help, college paper, my paper, need help writing a paper, writing APA paper',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_students_2,
      demoCards: 'records_students_2',
      FAQ_Items: 'FAQ_students_2',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp2_en',
        },
      }),
    },
  },
  {
    path: '/tr-students-2',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Send your paper and get writing tips from Expert Nerds. Instant reply. Text us now!',
      keywords:
        'research paper, thesis paper, writing research paper, someone to edit my paper, do my paper, research paper tutoring, paper tutor, outline help, title help, paper writing help, college paper, my paper, need help writing a paper, writing APA paper',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_students_2,
      sliderReviewsItems: 'sliderReviewsItems10',
      FAQ_Items: 'FAQ_students_2',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp2_en',
        },
      }),
    },
  },
  {
    path: '/cbl-students-2',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your paper deadlines with Nerds. Text us your paper task and get writing help from our Top Nerds. Instant reply. Try Now!',
      keywords:
        'research paper, thesis paper, writing research paper, someone to edit my paper, write my paper, do my paper, research paper tutoring, paper tutor, outline help, title help, paper writing help, college paper, my paper, need help writing a paper, writing APA paper',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/students-3',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your homework deadlines with Nerds. Text us your task and get homework help from Expert Nerds. Instant reply. Try Now!',
      keywords:
        'homework help, do my homework, online homework tutor, do my online homework, homework help online, do your homework, homework tutor, homework helping, help with homework, excel homework, hw help, doing homework, homework assistance, solve my homework',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_students_3,
      demoCards: 'records_students_3',
      FAQ_Items: 'FAQ_students_3',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/tr-students-3',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your homework deadlines with Nerds. Text us your task and get homework help from Expert Nerds. Instant reply. Try Now!',
      keywords:
        'homework help, do my homework, online homework tutor, do my online homework, homework help online, do your homework, homework tutor, homework helping, help with homework, excel homework, hw help, doing homework, homework assistance, solve my homework',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_students_3,
      sliderReviewsItems: 'sliderReviewsItems11',
      FAQ_Items: 'FAQ_students_3',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/cbl-students-3',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your homework deadlines with Nerds. Text us your task and get homework help from our Top Nerds. Instant reply. Try Now!',
      keywords:
        'homework help, do my homework, online homework tutor, do my online homework, homework help online, do your homework, homework tutor, homework helping, help with homework, excel homework, hw help, doing homework, homework assistance, solve my homework',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/students-4',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Send us your assignment and get help from Expert Nerds. Instant reply. Text us now!',
      keywords:
        'online assignment tutoring, do my assignment, help with assignments, university assignment, your assignment, excel assignment, college assignment, writing assignment, assignment examples, assignment tips, assignments help, assignment tutor, online assignment',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_students_4,
      demoCards: 'records_students_4',
      FAQ_Items: 'FAQ_students_4',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp4_en',
        },
      }),
    },
  },
  {
    path: '/tr-students-4',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Send us your assignment and get help from Expert Nerds. Instant reply. Text us now!',
      keywords:
        'online assignment tutoring, do my assignment, help with assignments, university assignment, your assignment, excel assignment, college assignment, writing assignment, assignment examples, assignment tips, assignments help, assignment tutor, online assignment',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_students_4,
      sliderReviewsItems: 'sliderReviewsItems12',
      FAQ_Items: 'FAQ_students_4',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp4_en',
        },
      }),
    },
  },
  {
    path: '/cbl-students-4',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your assignment deadlines with Nerds. Text us your assignment and get help from our Top Nerds. Instant reply. Try Now!',
      keywords:
        'online assignment tutoring, do my assignment, help with assignments, university assignment, your assignment, excel assignment, college assignment, writing assignment, assignment examples, assignment tips, assignments help, assignment tutor, online assignment',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/students-5',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your homework deadlines with Nerds. Text us your task and get writing help from Expert Nerds. Instant reply. Try Now!',
      keywords:
        'writing help, writing tips, do my project, project help, annotated bibliography, online writing help, writing helper, thesis writing, research writing, summary writing, writing discussion post, college writing, university writing, online writing, report writing, writing tutor, need help writing, writing for students, help with college writing, master thesis writing',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_students_5,
      sliderCombinedItems: 'sliderCombinedItems_students_5',
      demoCards: 'records_students_5',
      FAQ_Items: 'FAQ_students_5',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp5_en',
        },
      }),
    },
  },
  {
    path: '/tr-students-5',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your homework deadlines with Nerds. Text us your task and get writing help from Expert Nerds. Instant reply. Try Now!',
      keywords:
        'writing help, writing tips, do my project, project help, annotated bibliography, online writing help, writing helper, thesis writing, research writing, summary writing, writing discussion post, college writing, university writing, online writing, report writing, writing tutor, need help writing, writing for students, help with college writing, master thesis writing',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      slider1Items: slider1Items_students_5,
      sliderCombinedItems: 'sliderCombinedItems_students_5',
      sliderReviewsItems: 'sliderReviewsItems13',
      FAQ_Items: 'FAQ_students_5',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp5_en',
        },
      }),
    },
  },
  {
    path: '/cbl-students-5',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your homework deadlines with Nerds. Text us your task and get writing help from our Top Nerds. Instant reply. Try Now!',
      keywords:
        'writing help, writing tips, do my project, project help, annotated bibliography, online writing help, writing helper, thesis writing, research writing, summary writing, writing discussion post, college writing, university writing, online writing, report writing, writing tutor, need help writing, writing for students, help with college writing, master thesis writing',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/students-6',
    meta: {
      title: 'Nerdify | #1 Writing Tutoring for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'proofreading, checking, proofreader, checking services, proofreading services, online checking, online proofreading, grammar checker, essay checking, paper checking, essay proofreading, article proofreading, paper proofreading, online proofreader, online paper checker, proofreading online, assignment checker, proofreaders, esay checker, paper checker',
      robots: 'noindex, nofollow',
    },
    component: Landing4,
    exact: true,
    pageCfg: {
      hasNav: true,
      slider1Items: slider1Items_students_6,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp6_en',
        },
      }),
    },
  },

  {
    path: '/business-help',
    meta: {
      title: 'Nerdify | #1 Business Studies Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from Expert Nerds in Business, Marketing and Management. Try Now!',
      keywords:
        'Marketing assignment, Management homework, MBA assignment, Business homework help, help do my Business homework, Operations Management homework, Business Analytics homework, Business Law assignment, Business Finance homework, Project Management, Business Analytics, Marketing paper, online tutoring, homework help',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Business, Marketing & Management</span> who
          can help
        </>
      ),
      AntiFlicker: 1,
      slider1Items: slider1Items_business_help,
      sliderCombinedItems: 'sliderCombinedItems_business_help',
      demoCards: 'records_business_help',
      FAQ_Items: 'FAQ_business_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/tr-business-help',
    meta: {
      title: 'Nerdify | #1 Business Studies Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from Expert Nerds in Business, Marketing and Management. Try Now!',
      keywords:
        'Marketing assignment, Management homework, MBA assignment, Business homework help, help do my Business homework, Operations Management homework, Business Analytics homework, Business Law assignment, Business Finance homework, Project Management, Business Analytics, Marketing paper, online tutoring, homework help',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Business, Marketing & Management</span> who
          can help
        </>
      ),
      AntiFlicker: 1,
      slider1Items: slider1Items_business_help,
      sliderReviewsItems: 'sliderReviewsItems1',
      sliderCombinedItems: 'sliderCombinedItems_business_help',
      FAQ_Items: 'FAQ_business_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },

  {
    path: '/cbl-business-help',
    meta: {
      title: 'Nerdify | #1 Business Studies Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from our Top Nerds in Business, Marketing & Management. Try Now!',
      keywords:
        'Marketing assignment, Management homework, MBA assignment, Business homework help, help do my Business homework, Operations Management homework, Business Analytics homework, Business Law assignment, Business Finance homework, Project Management, Business Analytics, Marketing paper, online tutoring, homework help',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Business, Marketing & Management</span> who
          can help
        </>
      ),
      AntiFlicker: 1,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/social-sciences-help',
    meta: {
      title: 'Nerdify | #1 Social Sciences Help for College Students',
      description:
        'Meet your writing deadlines with Nerds. Text us your task and get help from Expert Nerds in Psychology, Sociology and Law. Try Now!',
      keywords:
        'Psychology paper, Sociology paper, Psychology homework, Psychology assignment, Law homework, Sociology assignment, Psychology essay, Law paper, Law essay, Political Science paper, help to write my Psychology paper, Sociology homework help, Sociology essay, writing help, tutor online',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Psychology, Sociology & Law</span> who can
          help
        </>
      ),
      AntiFlicker: 4,
      slider1Items: slider1Items_social_science_help,
      sliderCombinedItems: 'sliderCombinedItems_social_sciences_help',
      demoCards: 'records_social_sciences_help',
      FAQ_Items: 'FAQ_social_sciences_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/tr-social-sciences-help',
    meta: {
      title: 'Nerdify | #1 Social Sciences Help for College Students',
      description:
        'Meet your writing deadlines with Nerds. Text us your task and get help from Expert Nerds in Psychology, Sociology and Law. Try Now!',
      keywords:
        'Psychology paper, Sociology paper, Psychology homework, Psychology assignment, Law homework, Sociology assignment, Psychology essay, Law paper, Law essay, Political Science paper, help to write my Psychology paper, Sociology homework help, Sociology essay, writing help, tutor online',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Psychology, Sociology & Law</span> who can
          help
        </>
      ),
      AntiFlicker: 4,
      slider1Items: slider1Items_social_science_help,
      sliderCombinedItems: 'sliderCombinedItems_social_sciences_help',
      sliderReviewsItems: 'sliderReviewsItems7',
      FAQ_Items: 'FAQ_social_sciences_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },

  {
    path: '/cbl-social-sciences-help',
    meta: {
      title: 'Nerdify | #1 Social Studies Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from our Top Nerds in Psychology, Sociology & Law. Try Now!',
      keywords:
        'Psychology paper, Sociology paper, Psychology homework, Psychology assignment, Law homework, Sociology assignment, Psychology essay, Law paper, Law essay, Political Science paper, help to write my Psychology paper, Sociology homework help, Sociology essay, writing help, tutor online',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Psychology, Sociology & Law</span> who can
          help
        </>
      ),
      AntiFlicker: 1,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/natural-sciences-help',
    meta: {
      title: 'Nerdify | #1 Natural Sciences Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from Expert Nerds in Chemistry, Physics and Biology. Try Now!',
      keywords:
        'Chemistry help, help to do my Chemistry, Chemistry lab, Chemistry problems, homework help, Physics help, Physics homework, Chemistry homework, Chemistry homework help, Chemistry lab, Biology homework, Biology assignment, help to do my Physics, Physics homework, Chemistry assignment, Science homework, online tutoring help',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Chemistry, Physics & Biology</span> who can
          help
        </>
      ),
      AntiFlicker: 4,
      slider1Items: slider1Items_natural_sciences_help,
      sliderCombinedItems: 'sliderCombinedItems_natural_sciences_help',
      demoCards: 'records_natural_sciences_help',
      FAQ_Items: 'FAQ_natural_sciences_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/tr-natural-sciences-help',
    meta: {
      title: 'Nerdify | #1 Natural Sciences Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from Expert Nerds in Chemistry, Physics and Biology. Try Now!',
      keywords:
        'Chemistry help, help to do my Chemistry, Chemistry lab, Chemistry problems, homework help, Physics help, Physics homework, Chemistry homework, Chemistry homework help, Chemistry lab, Biology homework, Biology assignment, help to do my Physics, Physics homework, Chemistry assignment, Science homework, online tutoring help',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Chemistry, Physics & Biology</span> who can
          help
        </>
      ),
      AntiFlicker: 4,
      slider1Items: slider1Items_natural_sciences_help,
      sliderCombinedItems: 'sliderCombinedItems_tr_natural_sciences_help',
      sliderReviewsItems: 'sliderReviewsItems6',
      FAQ_Items: 'FAQ_natural_sciences_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },

  {
    path: '/cbl-natural-sciences-help',
    meta: {
      title: 'Nerdify | #1 Environmental Science Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from our Top Nerds in Chemistry, Physics & Biology. Try Now!',
      keywords:
        'Chemistry help, help to do my Chemistry, Chemistry lab, Chemistry problems, homework help, Physics help, Physics homework, Chemistry homework, Chemistry homework help, Chemistry lab, Biology homework, Biology assignment, help to do my Physics, Physics homework, Chemistry assignment, Science homework, online tutoring help',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Chemistry, Physics & Biology</span> who can
          help
        </>
      ),
      AntiFlicker: 1,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/statistics-help',
    meta: {
      title: 'Nerdify | #1 Statistics Help for College Students',
      description:
        'Meet your homework deadlines with Statistics Nerds. Text us your task and get help from Expert Nerds. Instant Reply. Try Now!',
      keywords:
        'Statistics help, do my Statistics, Statistics homework, Statistics homework help, Statistics assignment, help with Statistics homework, Statistics help online, solve my Statistics, Satistics problem, Statistics helper, homework help, online tutoring',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by Statistics tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Statistics</span> who can help
        </>
      ),
      AntiFlicker: 4,
      slider1Items: slider1Items_statistics_help,
      sliderCombinedItems: 'sliderCombinedItems_statistics_help',
      demoCards: 'records_statistics_help',
      FAQ_Items: 'FAQ_statistics_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/tr-statistics-help',
    meta: {
      title: 'Nerdify | #1 Statistics Help for College Students',
      description:
        'Meet your homework deadlines with Statistics Nerds. Text us your task and get help from Expert Nerds. Instant Reply. Try Now!',
      keywords:
        'Statistics help, do my Statistics, Statistics homework, Statistics homework help, Statistics assignment, help with Statistics homework, Statistics help online, solve my Statistics, Satistics problem, Statistics helper, homework help, online tutoring',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by Statistics tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Statistics</span> who can help
        </>
      ),
      AntiFlicker: 4,
      slider1Items: slider1Items_statistics_help,
      sliderCombinedItems: 'sliderCombinedItems_statistics_help',
      sliderReviewsItems: 'sliderReviewsItems8',
      FAQ_Items: 'FAQ_statistics_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },

  {
    path: '/cbl-statistics-help',
    meta: {
      title: 'Nerdify | #1 Statistics Help for College Students',
      description:
        'Meet your homework deadlines with Statistics Nerds. Text us your task and get help from our Top Nerds. Instant Reply. Try Now!',
      keywords:
        'Statistics help, do my Statistics, Statistics homework, Statistics homework help, Statistics assignment, help with Statistics homework, Statistics help online, solve my Statistics, Satistics problem, Statistics helper, homework help, online tutoring',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Stuck on a Statistics task?',
      subtitle: 'Get instant access to Nerds who can help',
      AntiFlicker: 1,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/finance-accounting-help',
    meta: {
      title: 'Nerdify | #1 Finance & Economics Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from Expert Nerds in Accounting, Finance and Economics. Try Now!',
      keywords:
        'Accounting homework, help do my Accounting homework, Finance homework, Tax Accounting homework help, Economics homework, help do my Economics, Accounting help online, Economics homework help, Accounting assignment, Finance assignment, Accounting project, Microeconomics homework, Economics paper, Economics assignment, Finance paper, Corporate Finance, Financial Accounting, Political Economy, online tutoring, tutoring help',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Accounting, Finance & Economics</span> who
          can help
        </>
      ),
      AntiFlicker: 1,
      slider1Items: slider1Items_finance_accounting_help,
      sliderCombinedItems: 'sliderCombinedItems_finance_accounting_help',
      demoCards: 'records_finance_accounting_help',
      FAQ_Items: 'FAQ_finance_accounting_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/tr-finance-accounting-help',
    meta: {
      title: 'Nerdify | #1 Finance & Economics Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from Expert Nerds in Accounting, Finance and Economics. Try Now!',
      keywords:
        'Accounting homework, help do my Accounting homework, Finance homework, Tax Accounting homework help, Economics homework, help do my Economics, Accounting help online, Economics homework help, Accounting assignment, Finance assignment, Accounting project, Microeconomics homework, Economics paper, Economics assignment, Finance paper, Corporate Finance, Financial Accounting, Political Economy, online tutoring, tutoring help',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Accounting, Finance & Economics</span> who
          can help
        </>
      ),
      AntiFlicker: 1,
      slider1Items: slider1Items_finance_accounting_help,
      sliderCombinedItems: 'sliderCombinedItems_finance_accounting_help',
      sliderReviewsItems: 'sliderReviewsItems3',
      FAQ_Items: 'FAQ_finance_accounting_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/cbl-finance-accounting-help',
    meta: {
      title: 'Nerdify | #1 Finance & Economics Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from our Top Nerds in Accounting, Finance & Economics. Try Now!',
      keywords:
        'Accounting homework, help do my Accounting homework, Finance homework, Tax Accounting homework help, Economics homework, help do my Economics, Accounting help online, Economics homework help, Accounting assignment, Finance assignment, Accounting project, Microeconomics homework, Economics paper, Economics assignment, Finance paper, Corporate Finance, Financial Accounting, Political Economy, online tutoring, tutoring help',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Accounting, Finance & Economics</span> who
          can help
        </>
      ),
      AntiFlicker: 1,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/humanities-help',
    meta: {
      title: 'Nerdify | #1 Humanities Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from Expert Nerds in Philosophy, History, Nursing and Ethics. Try Now!',
      keywords:
        'Philosophy homework help, Nursing paper, Philosophy essay, Philosophy paper, Nursing assignment, Ethics paper, History homework, History assignment, History homework help, History essay, Nursing homework, Ethics essay, Nursing essay, Arts, Religion essay, Nutrition, Education assignment, Public Health, Healthcare, Anthropology homework, Humanities, Culture, Theology, Art History, online tutoring',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Philosophy, History, Nursing & Ethics</span>{' '}
          who can help
        </>
      ),
      AntiFlicker: 1,
      slider1Items: slider1Items_humanities_help,
      sliderCombinedItems: 'sliderCombinedItems_humanities_help',
      demoCards: 'records_humanities_help',
      FAQ_Items: 'FAQ_humanities_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/tr-humanities-help',
    meta: {
      title: 'Nerdify | #1 Humanities Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from Expert Nerds in Philosophy, History, Nursing and Ethics. Try Now!',
      keywords:
        'Philosophy homework help, Nursing paper, Philosophy essay, Philosophy paper, Nursing assignment, Ethics paper, History homework, History assignment, History homework help, History essay, Nursing homework, Ethics essay, Nursing essay, Arts, Religion essay, Nutrition, Education assignment, Public Health, Healthcare, Anthropology homework, Humanities, Culture, Theology, Art History, online tutoring',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Philosophy, History, Nursing & Ethics</span>{' '}
          who can help
        </>
      ),
      AntiFlicker: 1,
      slider1Items: slider1Items_humanities_help,
      sliderCombinedItems: 'sliderCombinedItems_humanities_help',
      sliderReviewsItems: 'sliderReviewsItems4',
      FAQ_Items: 'FAQ_humanities_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },

  {
    path: '/cbl-humanities-help',
    meta: {
      title: 'Nerdify | #1 Humanities Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task & get help from our Top Nerds in Philosophy, History, Nursing & Ethics. Try Now!',
      keywords:
        'Philosophy homework help, Nursing paper, Philosophy essay, Philosophy paper, Nursing assignment, Ethics paper, History homework, History assignment, History homework help, History essay, Nursing homework, Ethics essay, Nursing essay, Arts, Religion essay, Nutrition, Education assignment, Public Health, Healthcare, Anthropology homework, Humanities, Culture, Theology, Art History, online tutoring',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Philosophy, History, Nursing & Ethics</span>{' '}
          who can help
        </>
      ),
      AntiFlicker: 1,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },
  {
    path: '/english-literature-help',
    meta: {
      title: 'Nerdify | #1 English & Literature Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from Expert Nerds in English, Literature and Linguistics. Try Now!',
      keywords:
        'my Literature review, Literary analysis essay, Literature review writing, English assignment, English writing, English homework, Linguistics homework, help to write English paper, Literature review help, Creative writing, online tutoring, writing help',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">English, Literature & Linguistics</span> who
          can help
        </>
      ),
      AntiFlicker: 1,
      slider1Items: slider1Items_english_literature_help,
      sliderCombinedItems: 'sliderCombinedItems_english_literature_help',
      demoCards: 'records_english_literature_help',
      FAQ_Items: 'FAQ_english_literature_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/tr-english-literature-help',
    meta: {
      title: 'Nerdify | #1 English & Literature Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from Expert Nerds in English, Literature and Linguistics. Try Now!',
      keywords:
        'my Literature review, Literary analysis essay, Literature review writing, English assignment, English writing, English homework, Linguistics homework, help to write English paper, Literature review help, Creative writing, online tutoring, writing help',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: dynamicHeadline || 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">English, Literature & Linguistics</span> who
          can help
        </>
      ),
      AntiFlicker: 1,
      slider1Items: slider1Items_english_literature_help,
      sliderCombinedItems: 'sliderCombinedItems_english_literature_help',
      sliderReviewsItems: 'sliderReviewsItems2',
      FAQ_Items: 'FAQ_english_literature_help',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },

  {
    path: '/cbl-english-literature-help',
    meta: {
      title: 'Nerdify | #1 English & Literature Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text us your task and get help from our Top Nerds in English, Literature & Linguistics. Try Now!',
      keywords:
        'my Literature review, Literary analysis essay, Literature review writing, English assignment, English writing, English homework, Linguistics homework, help to write English paper, Literature review help, Creative writing, online tutoring, writing help',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">English, Literature & Linguistics</span> who
          can help
        </>
      ),
      AntiFlicker: 1,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },

  {
    path: '/clp4-students-1',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'essay, essays, help with essay, proofread my essay, scholarship essay writing, outline help, pay someone, essay writing tips, do my essay, my essay, title help, essay tutoring, essay papers, essay writing tutoring, online essay tutor, essay consultation, university essay, best essay examples',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en',
        },
      }),
    },
  },
  {
    path: '/clp4-students-2',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'research paper, someone to edit my paper, research paper consultation, write my paper, research paper tutoring, research paper tutors, pay someone, writing research papers, college paper, research help, my paper, do my paper, need help writing a paper, paper tutor, write papers, title help, outline help',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp2_en',
        },
      }),
    },
  },
  {
    path: '/clp4-students-3',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'homework, homework help from online tutor, my homework, do my homework, homework help online, homework tutoring, hw help, hw tutors, online homework tutoring, English homework, business homework, pay someone, do your homework, homework tutor, homework management tips',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/clp4-students-4',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'assignment, online assignment tutoring, help with assignments, my assignment, university assignment, do my assignment, writing assignment examples, college assignment, writing assignments tips, assignments help, assignment tutor, assignment consultation, online assignment',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp4_en',
        },
      }),
    },
  },
  {
    path: '/clp4-students-5',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'writing help, writing tips, online writing help, writing tutor, how to become a better writer, writing helper, thesis writing, research writing, writing a book report, literature review help, outline help, college writing, university writing, online writing, report writing, writing tutor, need help writing, writing for students, help with college writing, master thesis writing',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp5_en',
        },
      }),
    },
  },

  {
    path: '/tt-students-1',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'homework, homework help from online tutor, my homework, do my homework, homework help online, homework tutoring, hw help, hw tutors, online homework tutoring, English homework, business homework, pay someone, do your homework, homework tutor, homework management tips',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp_tt',
        },
      }),
    },
  },

  {
    path: '/math-help',
    meta: {
      title: 'Nerdify | #1 Math Help for College Students',
      description:
        'Meet your homework deadlines with Math Nerds. Text us your task and get help from Expert Nerds. Instant Reply. Try Now!',
      keywords:
        'math homework help, help with my math homework, do my math homework, math problems, math online help, help with math problems, math homework helper, math assignment assistance, do my math assignment, math essay, math paper, math assistance, solve my math, solve math problems, geometry problem, calculus help, algebra homework, math questions, math tutor',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 1,
      headline: dynamicHeadline || 'Overwhelmed by Math tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Math, Algebra & Geometry</span> who can help
        </>
      ),
      hasNav: true,
      slider1Items: slider1Items_math_help,
      sliderCombinedItems: 'sliderCombinedItems_math_help',
      demoCards: 'records_math_help',
      FAQ_Items: 'FAQ_math_help',
    },
  },
  {
    path: '/tr-math-help',
    meta: {
      title: 'Nerdify | #1 Math Help for College Students',
      description:
        'Meet your homework deadlines with Math Nerds. Text us your task and get help from Expert Nerds. Instant Reply. Try Now!',
      keywords:
        'math homework help, help with my math homework, do my math homework, math problems, math online help, help with math problems, math homework helper, math assignment assistance, do my math assignment, math essay, math paper, math assistance, solve my math, solve math problems, geometry problem, calculus help, algebra homework, math questions, math tutor',
      robots: 'noindex, nofollow',
    },
    component: Landing7a,
    exact: true,
    pageCfg: {
      AntiFlicker: 1,
      headline: dynamicHeadline || 'Overwhelmed by Math tasks?',
      subtitle: (
        <>
          Get instant access to Nerds in{' '}
          <span className="strong">Math, Algebra & Geometry</span> who can help
        </>
      ),
      hasNav: true,
      slider1Items: slider1Items_math_help,
      sliderCombinedItems: 'sliderCombinedItems_math_help',
      sliderReviewsItems: 'sliderReviewsItems5',
      FAQ_Items: 'FAQ_math_help',
    },
  },
  {
    path: '/cbl-math-help',
    meta: {
      title: 'Nerdify | #1 Math Help for College Students',
      description:
        'Meet your homework deadlines with Math Nerds. Text us your task and get help from our Top Nerds. Instant Reply. Try Now!',
      keywords:
        'math homework help, help with my math homework, do my math homework, math problems, math online help, help with math problems, math homework helper, math assignment assistance, do my math assignment, math essay, math paper, math assistance, solve my math, solve math problems, math questions, math tutor',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      cashbackBanner: true,
      AntiFlicker: 2,
      headline: 'Stuck on a Math task?',
      hasNav: true,
      slider1Items: slider1Items_cbl_math_help,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en_cbl',
        },
      }),
    },
  },

  {
    path: '/clp3-students-3-maths',
    meta: {
      title: 'Nerdify | #1 Math Help for College Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get Help With Your Task Within Minutes. Message Now.',
      keywords:
        'math homework help, help with my math homework, math problems, math online help, my math problem help, help with math problems, math homework helper, math assignment assistance, math essay, math paper, math assistance',
      robots: 'noindex, nofollow',
    },
    component: LandingClp3,
    exact: true,
    pageCfg: {
      AntiFlicker: 2,
      headline: 'Stuck on a Math task?',
      hasNav: true,
    },
  },

  {
    path: '/video-explanations',
    meta: {
      title: 'Nerdify | #1 Maths Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'homework, math, do my math homework, maths, math homework help, solve my math homework, math online help, do math homework, math homework helper, do my math assignment, help with math, math assignment help, math essay, math paper, homework tutoring, hw help, hw tutors, math tutoring, maths tutor',
      robots: 'noindex, nofollow',
    },
    component: LandingClp4,
    exact: true,
    pageCfg: {
      hasNav: true,
      AntiFlicker: 1,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com4_en',
        },
        textBackText:
          'Hey there!\n' +
          'Thousands of Nerds are ready to help with any task 📊\n' +
          "We don't charge extra for texting, you only pay when you order something.\n" +
          'What can I help you with? :)',
      }),
    },
  },
  {
    path: '/video-tutoring',
    meta: {
      title: 'Nerdify | #1 Tutoring Service for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'online tutoring, tutoring help, math tutoring, statistics tutoring, physics tutoring, chemistry tutoring, calculus tutoring, algebra tutoring, geometry tutoring, assignment tutoring, homework tutoring, best tutoring website, online tutor, tutor site, tutoring service, math tutor, statistics tutor, physics tutor, chemistry tutor',
      robots: 'noindex, nofollow',
    },
    component: LandingClp4,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: 'Stuck on your Homework?',
      AntiFlicker: 1,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com4_en',
        },
        textBackText:
          'Hey there!\n' +
          'Thousands of Nerds are ready to help with any task 📊\n' +
          "We don't charge extra for texting, you only pay when you order something.\n" +
          'What can I help you with? :)',
      }),
    },
  },
  {
    path: '/math-tutoring',
    meta: {
      title: 'Nerdify | #1 Maths Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'homework, math, do my math homework, maths, math homework help, solve my math homework, math online help, do math homework, math homework helper, do my math assignment, help with math, math assignment help, math essay, math paper, homework tutoring, hw help, hw tutors, math tutoring, maths tutor',
      robots: 'noindex, nofollow',
    },
    component: LandingClp4,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: 'Stuck on a Math task?',
      AntiFlicker: 1,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com4_en',
        },
        textBackText:
          'Hey there!\n' +
          'Thousands of Nerds are ready to help with any task 📊\n' +
          "We don't charge extra for texting, you only pay when you order something.\n" +
          'What can I help you with? :)',
      }),
    },
  },
  {
    path: '/video-example',
    meta: {
      title: 'Nerdify | #1 Maths Help for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'homework, math, do my math homework, maths, math homework help, solve my math homework, math online help, do math homework, math homework helper, do my math assignment, help with math, math assignment help, math essay, math paper, homework tutoring, hw help, hw tutors, math tutoring, maths tutor',
      robots: 'noindex, nofollow',
    },
    component: LandingClp4Video,
    exact: true,
    pageCfg: {
      hasNav: true,
      AntiFlicker: 1,
    },
  },

  {
    path: '/rt-students',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your homework deadlines with Nerds. Text us your task and get help from Expert Nerds. Instant reply. Try Now!',
      keywords:
        'Nerdify, homework nerds, nerds writing help, nerds homework help, help with assignments, writing tips, nerds online writing help, nerds writing tutor, writing helper, nerds thesis writing, nerds research writing, nerds outline help, university writing, report writing, writing for students, nerds help with college writing',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: 'Message us to get help from Nerds ASAP',
      hasNav: true,
      slider1Items: slider1Items_rt_students,
    },
    // sliderCombinedItems: 'sliderCombinedItems_english_literature_help',
    // sliderReviewsItems: 'sliderReviewsItems2',
  },
  {
    path: '/rt-students-lreact',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        'Meet your homework deadlines with Nerds. Text us your task and get help from Expert Nerds. Instant reply. Try Now!',
      keywords:
        'Nerdify, homework nerds, nerds writing help, nerds homework help, help with assignments, writing tips, nerds online writing help, nerds writing tutor, writing helper, nerds thesis writing, nerds research writing, nerds outline help, university writing, report writing, writing for students, nerds help with college writing',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      promoBanner: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: 'Get $40 Off Your 1st Task',
      hasNav: true,
      slider1Items: slider1Items_rt_students,
    },
  },

  {
    path: '/clp1-students-1',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/study-help',
    meta: {
      title: 'Nerdify | #1 Editing Assistant for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'formatting, apa format help, mla format help, chicago format, essay formatting help, paper formatting help, cite sources, help to cite sources, cite this source for me, bibliography, outline, brief outline',
      robots: 'noindex, nofollow',
    },
    component: Landing5,
    exact: true,
    pageCfg: {
      hasNav: true,
      isRedirectToThankYou: true,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com3_en',
        },
      }),
    },
  },
  {
    path: '/clp2-students-1',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/nerdify-reviews',
    meta: {
      title: 'Updated Nerdify Reviews 2024 | reviews of verified users ✅',
      description:
        'All the latest Nerdify Reviews from all review platforms. Share your feedback with us!',
      keywords:
        'college help service, student help website, tutoring, student help agency, student job help, help with study, tutor help, college student help, university student help, nerdify, nerdify reviews, gonerdify, nerds help, nerdify user reviews, nerdify latest reviews, nerdify reviews 2023, nerdify reviews reddit',
      robots: 'noindex, nofollow',
    },
    component: NerdifyReviews,
    exact: true,
    pageCfg: {
      hasNav: true,
    },
  },

  {
    path: '/for-students',
    meta: {
      title: 'Nerdify for Students',
      description:
        'Nerdify provides access for students to the time of high-skilled, vetted freelancers who can help with on-demand researches. Text us to start!',
      keywords:
        'nerdify for students, student help service, college help service, find a tutor, college advisor, find internship, research help, presentation help, university help, study help, on-demand tutoring',
      robots: 'noindex, nofollow',
    },
    component: LandingForStudents,
    exact: true,
    pageCfg: {
      //headline: 'Nerdify for Students',
      slider1Items: slider1Items_for_students
    },
  },
  {
    path: '/for-businesses',
    meta: {
      title: 'Nerdify for Business',
      description:
        'Nerdify provides access for businesses to the time of high-skilled, vetted freelancers who can help with on-demand researches. Text us to start!',
      keywords:
        'nerdify for business, business advisor, find internship, research help, presentation help, business presentation, market research, data collection and analysis, on-demand research, on-demand research platform',
      robots: 'noindex, nofollow',
    },
    component: LandingForBusinesses,
    exact: true,
    pageCfg: {
      //headline: 'Nerdify for Businesses',
    },
  },
  {
    path: '/for-academia',
    meta: {
      title: 'Nerdify for Academia',
      description:
        'Nerdify provides access for educators to the time of high-skilled, vetted freelancers who can help with on-demand researches. Text us to start!',
      keywords:
        'nerdify for academia, nerdify for educators, find internship, college help service, research help, presentation help, market research, data collection and analysis, on-demand research, on-demand research platform',
      robots: 'noindex, nofollow',
    },
    component: LandingForAcademia,
    exact: true,
    pageCfg: {},
  },

  // mathon
/*
  {
    path: '/mathon',
    meta: {
      title: 'MathOn. 1-on-1 Online Math School',
      description:
        'MathOn is a one of a kind online math school with personal tutors available through convenient 24/7 concierge.',
      keywords:
        'Math tutors, math tutor, online math school, math school, online math tutor',
      robots: 'noindex, nofollow',
    },
    component: Mathon,
    exact: true,
    pageCfg: {
      landingGuid: {
        us: 'nerdifyit.com_mathon',
      },
    },
  },
*/
  {
    path: '/mathon',
    meta: {
      title: 'MathOn | Online Math School',
      description:
        'MathOn is a one-of-a-kind online math school with personal tutors available through a convenient 24/7 concierge.',
      keywords:
        'Math tutors, math tutor, online math school, math school, online math tutor',
      robots: 'noindex, nofollow',
    },
    component: MathonLp,
    exact: true,
    pageCfg: {
      block2Content: 'lp1block2',
      thankYouPagePath: '/mathon/thankyou',
      landingGuid: {
        us: 'nerdifyit.com_mathon',
      },
    },
  },
  {
    path: '/mathon/lp1',
    meta: {
      title: 'MathOn | Online Math School',
      description:
        'MathOn is a one-of-a-kind online math school with personal tutors available through a convenient 24/7 concierge.',
      keywords:
        'Math tutors, math tutor, online math school, math school, online math tutor',
      robots: 'noindex, nofollow',
    },
    component: MathonLp,
    exact: true,
    pageCfg: {
      block2Content: 'lp1block2',
      thankYouPagePath: '/mathon/thankyou',
      landingGuid: {
        us: 'nerdifyit.com_mathon',
      },
    },
  },
  {
    path: '/mathon/thankyou',
    meta: {
      title: 'MathOn | Online Math School',
      description:
        'MathOn is a one-of-a-kind online math school with personal tutors available through a convenient 24/7 concierge.',
      keywords:
        'Math tutors, math tutor, online math school, math school, online math tutor',
      robots: 'noindex, nofollow',
    },
    component: MathonLp,
    exact: true,
    pageCfg: {
      block2Content: 'lp1block2',
      thankYouPagePath: '/mathon/thankyou',
      thankYouModal: true,
      landingGuid: {
        us: 'nerdifyit.com_mathon',
      },
    },
  },
  {
    path: '/mathon/privacy-policy',
    meta: {
      title: 'MathOn Privacy Policy',
      description: 'MathOn Privacy Policy',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: MathonPrivacyPolicy,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/mathon/terms-and-conditions',
    meta: {
      title: 'MathOn Terms and Condition.',
      description: 'MathOn Terms and Condition.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: MathonTerms,
    exact: true,
    pageCfg: {},
  },

  // redirections
  {
    path: '/for-colleges',
    pageCfg: {
      redirectTo: '/students-6',
    },
  },
  {
    path: '/business-1',
    pageCfg: {
      redirectTo: '/students-6',
    },
  },
  {
    path: '/college-1',
    pageCfg: {
      redirectTo: '/students-6',
    },
  },
  {
    path: '/clp1-students-2',
    pageCfg: {
      redirectTo: '/students-2',
    },
  },
  {
    path: '/clp1-students-3',
    pageCfg: {
      redirectTo: '/students-3',
    },
  },
  {
    path: '/clp1-students-4',
    pageCfg: {
      redirectTo: '/students-4',
    },
  },
  {
    path: '/clp1-students-5',
    pageCfg: {
      redirectTo: '/students-5',
    },
  },
  {
    path: '/clp2-students-2',
    pageCfg: {
      redirectTo: '/students-2',
    },
  },
  {
    path: '/clp2-students-3',
    pageCfg: {
      redirectTo: '/students-3',
    },
  },
  {
    path: '/clp2-students-4',
    pageCfg: {
      redirectTo: '/students-4',
    },
  },
  {
    path: '/clp2-students-5',
    pageCfg: {
      redirectTo: '/students-5',
    },
  },
  {
    path: '/clp3-students-1',
    pageCfg: {
      redirectTo: '/students-1',
    },
  },
  {
    path: '/clp3-students-2',
    pageCfg: {
      redirectTo: '/students-2',
    },
  },
  {
    path: '/clp3-students-3',
    pageCfg: {
      redirectTo: '/students-3',
    },
  },
  {
    path: '/clp3-students-4',
    pageCfg: {
      redirectTo: '/students-4',
    },
  },
  {
    path: '/clp3-students-5',
    pageCfg: {
      redirectTo: '/students-5',
    },
  },
  {
    path: '/clp3-math-open-hours',
    pageCfg: {
      redirectTo: '/clp3-students-3-maths',
    },
  },

  {
    path: '/thank-you',
    meta: {
      title: 'Nerdify | #1 Learning Help for College Students',
      description:
        "Meet your homework deadlines with Nerds. Text us now and we'll reply you in a few seconds. Get homework help from Expert Nerds. Try Now!",
      keywords:
        'Nerdify, homework nerds, nerds writing help, nerds homework help, someone to edit my paper, proofread my essay, college paper, help to complete my paper, help with assignments, writing tips, nerds online writing help, nerds writing tutor, writing helper, nerds thesis writing, nerds research writing, nerds outline help, university writing, report writing, writing for students, nerds help with college writing',
      robots: 'noindex, nofollow',
    },
    component: ThankYou,
    exact: true,
    pageCfg: {
      backLink: true,
    },
  },

  {
    meta: {
      title: 'Nerdify | Error',
      description: 'Nerdify | Error page',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: NotFound,
    pageCfg: {},
  },
];
