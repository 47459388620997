const trustpilot = {
  trustpilot: [
    {
      "name": "Esperanza Vasquez",
      "score": "5",
      "date": "30 Jul 2024",
      "title": "Life Savers",
      "review": "Every interaction with Nerdify and every inquiry has been an amazing experience. I've recommended this website to my friends. They also have a very convenient app. The Nerds are incredibly helpful and kind! If I could give more stars, I would!",
      "nerdify": "Many thanks for your kind words, Esperanza! If you ever need assistance in the future, we've got your back :)"
    },
    {
      "name": "Waris Chouha",
      "score": "5",
      "date": "28 Jul 2024",
      "title": "Assistance with Statistics",
      "review": "I received excellent support when working on my statistics project! The project turned out great, and I'm really glad about that!",
      "nerdify": "We’re so happy you’re satisfied with our services, Waris!"
    },
    {
      "name": "Liz Ayala",
      "score": "5",
      "date": "22 Jul 2024",
      "title": "Great experience!",
      "review": "The experience was great. Requesting help was super easy, and it definitely took some weight off my shoulders. Thank you!",
      "nerdify": "Thank you for choosing Nerdify and sharing your positive feedback with us, Liz!"
    },
    {
      "name": "Taylor Morales",
      "score": "5",
      "date": "10 Jul 2024",
      "title": "Very helpful and professional!",
      "review": "Anna did a phenomenal job helping me with my Microsoft Access homework. No matter what I did, I couldn’t understand how it works, so I tried Nerdify, and they guided me through it. With their help, I got a 100% score on this huge task, and it literally saved me. It was $100, but well worth it since I'm able to graduate on time now.",
      "nerdify": "Taylor, thank you so much for your amazing review! Congrats on your achievement :)"
    },
    {
      "name": "Wendy Cotton",
      "score": "5",
      "date": "4 Jul 2024",
      "title": "My first experience with Nerdify",
      "review": "This is my first time using this site for help with my tasks, and I must say they are very professional. Thanks to the Nerd's advice and tips on the structure, I was able to compose a very well-written paper. I will be using this website again!",
      "nerdify": "Wendy, thank you for trusting us to help with your tasks! We can't wait to have you back!"
    },
    {
      "name": "Bob Adams",
      "score": "5",
      "date": "24 Jun 2024",
      "title": "Amazing service!",
      "review": "They responded immediately and were very professional. They found me an available Nerd at such short notice and were very helpful too. The communication was clear and easy, and the Nerd explained everything thoroughly. I couldn’t have asked for better service.",
      "nerdify": "Bob, we’re delighted to hear that we were able to meet your expectations!"
    },
    {
      "name": "Jethro Law",
      "score": "5",
      "date": "13 Jun 2024",
      "title": "Really helpful",
      "review": "Nerdify is great to work with, flexible and accommodating. The prices are very reasonable. Also, the experts are very knowledgeable in their subjects.",
      "nerdify": "Jethro, we are so happy to hear that you're satisfied with your Nerdify experience!"
    },
    {
      "name": "Taryn Gullick",
      "score": "5",
      "date": "9 Jun 2024",
      "title": "First-time user",
      "review": "I have never done anything like this before, and while I was very hesitant at first, this was the smoothest and easiest process. I really appreciate the help, especially since I struggled with understanding the subject. Thank you!",
      "nerdify": "Taryn, thank you for choosing Nerdify and sharing your positive experience! Your trust means a lot to us!"
    },
    {
      "name": "Nick Guerra",
      "score": "5",
      "date": "23 May 2024",
      "title": "Nerdify is a legit service",
      "review": "Nerdify is a legit service, I got the help I needed. With Nerd's guidance I was able to create a very organized and professional slideshow project for my physics class, and I got 100% on it.",
      "nerdify": "Thanks, Nick! Your feedback motivates us to continue delivering high-quality study assistance :)"
    },
    {
      "name": "Caroline Chatham",
      "score": "5",
      "date": "21 May 2024",
      "title": "Quick and Professional",
      "review": "The team was quick, professional, and responsive. The assistance they provided was not only as requested but even better. Thank you for your help! I would recommend your services to anyone in need.",
      "nerdify": "Your recommendation means a lot to us, and we are always here to help whenever you need our assistance again!"
    },
    {
      "name": "Bárbara Santos",
      "score": "5",
      "date": "13 May 2024",
      "title": "Quick and responsive service",
      "review": "Nerdify is a quick and responsive service! Would definitely recommend. A reliable choice for prompt assistance.",
      "nerdify": "Barbara, thank you for recommending us and choosing Nerdify to help you with your studies!"
    },

    {
      name: "Mellissa Cowfer",
      score: "5",
      date: "10 Apr 2024",
      title: "This website is a saving grace!",
      review: "This website is a saving grace! Amazing results! Being a full-time mom with a full-time job trying to finish her BA, I would have not been able to survive without Nerdify.",
      nerdify: "Thanks, Mellissa! The Nerdify team is here to help when you need it!"
    },
    {
      name: "Tuki Weber",
      score: "5",
      date: "6 Apr 2024",
      title: "Thank you!",
      review: "I am grateful to have found help on my doctoral journey. Their assistance has reduced my anxiety. I can finally relax with the help from the Nerd!",
      nerdify: "Thank you, Tuki! We're thrilled to be part of your journey :)"
    },
    {
      name: "Dylan Valenta",
      score: "5",
      date: "1 Apr 2024",
      title: "Perfect assistance",
      review: "Nerdify assisted me in understanding complex equations. The Nerd explained the material thoroughly, ensuring that I understood everything. Feedback and assistance with my questions were provided to me in a timely manner. I can't express how grateful I am!",
      nerdify: "Dylan, your gratitude means a lot to us, and we're always here to help you whenever you need it!"
    },
    {
      name: "Purplee Rain",
      score: "5",
      date: "21 Mar 2024",
      title: "Always great service",
      review: "Always great service and very affordable. I got a perfect score on my assignment with their help. The process was really easy and it didn't cost me an arm and a leg. Nobody beats Nerdify!",
      nerdify: "Purplee, your positive feedback means the world to us!"
    },
    {
      name: "Robert Lopez",
      score: "5",
      date: "8 Mar 2024",
      title: "Nerdify really came through for me",
      review: "Nerdify really came through for me. I have to admit, I was a little skeptical at first but trust me when I say it is worth it. They did an awesome job! Nerdify's assistance helped me get an A on my project, which was a great boost for my grade!",
      nerdify: "Thank you, Robert, for your trust!"
    },
    {
      name: "Stacy Jenkins",
      score: "5",
      date: "22 Feb 2024",
      title: "Very helpful!",
      review: "I absolutely love Nerdify! They’re affordable and efficient. I will definitely be using these guys again❤",
      nerdify: "Stacy, we are over the moon to hear that you loved your Nerdify experience :)"
    },
    {
      name: "Stephanie Rigsby",
      score: "5",
      date: "1 Feb 2024",
      title: "Thank you so much!",
      review: "Thank you so much! Nerdify did an amazing job assisting me with a literary analysis paper. I will definitely be using their services again.",
      nerdify: "Thank you, Stephanie, for your kind words!"
    },
    {
      name: "Petrina Sweet",
      score: "5",
      date: "16 Jan 2024",
      title: "Nursing student. Assignment Tracker help!",
      review: "I was in a desperate situation and came across Nerdify. I’m in nursing school, I have 5 different syllabi and at least 4-5 different assignments for each class. I needed an assignment tracker in an Excel worksheet, so I uploaded all my syllabi and asked them to create one! They did it in 3 hours! I will be completely organized for the semester ahead! I am so grateful for their professionalism and talent. I will forever use their services! Thank you again! 10/10 💫",
      nerdify: "We’re so happy you’re satisfied with our services, Petrina!"
    },
    {
      name: "Tony Kaiser",
      score: "5",
      date: "10 Jan 2024",
      title: "Nerdify was fast and efficient",
      review: "The Nerd assigned to me was fast and efficient. He was able to answer all my inquiries quickly and throughly explained how to solve the problems. I greatly appreciate it!",
      nerdify: "Thank you, Tony! Don’t hesitate to contact us if you need more help :)"
    },
    {
      name: "Camila Flores",
      score: "5",
      date: "18 Dec 2023",
      title: "11/10!",
      review: "They were super helpful, quick to reply and efficient given my tight deadline. The quality of assistance they provided was stellar, and I was not disappointed at all!",
      nerdify: "We appreciate your positive feedback, Camila!"
    },

    {
      name: "Brian Mions",
      score: "5",
      date: "15 December, 2023",
      title: "Awesome communication",
      review: "Nerd explained the approach to my Math task in details. I was able to go through it step-by-step and understand it myself. Now, I'm not afraid of difficult Math tasks.",
      nerdify: "Thank you, Brian!"
    },
    {
      name: "Catiana Israel",
      score: "5",
      date: "12 December, 2023",
      title: "True professionals",
      review: "They're true professionals! My Nerd answered all my questions and assisted with notes for my Philosophy class, thanks 🙏🏾",
      nerdify: "Hey there, Catiana, thanks for your kind review <3"
    },
    {
      name: "Kimberly Tracy",
      score: "5",
      date: "8 December, 2023",
      title: "Anna really helped me",
      review: "Anna was great and helped me finish my project for school. She found useful sources and explained the methods I should use. It's a professional platform and super easy to use. Thank you!",
      nerdify: "Kimberly, thanks for sharing your Nerdify experience :)"
    },
    {
      name: "Marcus Smith",
      score: "5",
      date: "3 December, 2023",
      title: "Worth it",
      review: "It’s worth the money I paid! Nerd explained to me how to solve Math problems I was stuck on and gave me a list of useful study tips. No regrets!",
      nerdify: "Marcus, thank you so much!"
    },
    {
      name: "Jerry Harrison",
      score: "1",
      date: "30 November, 2023",
      title: "Didn't find a Nerd",
      review: "I reached out to them because I needed study assistance and they couldn't find a Nerd for me. I'm so disappointed.",
      nerdify: "Dear Jerry, please accept our sincere apologies for this frustrating situation and any inconvenience it caused.\nUnfortunately, in rare cases, we need more time to find the Nerd for you due to their high workload.\nWe really appreciate your honest feedback and hope you will give us one more chance."
    },
    {
      name: "Kat Denvers",
      score: "5",
      date: "17 November, 2023",
      title: "Amazing",
      review: "Nerdify is truly amazing! Thanks top them I've improved my study results. It could not have been better!!! 100%!!!!",
      nerdify: "Thank you, Kat! <3"
    },
    {
      name: "Josh Nickson",
      score: "5",
      date: "16 November, 2023",
      title: "Helped me a lot",
      review: "I'd rate the quality of Nerdify's work with more than 5 stars if I could!",
      nerdify: "Josh, thanks for your feedback!"
    },
    {
      name: "Otis Mobley",
      score: "5",
      date: "15 November, 2023",
      title: "Great!",
      review: "Great, great, great! This is a legit and trustworthy site with the best Nerds ever.",
      nerdify: "Thank you, Otis, for your great review :)"
    },
    {
      name: "Katherine Black",
      score: "5",
      date: "13 November, 2023",
      title: "24/7 support",
      review: "Having 24/7 support made all the difference. My experience was great, and customer support is awesome. Overall I will be using this service regularly, it brings me peace of mind.",
      nerdify: "Katherine, thank you so much for your kind review! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Bre Walles",
      score: "5",
      date: "30 October, 2023",
      title: "Easy to use and fast!",
      review: "Nerdify is easy to use and fast! I recommend them to everyone!",
      nerdify: "Thanks, Bre!"
    },
    {
      name: "Jas Spencer",
      score: "5",
      date: "28 October, 2023",
      title: "So helpful",
      review: "I’ve been trying to stay on track with my English but I become backed up on work and needed help. Nerd assisted me with plan and notes for my class so I was fully prepared for it. Thank you!",
      nerdify: "Thanks a lot for your review, Jas ;)"
    },
    {
      name: "Billy Yarnis",
      score: "5",
      date: "25 October, 2023",
      title: "Helpful and easy",
      review: "They're very helpful and reliable. I'd recommend using this platform.",
      nerdify: "Thank you!"
    },
    {
      name: "Rebecca Vander Horst",
      score: "5",
      date: "22 October, 2023",
      title: "Very nice!",
      review: "Very nice! It was perfect. Exactly what I needed.",
      nerdify: "Rebecca, thank you, we're glad we could help you :)"
    },
    {
      name: "Cassie Allen",
      score: "5",
      date: "19 October, 2023",
      title: "Awesome!",
      review: "Awesome!! Fast and easy :)",
      nerdify: "Cassie, thank you so much!"
    },
    {
      name: "Savanah Browder",
      score: "5",
      date: "16 October, 2023",
      title: "Perfect",
      review: "Everything was perfect and timely. I’m very satisfied and grateful for this assistance and kind service!",
      nerdify: "Thanks, Savanah, we're glad to know you're satisfied with your Nerdify experience :)"
    },
    {
      name: "Sesilia Hortons",
      score: "5",
      date: "14 October, 2023",
      title: "I’d give more than 5 if I could",
      review: "I’d give more than 5 if I could! The quick response to my text. The customer service is great, and they know their work extremely well!",
      nerdify: "Hey, Suha, thank you for such a great review!"
    },
    {
      name: "Kevin Owens",
      score: "5",
      date: "12 October, 2023",
      title: "Nerdify is a great service",
      review: "This service is great. The Nerds are knowledgeable and professional.",
      nerdify: "Hey there, Kevin, thanks for your great review!"
    },
    {
      name: "Tyler Cortis",
      score: "5",
      date: "9 October, 2023",
      title: "I love using Nerdify",
      review: "I love using Nerdify. The nerds are very knowledgeable, the customer service is great (especially with the subscription) and it helps me when I need to get a consultation. The subscription is a good price and worth it.",
      nerdify: "Hi there, thanks a lot for your feedback! We're always happy to help you ;)"
    },
    {
      name: "Willie Louis",
      score: "5",
      date: "8 October, 2023",
      title: "Helped me a lot",
      review: "Jane did such a wonderful job. English is my third language and the grammar confuses me here in the US. She checked my grammar, explained to me the rules, and highlighted the things that could be improved. I followed her recommendations and fixed some of my statements so they look much better now. I am so proud to be able to hand in this document. And I would recommend this company to anyone who needs study assistance. They were so accommodating and so helpful. I will be using this company again.",
      nerdify: "We’re so happy you’re satisfied with our services, Willie! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      name: "Nadia Atkins",
      score: "5",
      date: "5 October, 2023",
      title: "Very impressed",
      review: "Very impressed with the quality of this service and their professional approach.",
      nerdify: "Thanks, we value your feedback :)"
    },
    {
      name: "Tj Tayne",
      score: "5",
      date: "2 October, 2023",
      title: "On time",
      review: "Quick communication and high-quality service!",
      nerdify: "Thanks, Tj! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks! :)"
    },
    {
      name: "Tyra Stewenson",
      score: "5",
      date: "1 October, 2023",
      title: "Very responsive",
      review: "Very responsive. Each time I reach out for help I get a real response immediately. They work with you to provide the best assistance possible. I’ve been using them throughout my college career and I’ve improved my results significantly.",
      nerdify: "Thank you so much for your review!"
    },
    {
      name: "Laura Saponar-Taddei",
      score: "5",
      date: "28 September, 2023",
      title: "I will use this platform again",
      review: "I will use this company again and again. Quality service for a fair price and saves me all the headaches that come with small projects.",
      nerdify: "Hey, Laura, thanks for the review! We're glad to help you :)"
    },
    {
      name: "Minahil Rahimullah",
      score: "5",
      date: "25 September, 2023",
      title: "Good experience",
      review: "I felt like the experience was well worth my time. Very surprised by the fast and efficient communication. I felt like the customer service was very friendly and accommodating. I would recommend it.",
      nerdify: "Thanks, Minahil, we're here to help when you need it :)"
    },
    {
      name: "Mallory Jones",
      score: "5",
      date: "24 September, 2023",
      title: "Assisted quickly",
      review: "Assisted quickly and answered all my questions. I appreciate it",
      nerdify: "Thank you, Mallory, don't hesitate to contact us if you need more help ;)"
    },
    {
      name: "Alex Mayfield",
      score: "5",
      date: "22 September, 2023",
      title: "Fast & great service",
      review: "Fast and great service! Very informative and awesome communication!",
      nerdify: "Alex, thanks for taking the time to share your Nerdify experience with us!"
    },
    {
      name: "Steff Walles",
      score: "5",
      date: "19 September, 2023",
      title: "My overall experience was great!",
      review: "My overall experience was great! I was able to get the assistance I needed which boosted my grade up. I highly appreciate it as I needed that! For students out there just like me I definitely would recommend 100% 🙂",
      nerdify: "Thanks, Steff! We're always here to help you! :)"
    },
    {
      name: "Tina Dwane",
      score: "5",
      date: "17 September, 2023",
      title: "Fabulous experience",
      review: "My experience with these people is fabulous. They are honest and courteous. Would definitely recommend it.",
      nerdify: "Tina, thank you so much! We’re glad that we could help you."
    },
    {
      name: "Fay Richardson",
      score: "5",
      date: "14 September, 2023",
      title: "Exceptional service",
      review: "My experience with the company was great because they found a good tutor who met all my requirements and helped me with my Geography class significantly. Highly recommended.",
      nerdify: "Thanks a lot, Fay!"
    },
    {
      name: "Claudia Barboza",
      score: "5",
      date: "13 September, 2023",
      title: "Always there for me",
      review: "It was a perfect experience and I hope that I could continue the work with my Nerd.",
      nerdify: "Thanks, Claudia! We're always here to help :)"
    },
    {
      name: "Tia Terrens",
      score: "5",
      date: "9 September, 2023",
      title: "Fast service",
      review: "Fast service and awesome customer service.",
      nerdify: "Thanks, Tia!"
    },
    {
      name: "Erica Washington",
      score: "5",
      date: "7 September, 2023",
      title: "My Nerd was awesome",
      review: "My Nerd did a wonderful job.",
      nerdify: "Thanks for your wonderful review, Erica! <3"
    },
    {
      name: "Anthonie Holman",
      score: "5",
      date: "2 September, 2023",
      title: "This company is truly a lifesaver!",
      review: "This company is truly a lifesaver, the quick responses make you feel safe you can message them at any time day or night they are up to help 24/7. THIS IS NOT A SCAM! I can not stress enough how my Nerd helped me out in the best way possible. I will be using the Nerdify app throughout this semester!",
      nerdify: "Anthonie, thanks for sharing your Nerdify experience with us! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Brian Adamson",
      score: "5",
      date: "31 August, 2023",
      title: "Never let me down",
      review: "Been using there services for over a year and they haven’t let me down yet!!!",
      nerdify: "Thanks, we're glad we could help you :)"
    },
    {
      name: "Noah Watters",
      score: "5",
      date: "28 August, 2023",
      title: "Super satisfied",
      review: "Nerds are very helpful and friendly. Super satisfied",
      nerdify: "Thank you!"
    },
    {
      name: "Alexander Aguirre",
      score: "5",
      date: "26 August, 2023",
      title: "Got the help I needed",
      review: "What made my experience great was receiving the answers I needed from a hired Nerd from Nerdify.",
      nerdify: "Thanks, Alexander! We're glad to help you :)"
    },
    {
      name: "Chloe Thompson",
      score: "5",
      date: "25 August, 2023",
      title: "Great job and experience!",
      review: "I have used Nerdify three times and they have been nothing but great. Always on time and prompt to answer any questions I may have. I would recommend Nerdify to any student needing a helping hand!",
      nerdify: "Thank you, Chloe! We’re over the moon to hear that you’re happy!"
    },
    {
      name: "Ellen Miller",
      score: "5",
      date: "18 August, 2023",
      title: "It was my first experience",
      review: "It was my first experience it was very fast, wonderful, no issues at all. Highly recommended.",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Oscar Canas",
      score: "5",
      date: "17 August, 2023",
      title: "Excellent service",
      review: "The experience using Nerdify was excellent. Quick response to all my inquiries and very professional.",
      nerdify: "Thanks for your excellent review, Oscar!"
    },
    {
      name: "Nicole Stillwater",
      score: "5",
      date: "10 August, 2023",
      review: "Honestly… I was scared to try Nerdify out but I took the chance... Guys, they are actually legit! I was off for a week and had a test when I came back to class and was so lost on the topics. The test was a take-home, still, you would think it would be easy but I just didn’t understand the topic no matter how much I tried! They explained it to me so well! I would’ve failed that test regardless! I know it may seem weird when you first contact them since it’s a back-and-forth through text on your number but once you download the app all the messages are saved on the app as well automatically when you log in by entering your number! Also, I reached out to them at like 4 a.m. and they were there to find a suitable tutor for me which was amazing! They also respond instantly ready to assist you with anything! They also gave me a discount when I asked if there was a possible way to lower my fee. They also made sure they connected me with a Top Nerd! Will be trying again soon! I hope this helps for anyone wanting to try Nerdify out!",
      nerdify: "Thanks a lot for your kind review, Nicole! We're over the moon to hear that you're happy with your Nerdify experience!"
    },
    {
      name: "Melinda Davila",
      score: "5",
      date: "5 August, 2023",
      title: "The customer service was great",
      review: "The customer service was great. They respond quickly and answer all the questions. I'll be using this service again in the future!",
      nerdify: "Thank you so much, Melinda! <3"
    },
    {
      name: "Salina Kidane",
      score: "5",
      date: "31 July, 2023",
      title: "Perfect experience!",
      review: "Great customer service! 10/10!!!",
      nerdify: "Thanks for your feedback, Salina!"
    },
    {
      name: "Catherine Winters",
      score: "5",
      date: "28 July, 2023",
      title: "Great experience with the best Nerds",
      review: "Great experience with the best experts available!",
      nerdify: "Thanks for your great review!"
    },
    {
      name: "Lisa Shepard",
      score: "5",
      date: "22 July, 2023",
      title: "They did a great job",
      review: "They did a great job! With their help and advices, I prepared a great presentation for my class!",
      nerdify: "Thank you, Lisa! Share this experience with friends via your Nerdify referral link and get bonuses! ;)"
    },
    {
      name: "Connor Stone",
      score: "5",
      date: "16 July, 2023",
      title: "Satisfied",
      review: "Very detailed answer to my questions.",
      nerdify: "Thanks a lot!"
    },
    {
      name: "Anthony Broady",
      score: "5",
      date: "12 July, 2023",
      title: "Great help with my math class",
      review: "My experience with this company was great, they helped me and they reply back fast. One of the best things I found this year. I really appreciate this company and all the help they gave me.",
      nerdify: "Anthony, thank you for the review! Don't hesitate to contact us if you need more help :)"
    },

    {
      name: "Harry Terrens",
      score: "5",
      date: "6 July, 2023",
      title: "Happy with their service",
      review: "The response to my inquiry was instant. The person I communicated with did an excellent job. The answers were quick and polite. I am very happy with the help provided.",
      nerdify: "Thanks a lot for your review! Our Nerds always do their best to help you :)"
    },
    {
      name: "Daniel Mendoza",
      score: "5",
      date: "6 July, 2023",
      title: "Great Service",
      review: "Everything about my Nerdify experience was great. Professional consultation, fair price, and great service.",
      nerdify: "Thanks, Daniel! We do our best to meet high expectations like yours!"
    },
    {
      name: "David Letterman",
      score: "5",
      date: "3 July, 2023",
      title: "I recommend Nerdify",
      review: "The representative who initially took my request was very polite and really made an effort to understand what I needed help with. I had 2 different reports to prepare and I was falling behind with both of them. The Nerds assigned to me helped to figure out what to do with those reports. I say kudos to them. I won't hesitate to use your service again. I will recommend your service to others. Thanks for doing such a great job.",
      nerdify: "Thank you for a great review! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      name: "Frank Romero",
      score: "5",
      date: "28 June, 2023",
      title: "Reliable Nerds",
      review: "The quality of service is top-notch! Your company communicates extremely well and in a timely manner.",
      nerdify: "Thank you, Frank, we're always here to help :)"
    },
    {
      name: "Edgardo Quinones",
      score: "5",
      date: "26 June, 2023",
      title: "Quick and easy to use",
      review: "Quick and easy to use. Highly recommended. I will keep using Nerdify.",
      nerdify: "Edgardo, thanks for the review! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      name: "Shannon Shelton",
      score: "5",
      date: "22 June, 2023",
      title: "Nerdify saved me",
      review: "I’m usually not the one to trust different apps and services, but between my promotion and relocating I really didn’t have much of a choice. This class was the last class needed to graduate and I couldn’t risk flunking. So I contacted Nerdify and asked them to help me with it. My Nerd found all the information I needed and gave me useful notes. They were proficient and easy to understand. Thanks!",
      nerdify: "Thank you, Shannon, we truly value your trust an are always ready to help :)"
    },
    {
      name: "Omie Clayton",
      score: "5",
      date: "17 June, 2023",
      title: "Nerdify is an awesome resource",
      review: "Nerdify is an awesome resource. Their prices are reasonable and the customer support is polite. I highly recommend them!",
      nerdify: "Thank you, Omnie!"
    },
    {
      name: "Elizabeth Mariza",
      score: "5",
      date: "14 June, 2023",
      title: "Nerdify deserves a special shoutout!",
      review: "Nerdify deserves a special shoutout on my graduation. Seriously! They came through every time I needed help. They are quick to respond and very punctual! Highly recommended!",
      nerdify: "Thank you, Elizabeth! We're glad we could help you!"
    },
    {
      name: "Chantell Holden",
      score: "5",
      date: "8 June, 2023",
      title: "Fantastic!",
      review: "Great assistance and good service!",
      nerdify: "Chantell, thanks for your feedback! We're always ready to help you :)"
    },
    {
      name: "Yesica Moro",
      score: "5",
      date: "1 June, 2023",
      title: "My first time using Nerdify",
      review: "It was my first time using Nerdify and I will definitely use it again! My Nerd was very helpful and went beyond my expectations! Thank you, Nerdify!",
      nerdify: "Yesica, thanks for sharing your Nerdify experience with us! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      name: "Hana Chains",
      score: "5",
      date: "30 May, 2023",
      title: "Superior Nerds",
      review: "They explained to me the approach and solution of my Math problem. I managed to prepare my task on time and save some time for my family.",
      nerdify: "Thanks, Hana! We're always here to help :)"
    },
    {
      name: "Ariella Bennian",
      score: "5",
      date: "27 May, 2023",
      title: "You should try them",
      review: "Everyone in Nerdify was easy to work with, responds were quick, and they're very friendly. Highly recommended.",
      nerdify: "Thanks for your great review! :)"
    },
    {
      name: "Samantha Warren",
      score: "5",
      date: "24 May, 2023",
      title: "Perfect!",
      review: "Amazing! Thanks to them I've prepared a great project and got good results!",
      nerdify: "Samantha, thank you for the review :)"
    },

    {
      name: "Betty Ladds",
      score: "5",
      date: "21 May, 2023",
      title: "Great customer service",
      review: "Great customer service! They provided notes for my project on time and they met all the expected criteria. Thanks!",
      nerdify: "Thank you! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      name: "Keith Kogane",
      score: "5",
      date: "19 May, 2023",
      title: "Excellent!",
      review: "I had an excellent experience with Nerdify. They were very accommodating and communicative. Moreover, the Nerd found the data I was looking for a long time in a reasonable time and the pricing was fair too.",
      nerdify: "Hey there, thanks for your review! :)"
    },
    {
      name: "Shaketa Rouse",
      score: "5",
      date: "18 May, 2023",
      title: "I love Nerdify",
      review: "When I need study assistance Nerds always help me even if it’s last minute… I’m glad I found Nerdify cause without them I would fail my class… I recommend them to everyone 🥰 I’ve used them four times already and my experience is great! 💪🏾",
      nerdify: "Shaketa, thanks for the kind words! Hope that you already had a chance to share this experience with friends via your Nerdify referral link and get bonuses! ;)"
    },
    {
      name: "Chantell Holden",
      score: "5",
      date: "16 May, 2023",
      title: "Great assistance and explanation",
      review: "I know that I can always rely on Nerdify, They always provide assistance on time and with good pricing. I'd recommend them to everyone.",
      nerdify: "Thank you, Chantell! <3"
    },
    {
      name: "Mary Arkansas",
      score: "5",
      date: "14 May, 2023",
      title: "Nerdify is the best",
      review: "Nerdify is the best in assistance with any subject. I love them so much. They were amazing every time and helped me a lot during my college terms.",
      nerdify: "Thank you, Mary, for your amazing review!"
    },
    {
      name: "Bobby Wayne",
      score: "5",
      date: "11 May, 2023",
      title: "Worth it.",
      review: "I was looking for study help and found Nerdify via Google. I’m glad they were quick to respond and to help me out. I had last minute change to my project and they were very understanding. The Nerd found the necessary information as I asked and it’s worth the price I paid. I managed to complete everything on time. Thank you again!",
      nerdify: "We’re happy that you loved your Nerdify experience. Don’t hesitate to contact us if you need more help!"
    },
    {
      name: "Laura Melo",
      score: "5",
      date: "11 May, 2023",
      title: "Reliable",
      review: "Nerdify provides a very good service. Thank you!",
      nerdify: "Thank you, Laura! It's our pleasure to help you :)"
    },
    {
      name: "Derry Harris",
      score: "5",
      date: "11 May, 2023",
      title: "Nerdify is awesome",
      review: "Nerdify is awesome. I’ve never been disappointed with them and I’ve been a customer for over 3 years now.",
      nerdify: "Thanks for your review, Derry! We appreciate you being our customer for such a long time and are always here to help :)"
    },
    {
      name: "Fayez Hatam",
      score: "5",
      date: "10 May, 2023",
      title: "Easy to communicate",
      review: "It’s easy to contact their service, they answer right away and are always ready to help. If you're a graduate student and need additional study assistance Nerdify is a good choice!",
      nerdify: "Thank you so much for the wonderful review, Fayez!"
    },
    {
      name: "Marcie Walker",
      score: "5",
      date: "10 May, 2023",
      title: "Very convenient",
      review: "This app is very convenient and the customer support is awesome. They answer all your questions and you can be sure you'll get the necessary help.",
      nerdify: "Marcie, thank you!"
    },
    {
      name: "Angela Marian Wantate",
      score: "5",
      date: "10 May, 2023",
      title: "Thanks for help!",
      review: "You will always get the study backup with the Nerds' guidelines. Nerdify offers a lot of assistance and reasonable prices. Thanks for all your help 🙏🏾",
      nerdify: "Thank you for taking the time to share your experience with us, Angela! We’re over the moon to hear that you’re happy."
    },
    {
      name: "Cassii Linc",
      score: "5",
      date: "10 May, 2023",
      title: "Fast and efficient service",
      review: "Fast and very efficient service. I used Nerdify twice and would have used them way more if I wasn’t a broke college student 😂 They're definitely helpful when you don’t have enough time for research and collecting information.",
      nerdify: "We're so glad that you had a positive experience with us, Cassii!"
    },
    {
      name: "Chantae Harrell",
      score: "5",
      date: "10 May, 2023",
      title: "Nerdify is a lifesaver",
      review: "I recommend Nerdify to everyone because these guys are awesome! Without Nerdify I would’ve failed my class.",
      nerdify: "Thank you for the great review, Chantae! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Tyran Lafrance",
      score: "5",
      date: "10 May, 2023",
      title: "Great work",
      review: "They helped me with every task that I ever asked of in a timely manner and thanks to it I've significantly improved my study results.",
      nerdify: "Thank you, Tyran! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      name: "Donnie Mallard",
      score: "5",
      date: "10 May, 2023",
      title: "Easy and convenient to use",
      review: "Easy and convenient to use. Pricing is fair enough too.",
      nerdify: "Thanks, Donnie! ;)"
    },
    {
      name: "Lais Hernandez",
      score: "5",
      date: "10 May, 2023",
      title: "So far so good",
      review: "So far I have had a great experience with Nerdify and they're very reliable. I have been using this app for 2 years and they never let me down.",
      nerdify: "Thank you for taking the time to leave a review, Lais! Your feedback means a lot to us :)"
    },
    {
      name: "Courtney Carson",
      score: "5",
      date: "10 May, 2023",
      title: "My Nerd was great",
      review: "The last Nerd I had was great! Zero issues and didn't have to return to them to fix anything.",
      nerdify: "Thanks, we're thrilled to hear that you had a positive experience with us!"
    },
    {
      name: "Kevin Cao",
      score: "5",
      date: "10 May, 2023",
      title: "Great service",
      review: "It’s a great service to help college students and graduates to get by when they are in a pinch. Nerdify is a good choice even for office work or small projects.",
      nerdify: "Thanks, Kevin! It’s our pleasure to help you :)"
    },
    {
      name: "Carter Kemmis",
      score: "5",
      date: "9 May, 2023",
      title: "Very quick",
      review: "Very quick and great work! I would like the prices to be a little lower, but overall experience was great!",
      nerdify: "Thank you, Carter! Don’t hesitate to contact us if you need more help!"
    },
    {
      name: "Sandra Hernández",
      score: "5",
      date: "4 May, 2023",
      title: "Highly recommended",
      review: "The communication was great, everything was so quick and efficient. Thanks to my Nerd I've managed to complete my presentation on time. Highly recommended!",
      nerdify: "Thanks, Sandra! Don’t hesitate to contact us if you need more help!"
    },
    {
      name: "Miguel Quades",
      score: "5",
      date: "3 May, 2023",
      title: "Satisfied client",
      review: "These guys are a huge help when it comes to studies. I significantly improved my results in English and it’s more than satisfying for me. I’m trying to survive the semester and graduate, so I recommend using Nerdify's assistance when you feel overwhelmed. These Nerds definitely know how to find an approach and help.",
      nerdify: "Thanks, Miguel! Don’t hesitate to contact us if you need more help!"
    },
    {
      name: "Crystal Alex McGadney House",
      score: "5",
      date: "28 April, 2023",
      title: "Right on time",
      review: "I asked for assistance with a really difficult project 24hrs in advance. They found the information for it really fast! I finished my work right on time! These folks are true lifesavers! I'll be using them again and recommending them to my friends!",
      nerdify: "Thank you for the great review! Keep in mind that you can get bonuses for your next tasks if you invite your friends to Nerdify via a referral link ;)"
    },
    {
      name: "Ashagay Hall",
      score: "5",
      date: "26 April, 2023",
      title: "Best assistance ever",
      review: "The agents are polite and respond very quickly, they answered all my questions and made sure any issue I had was resolved. I will definitely be using this service for the rest of my school years.",
      nerdify: "Thanks, Ashagay! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks! ;)"
    },
    {
      name: "Sean Masters",
      score: "5",
      date: "24 April, 2023",
      title: "Very punctual",
      review: "Very punctual. The quality of their work is top-notch and the prices are very reasonable. I highly recommend this service.",
      nerdify: "Thanks! We're thrilled to know you're satisfied with your Nerdify experience, Sean!"
    },
    {
      name: "Jane Niglet",
      score: "5",
      date: "21 April, 2023",
      title: "Timely manner",
      review: "Timely manner, immediate responses & good service.",
      nerdify: "Thanks, Niglet! We’re glad that we could help you :)"
    },
    {
      name: "Peter Waynard",
      score: "5",
      date: "11 April, 2023",
      title: "They did a great job for me",
      review: "Nerd did a great job. I was kept informed during the whole process and I was always able to communicate with a Nerdify representative to get answers to my questions or concerns.",
      nerdify: "Thank you so much for your kind words!"
    },
    {
      name: "Margaret Satersmoen",
      score: "5",
      date: "5 April, 2023",
      title: "Well done",
      review: "They did everything well and it's easy to communicate with Nerdify. I recommend them.",
      nerdify: "Thank you so much, Margaret <3"
    },
    {
      name: "Ariel Patterson",
      score: "5",
      date: "2 April, 2023",
      title: "Saved me!",
      review: "Excellent service and fast replies!",
      nerdify: "Thank you!"
    },
    {
      name: "Peter Banner",
      score: "5",
      date: "23 March, 2023",
      title: "Good quality",
      review: "I needed to collect information for my project and asked Nerds for help. They made a research for me on time and the material they found was a perfect match for my project. Thanks!",
      nerdify: "Thanks, Peter! Don't hesitate to contact us if you need more help :)"
    },
    {
      name: "Jonathan Miranda",
      score: "5",
      date: "19 March, 2023",
      title: "Excellent service",
      review: "The service was affordable and the Nerd was quick to answer my questions. Both tasks I was stuck on I completed with excellent results. I used the service twice because I liked how they helped me the first time and that's why contacted them again.",
      nerdify: "Jonathan, thanks for your excellent review! :)"
    },
    {
      name: "Ann Robles",
      score: "5",
      date: "17 March, 2023",
      title: "Very reliable",
      review: "I got a very reliable Nerd that responded really fast! I would definitely recommend Nerdify if you need a last minute study help!",
      nerdify: "Thanks, we appreciate your feedback!"
    },
    {
      name: "Samuel Soler",
      score: "5",
      date: "14 March, 2023",
      title: "Fantastic service",
      review: "Fantastic service, extremely helpful. Nerds are there when you need them. I will definitely recommend Nerdify.",
      nerdify: "Thanks, Samuel! We’re glad that we could help you :)"
    },
    {
      name: "Nicole Mayers",
      score: "5",
      date: "13 March, 2023",
      title: "This service is amazing",
      review: "The service is amazing! This is my 3rd time using Nerdify. Their Nerds are top-notch. Customer service is great. You can communicate with a real person 24hrs a day, 7 days a week. And, I always get the assistance I need on time! I will definitely recommend Nerdify to my friends and family.",
      nerdify: "Thanks for your amazing review, Nicole! Hope that you already had a chance to use our referral program and get bonuses ;)"
    },
    {
      name: "Arshu Ubhi",
      score: "5",
      date: "11 March, 2023",
      title: "Great experience",
      review: "My experience was great and the Nerd who was assigned to me was wonderful. He did and amazing job with collecting information for my presentation and followed all my demands. I’m so thankful to the Nerdify team!",
      nerdify: "Thank you, Arshu! Don't hesitate to contact us if you need more help :)"
    },
    {
      name: "Melissa Apton",
      score: "5",
      date: "10 March, 2023",
      title: "Polite communication",
      review: "The communication was pleasant and polite. The response to my inquiries was really quick! Nerdify helped me to improve my study results!",
      nerdify: "Thank you for you review! We're glad we could help you ;)"
    },
    {
      name: "Martin Lissa",
      score: "5",
      date: "9 March, 2023",
      title: "Nerdify is the best",
      review: "Nerdify is always quick when responding to its client. They are polite and communication is great. They are the best when it comes to study help. l would recommend them.",
      nerdify: "Thanks, we appreciate your feedback :)"
    },
    {
      name: "Kamdyn Hoskins",
      score: "5",
      date: "5 March, 2023",
      title: "Quick and easy",
      review: "The text communication made my experience quick and easy. Nerd helped me with simple question very fast. They continue helping me with more of my questions and tasks. Love it!!!!",
      nerdify: "Kamdyn, thank you for your review! Hope that you already had a chance to share your Nerdify referral link with friends and get bonuses ;)"
    },
    {
      name: "Kimberly Forté",
      score: "5",
      date: "5 March, 2023",
      title: "Well done",
      review: "My Nerd reviewed my presentation and highlighted the things I should pay attention to which helped me to exclude some mistakes and I'm very happy about it. Thanks to it I got a good grade. Thank you!",
      nerdify: "Thanks, Kimberly! We appreciate your feedback :)"
    },
    {
      name: "Harlie Smith",
      score: "5",
      date: "28 February, 2023",
      title: "Thank you!",
      review: "I'm just really happy. You've saved me. I needed help so bad, and you were there for me. Now I can finish my class without paying $200 for an extension. Thank you!",
      nerdify: "We’re so happy you’re satisfied with our services, Harlie! :)"
    },
    {
      name: "Carlos Rivera",
      score: "5",
      date: "27 February, 2023",
      title: "Great customer service and support",
      review: "Good customer service and great Nerds. I had a good experience so far and the pricing is fair too. A customer support representative walked me through the process. I'm so glad I found them. Looking forward to continuing my cooperation with Nerdify.",
      nerdify: "Thank you so much, Carlos! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Gabbie Zanders",
      score: "5",
      date: "21 February, 2023",
      title: "Always willing to help",
      review: "Nerds are always willing to help, patient, and understanding. They do an excellent job.",
      nerdify: "Thank you so much! <3"
    },
    {
      name: "Demi Jones",
      score: "5",
      date: "20 February, 2023",
      title: "Great communication and flexibility",
      review: "Great communication and very helpful. Thank you, Nerdify!",
      nerdify: "Thank you, Demi!"
    },
    {
      name: "Tessy Goldberg",
      score: "5",
      date: "19 February, 2023",
      title: "Easy to talk to",
      review: "They were very easy to talk to! They were super clear about everything and all the pricing. They replied very quickly and always answered all my questions!",
      nerdify: "Thanks, Tessy! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Osman Careem",
      score: "5",
      date: "16 February, 2023",
      title: "Anna did a great job",
      review: "Anna did a great job quickly identifying what I needed help with and executing perfectly. I got the consultation within the 30 minutes. I definitely recommend Nerdify! 10/10!",
      nerdify: "Thanks, Osman! Don’t hesitate to contact us if you need more help! :)"
    },
    {
      name: "Mikayla Tinkler",
      score: "5",
      date: "13 February, 2023",
      title: "Timely and Responsive",
      review: "Nerdify is always on time and provides a very useful service! Much recommended.",
      nerdify: "Thank you, Mikayla! We do our best to help you :)"
    },
    {
      name: "Noah Lewis",
      score: "5",
      date: "9 February, 2023",
      title: "Great Nerds",
      review: "Great Nerds! They always answer all my questions and help me to improve my study results. Thank you!",
      nerdify: "We're happy to know you had a great experience with Nerdify! Thanks!"
    },
    {
      name: "Kaye Withers",
      score: "5",
      date: "6 February, 2023",
      title: "These guys are amazing",
      review: "These guys are amazing. Statistics is hard as is and these guys came through! I definitely will finish my semester with them. I would highly recommend them to anyone struggling with any Math classes!",
      nerdify: "Thanks for your amazing review, Kaye! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks! :)"
    },
    {
      name: "Cassandra Williams",
      score: "5",
      date: "2 February, 2023",
      title: "I was satisfied",
      review: "I was satisfied with my communication with Nerd. Thanks to her I finished my presentation within 2 days like I expected. Thanks a lot!",
      nerdify: "Cassandra, thank you so much! :)"
    },
    {
      name: "Anthony Parker",
      score: "5",
      date: "30 January, 2023",
      title: "Quick replying",
      review: "Quick replies and great customer service!",
      nerdify: "We appreciate your positive feedback, Anthony, thanks!"
    },
    {
      name: "Jai Nguyen",
      score: "5",
      date: "29 January, 2023",
      title: "Nerdifiy is great helper!",
      review: "Nerdifiy is a great assistant when it comes to different tasks, presentations, and projects. Customer service is very helpful and approachable. They are very attentive to the details and do everything on time if not earlier. Highly Recommended. I'm glad I found Nerdifiy.",
      nerdify: "Jai, thanks for sharing with us your Nerdify experience!"
    },
    {
      name: "Ariel Jones",
      score: "5",
      date: "25 January, 2023",
      title: "I love Nerdify",
      review: "I love Nerdify! They are always prompt and ready to answer to any questions, comments or concerns right away. Pricing is fair and thanks to their help I improved my study results significantly!",
      nerdify: "We love you too, Ariel! Thanks for your review! <3"
    },
    {
      name: "Kasey Edwards",
      score: "5",
      date: "21 January, 2023",
      title: "The Customer service is great",
      review: "The customer service at Nerdify is great. They listen to everything you say and help you with everything. They do their job very well.",
      nerdify: "Thanks, Kasey! Don’t hesitate to contact us if you need more help!"
    },
    {
      name: "Daniel Calderon",
      score: "5",
      date: "14 January, 2023",
      title: "Great",
      review: "Very quick responses and great communication. Fair prices too. I would recommend it to everyone.",
      nerdify: "Thank you, Daniel! :)"
    },
    {
      name: "Samuel Levi",
      score: "5",
      date: "6 January, 2023",
      title: "Best investment ever",
      review: "Best investment ever. Whoever my Nerd was, they need to be promoted to a TOP Nerd … #OUTSTANDING",
      nerdify: "Your feedback means a lot to us! We'll tell thanks to your Nerd :)"
    },

    {
      name: "Anthony Russel",
      score: "5",
      date: "21 December, 2022",
      title: "Fast responses to all questions!",
      review: "Super fast response and excellent pricing. I will definitely recommend them to my friends.",
      nerdify: "Thank you for taking the time to share your experience with us, Anthony!"
    },
    {
      name: "Warren Kolt",
      score: "5",
      date: "19 December, 2022",
      title: "Professional Nerds",
      review: "The Nerd did exactly what I needed. I am more than satisfied with my experience.",
      nerdify: "Thank you so much for your feedback! We're glad to help you!"
    },
    {
      name: "John Gardens",
      score: "5",
      date: "16 December, 2022",
      title: "I'm satisfied with my experience",
      review: "I used the app for the first time and liked my experience. Very timely manner and the customer support team was very helpful and patient to me. Everything went very well.",
      nerdify: "Thank you for taking the time to share your experience with us, John!"
    },
    {
      name: "Melanie Sanders",
      score: "5",
      date: "15 December, 2022",
      title: "Awesome work",
      review: "It was my first time using this app and it was great because Nerds helped me to cope with the critical problems of my task and I completed it on time. Nerdify is truly worth it.",
      nerdify: "Thank you so much! :)"
    },
    {
      name: "Valentina Marin",
      score: "5",
      date: "12 December, 2022",
      title: "Amazing service",
      review: "This was an easy and very helpful experience! Everything went great! Fast results, fast replies, and all my questions were answered with no problem. Definitely will be coming back for more help. Appreciate it!",
      nerdify: "Thank you, Valentina! Remember that you can share this experience with friends via your Nerdify referral link and get bonuses! :)"
    },
    {
      name: "John Doeson",
      score: "5",
      date: "9 December, 2022",
      title: "Worth it",
      review: "Fast and easy! Helped me with a very complicated task and I completed it on time! Thanks!",
      nerdify: "Thank you, John! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      name: "Macario Harris",
      score: "5",
      date: "8 December, 2022",
      title: "Very good",
      review: "They were fast and very good! I was hesitant about doing it at first but I definitely will be using Nerdify again.",
      nerdify: "Thank you, Macario! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Colby Southers",
      score: "5",
      date: "6 December, 2022",
      title: "Useful website",
      review: "I have used this site for assistance with my studies. They have always been quick and accurate. I would highly recommend Nerdify to anyone.",
      nerdify: "Thank you, Colby! It's great that we helped you! :)"
    },
    {
      name: "Audrey Hermine Nsangou",
      score: "5",
      date: "6 December, 2022",
      title: "Amazing experience",
      review: "I was a bit scared that I wasn't going to hear back as soon as possible. Also, the agent I was in contact with was very understanding about the situation. And the Nerd did an amazing job. They provided me with detailed notes and explanations for my tasks. I am very thankful for it. My experience was amazing, this is my second time using Nerdify and I plan on using it in the future.",
      nerdify: "We're so happy you had an amazing Nerdify experience, Audrey! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Savanna Callis",
      score: "5",
      date: "5 December, 2022",
      title: "Professional help",
      review: "The professionalism and the willingness to help are top-notch! They have done a good job with not only my task but customer service itself! I was very skeptical, but I am very pleased and will recommend them to everyone!",
      nerdify: "Thank you so much, Savanna! We're happy to help!"
    },
    {
      name: "Soleio Watters",
      score: "5",
      date: "3 December, 2022",
      title: "Fast and great communication",
      review: "They answered my questions very quickly and provided me with all the necessary information. Thanks for your assistance!",
      nerdify: "Thank you for taking the time to share your experience with us, Soleio!"
    },
    {
      name: "Maria Arbizu",
      score: "5",
      date: "2 December, 2022",
      title: "Helpful services",
      review: "They answer super fast and completed my task quickly and professionally :)",
      nerdify: "Thanks, Maria! Don't hesitate to contact us if you need more help!"
    },
    {
      name: "Febby Kyawt",
      score: "5",
      date: "1 December, 2022",
      title: "Worth every penny",
      review: "I found out about Nerdify nearly at the end of the semester and I regret it. English is not my first language and it was difficult for me to complete my task. So I didn’t even have enough time to prepare for other subjects which are more important for my career, and now, Nerdify just saved me. I can't even describe how fast their responses are. The customer support is so approachable and patient. It was the first time for me so I didn't know what to do, but they were so patient with me. I loved the quality of the task completed. I will recommend Nerdify to all my friends!",
      nerdify: "We're so happy to know we could help you, Febby! :)"
    },
    {
      name: "Dvonte Frett",
      score: "5",
      date: "30 November, 2022",
      title: "Nerdify Review",
      review: "Nerdify is doing a wonderful job here and customer service is wonderful. Tasks are completed when they’re supposed to be, the work is detailed and thorough. I would definitely recommend you guys to anyone who needs study help.",
      nerdify: "Thanks, Dvonte, for your wonderful review! :)"
    },
    {
      name: "Christina Daggett",
      score: "5",
      date: "30 November, 2022",
      title: "Definitely recommend",
      review: "Nerdify is very professional and provides great services. They were easy to get in touch with, but prices could be a little better. Overall, Nerdify took weight off my shoulders. I definitely recommend Nerdify.",
      nerdify: "Thank you for taking the time to share your experience with us, Christina!"
    },
    {
      name: "Simran Jaswal",
      score: "5",
      date: "30 November, 2022",
      title: "It was good",
      review: "It was good but the prices are too high. Though they completed a task on time and the quality was decent. I liked the service.",
      nerdify: "We're always here to help you, Simran!"
    },
    {
      name: "Lilliana Flores",
      score: "5",
      date: "29 November, 2022",
      title: "Good Work",
      review: "Very fast response time and they followed my instructions thoroughly. Completed the task on time. I recommend them 100%.",
      nerdify: "Thanks for sharing, Lilliana, we are thrilled to receive such a great rating!"
    },
    {
      name: "Garry Porter",
      score: "5",
      date: "29 November, 2022",
      title: "Fascinating results and services",
      review: "I love the Nerdify service received. At first, I was scared because of the scam sites but you can totally trust Nerdify. Along with that, their honesty and fast service are icing on the cake. They completed a task very professionally. Overall I had a good experience with them.",
      nerdify: "We appreciate your fascinating feedback! :)"
    },
    {
      name: "Dean Jackson",
      score: "5",
      date: "29 November, 2022",
      title: "Nerdify is awesome",
      review: "The communication via text is great and convenient. They answer really fast. The Nerds were late a couple of times, which it disappointing but it happens sometimes. Overall, I liked my experience. Thank you!",
      nerdify: "We really appreciate your feedback! Thanks!"
    },
    {
      name: "Lidia Habte",
      score: "5",
      date: "29 November, 2022",
      title: "They helped me",
      review: "The Nerds helped me with my task. Thanks to their guidelines and notes I got a better understanding of a subject.",
      nerdify: "Thank you, Lidia!"
    },
    {
      name: "Tiffany Fadden",
      score: "5",
      date: "29 November, 2022",
      title: "The best customer service",
      review: "Nerdify has the best customer service response time. Their quality of work was great! My only concern was the price but that was my fault. I waited too long to place my request. Overall, my experience with Nerdify was well worth it.",
      nerdify: "We’re so happy you’re satisfied with our services, Tiffany!"
    },
    {
      name: "Ashley Scott",
      score: "5",
      date: "29 November, 2022",
      title: "Quick and easy",
      review: "I got help with my tasks quickly and I liked the quality. The prices are fine and reasonable. I'd recommend it to my friends.",
      nerdify: "Thank you, Ashley! <3"
    },
    {
      name: "Sean Khanna",
      score: "5",
      date: "29 November, 2022",
      title: "You won't be disappointed",
      review: "Outstanding experience. Prompt and helpful customer service via chat. I received the completed task within the promised time frame. A detailed and clear solution to my request was provided along with helpful explanations.",
      nerdify: "Thank you for your review! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Kristi Blades",
      score: "5",
      date: "29 November, 2022",
      title: "Great app to use",
      review: "If you need study help try using Nerdify! Their service is top-notch and the pricing is fair.",
      nerdify: "Thank you so much!"
    },
    {
      name: "Lauren Colletti",
      score: "5",
      date: "29 November, 2022",
      title: "Would recommend",
      review: "Prices are reasonable. Good customer service, fairly simple to use.",
      nerdify: "Thank you, Lauren!"
    },
    {
      name: "Jamie Quay",
      score: "5",
      date: "29 November, 2022",
      title: "Love Nerdify",
      review: "Love Nerdify! Always a great experience!",
      nerdify: "We're always ready to help you, Jamie! ;)"
    },
    {
      name: "Solomon Davis",
      score: "5",
      date: "29 November, 2022",
      title: "Wonderful service!",
      review: "Wonderful service!!! I would recommend it ❤️",
      nerdify: "Thank you so much! <3"
    },
    {
      name: "Tammy Slaten",
      score: "5",
      date: "29 November, 2022",
      title: "So grateful",
      review: "I improved my results and productivity significantly thanks to Nerds' help! I’m so grateful to you!",
      nerdify: "We're always here to help, Tammy! Thanks for your review!"
    },
    {
      name: "Jay Williams",
      score: "5",
      date: "29 November, 2022",
      title: "Amazing results",
      review: "My results are amazing, they understood the request I had and executed it with perfection.",
      nerdify: "We appreciate your amazing review, Jay!"
    },
    {
      name: "Shawn Anderson",
      score: "5",
      date: "28 November, 2022",
      title: "Awesome experience",
      review: "My experience was awesome! The service and response time were great. Very professional every step of the way. I would recommend it to anyone and will use it again for sure!!!!",
      nerdify: "It's good to know that you had an awesome Nerdify experience, Shawn. Thank you!"
    },
    {
      name: "Chris Martinez",
      score: "5",
      date: "25 November, 2022",
      title: "Great work",
      review: "Great work! Dependable and quick turnaround. Thanks, Nerdify!",
      nerdify: "Chris, thanks for sharing with us your Nerdify experience!"
    },
    {
      name: "Daena Gayle",
      score: "5",
      date: "21 November, 2022",
      title: "They’re Top Tier",
      review: "They’re perfect. Everything from communication to making payment was so easy. I will 200% be using their services in the future and I’m glad I came across their site. Their service lifted a huge amount of stress off of my shoulders and their timing is impeccable. Exceptional work and service. 10/10 would recommend it.",
      nerdify: "Thanks, Daena, for your perfect review! We recommend you share this experience with friends via your Nerdify referral link and get bonuses for your next tasks! ;) "
    },
    {
      name: "Ta'Shana Bean",
      score: "5",
      date: "19 November, 2022",
      title: "Highly recommend",
      review: "Nerdify's study assistance is highly recommended. They are prompt and efficient. I was referred by a friend, now I am referring others. They do everything in a timely manner. I am very pleased! Honestly, Nerdify has exceeded all my expectations. Thanks!",
      nerdify: "Thank you for your good review! Good to know that our referral program also helped you ;)"
    },
    {
      name: "Joi Davis",
      score: "5",
      date: "18 November, 2022",
      title: "Great communication!",
      review: "Great communication! Nerdify is very helpful and affordable :)",
      nerdify: "Thanks, Joi! Let us know if we can help more :)"
    },
    {
      name: "Isaac Shainblum",
      score: "5",
      date: "17 November, 2022",
      title: "Very quick and effective",
      review: "Very quick turnaround even with a very minor inconvenience impossible to predict. Thanks for your help!",
      nerdify: "Thank you for your review, Isaac!"
    },
    {
      name: "Audrey Self",
      score: "5",
      date: "13 November, 2022",
      title: "Quick and on point!",
      review: "I have used Nerdify 2 times already and I had a great experience both times! They answer quickly and send you back your task on time. I'm satisfied with the results of using Nerdify. They really help and I love their services!",
      nerdify: "Thanks, Audrey! Please, don't forget that you can share your experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      name: "Yousef Monterey",
      score: "5",
      date: "9 November, 2022",
      title: "Great customer service!",
      review: "Thanks Nerdify for the help with my tasks! They explained to me every Math topic I had issues with and provided with detailed notes. It was great! I will recommend Nerdify to my friends!",
      nerdify: "Thank you, Yuosef! We appreciate your feedback!"
    },
    {
      name: "Jack Russel",
      score: "5",
      date: "7 November, 2022",
      title: "Response time is quick",
      review: "The response time is quick. The customer support tries to help with any issues and I really appreciate the availability to help in my time of need. Thanks, Nerdify!",
      nerdify: "Thanks! Don’t hesitate to contact us if you need more help!"
    },


    {
      name: "Brittenye Simmons",
      score: "5",
      date: "31 October, 2022",
      title: "Overall excellent customer service",
      review: "Overall excellent customer service. The only thing that I did have an issue with was a guideline not being fully provided. One step was missed. I will say once it was brought to their attention, it was added immediately. I would definitely refer it to a friend who is struggling or just needs help with any of their projects!",
      nerdify: "Brittenye, thank you! We're glad we could help you. Please, don't forget that you can get additional bonuses for your tasks if you share the referral link with your friends ;)"
    },
    {
      name: "Michael Angursday",
      score: "5",
      date: "27 October, 2022",
      title: "Excellent and punctual work",
      review: "Excellent and quick help with my task. Nothing much I can say, just give them a try.",
      nerdify: "Michael, we appreciate your excellent review. Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Divit Varma",
      score: "5",
      date: "26 October, 2022",
      title: "It was decently worth every cent",
      review: "It was decently worth every cent, not to mention how fast and professional they were.",
      nerdify: "Thank you so much, Divit! We’re so happy you’re satisfied with our services :)"
    },
    {
      name: "Juvenska Charles",
      score: "5",
      date: "21 October, 2022",
      title: "100% satisfaction, good customer service…",
      review: "100% satisfaction! Good customer service always.",
      nerdify: "Thanks for your review! We’re glad that we could help you :)"
    },
    {
      name: "Tamar Flores",
      score: "5",
      date: "12 October, 2022",
      title: "It was a good choice to work with you",
      review: "I needed assistance with my geographic project. My Nerd was great and explained everything. The research also was accomplished thoroughly, and it was well-planned. Overall, it was very well executed, and all the information provided was really useful.",
      nerdify: "Tamar, thanks for your positive feedback! Don’t hesitate to contact us if you need more help!"
    },
    {
      name: "Bob Thompson",
      score: "5",
      date: "10 October, 2022",
      title: "I can’t even believe this is real",
      review: "I can't even believe this is real. The Nerds saved me with my research project. It relieves so much stress knowing they are there.",
      nerdify: "We're always here for you any time you need us, Bob!"
    },
    {
      name: "Lolade Olagunju",
      score: "5",
      date: "8 October, 2022",
      title: "Good job",
      review: "I contact them on short notice, and they meet up within that short notice and come up with a nice job for me. Thanks.",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Antony Davis",
      score: "5",
      date: "6 October, 2022",
      title: "Always better than expected",
      review: "The entire process is very smooth and you get responses in a timely manner. They also keep you updated throughout the process, so you don’t feel left in the dark. I always recommend them. You can tell they take their job very seriously.",
      nerdify: "Thank you so much for recommending us! Hope you already got a chance to use your referral bonuses ;)"
    },
    {
      name: "Omar Mohammed",
      score: "5",
      date: "3 October, 2022",
      title: "30/10 I recommend this service",
      review: "30/10 I recommend it! The best service out there. Get in touch with them ASAP.",
      nerdify: "We're happy to help you, Omar!"
    },
    {
      name: "Ayesha Hills",
      score: "5",
      date: "30 September, 2022",
      title: "Timely help with my project",
      review: "I was stuck with my project and looked for someone to help me, but that guys just stole my money and I was losing my points. Then I started searching for help online and found Nerdify. Nerdify is the best study helper! They immediately took care of me, made the research I needed, and completed it on the promised time. Will recommend!",
      nerdify: "Thanks for your feedback, Ayesha! Please don't forget you can invite friends to Nerdify and earn referral bonuses ;)"
    },
    {
      name: "Enrique Crimann",
      score: "5",
      date: "27 September, 2022",
      title: "Great job",
      review: "Great job, well done! Fast and efficient. I would use this site again.",
      nerdify: "Thank you, please don't forget to invite friends to Nerdify so you can earn referral bonuses!"
    },
    {
      name: "Mai Joe",
      score: "5",
      date: "23 September, 2022",
      title: "Nerds helped me a lot",
      review: "The Nerds helped me to complete my task by the due date. I liked the way it was handled and the price was quite reasonable. I will recommend it to my friends.",
      nerdify: "We'll be happy to see you back soon!"
    },
    {
      name: "Aimee Melendez",
      score: "5",
      date: "19 September, 2022",
      title: "Excellent Experience",
      review: "I have had an excellent experience so far! It has been very easy to communicate with my Nerd. All my questions were answered super fast! I managed to prepare my task even earlier than expected! I would definitely recommend Nerdify and will absolutely be using them again for future needs!",
      nerdify: "Thank you so much, Aimee! We'll be happy to give you referral bonuses for recommending us to friends :)"
    },
    {
      name: "Spencer Richards",
      score: "5",
      date: "18 September, 2022",
      title: "Highly effective",
      review: "Your work reflects a very refined ability and understanding of the task explained. I hope you will consult me on my future projects too!",
      nerdify: "We'll send this review to your Nerd, thanks!"
    },
    {
      name: "Alexandria Hoarau",
      score: "5",
      date: "16 September, 2022",
      title: "Helpful consultation",
      review: "I was helping my 8th grader with his math homework, and one of the questions had me stumped. I could Google the answer, but I wanted a clear explanation of how to solve it, so I could explain it to him and be confident that he would be able to answer a similar question on an upcoming test. I’m so glad I found Nerdify. It was extremely helpful! The explanation was easy to understand, and I could teach it to my son without problems. I went ahead and signed up for the monthly subscription, so I can be confident in my ability to help my son with his Math for the entire school year, especially because it’s the subject he struggles with the most. Knowing I can help him also saves me a lot of money on a tutor. Thank you, Nerdify!",
      nerdify: "Thanks for finding time to write a review, Alexandria! Please don't forget to invite friends to Nerdify so you can earn referral bonuses ;)"
    },
    {
      name: "John Romero",
      score: "5",
      date: "16 September, 2022",
      title: "Very satisfied with the service",
      review: "Very satisfied with the service I was provided.",
      nerdify: "Thanks, John!"
    },
    {
      name: "Leila Williams",
      score: "5",
      date: "14 September, 2022",
      title: "I had a great experience!",
      review: "I had a great experience! Thanks for the fast replies. I thought it was a scam at first, but they really helped me.",
      nerdify: "Thanks, Leila! Hope you already got a chance to earn bonuses you can use for future tasks ;)"
    },

    {
      name: "Jdestini Burton",
      score: "5",
      date: "10 September, 2022",
      title: "THANK YOU",
      review: "From the person who helped me find a Nerd to work on my request and to the Nerd that actually completed it, I really appreciate your help, and thank y’all a lot! I give the experience 5 stars!",
      nerdify: "Thanks, we appreciate it!"
    },
    {
      name: "Jonathan Germany",
      score: "5",
      date: "8 September, 2022",
      title: "Thumbs up to you",
      review: "These Nerds are quick and efficient!!! I love Nerdify!",
      nerdify: "Thanks, Jonathan! Please don't forget to invite friends to Nerdify so you can earn referral bonuses ;)"
    },
    {
      name: "Opinderjit Kaur",
      score: "5",
      date: "7 September, 2022",
      title: "They are doing a very great job 👍👍",
      review: "I would definitely use Nerdify's services again! It's amazing!",
      nerdify: "Thanks, we appreciate it!"
    },
    {
      name: "Amhari Hondagua",
      score: "5",
      date: "6 September, 2022",
      title: "Will use again",
      review: "Great service and fast. Will use in the future!",
      nerdify: "Thanks!"
    },
    {
      name: "Igor Eguren",
      score: "5",
      date: "5 September, 2022",
      title: "Thank you so much Nerdify",
      review: "Replies were immediate, and the work was amazing. Thank you, Nerdify.",
      nerdify: "Thanks, Igor!"
    },
    {
      name: "Karina Bomstein",
      score: "5",
      date: "31 August, 2022",
      title: "Professionals!!",
      review: "Wow, I've received great help from these professionals!\nI received my work on time and got immediate responses. Communication was very easy, plus they were super polite.\nVery cool service, and I wish I've known about them years ago, as they would have saved me so much time.",
      nerdify: "We're so glad you liked your experience!"
    },
    {
      name: "Angela Beckler",
      score: "5",
      date: "26 August, 2022",
      title: "Recommend Nerdify",
      review: "Nerdify has completed 2 tasks for me, and it has always been amazing working with them. They always responded fast. So far, all the work that they’ve done was awesome. I truly recommend Nerdify.",
      nerdify: "Thank you so much, Angela!"
    },
    {
      name: "Minea Royalty",
      score: "5",
      date: "23 August, 2022",
      title: "THANK YOU",
      review: "Thank you for your assistance in helping my family and me during this time of need. Your communication, response, and politeness were appreciated! We will definitely use your services again as well as refer them to friends and family.",
      nerdify: "Thank you so much, we hope you already got a chance to earn referral bonuses you can use for future tasks!"
    },
    {
      name: "Dana Ramsey",
      score: "5",
      date: "17 August, 2022",
      title: "Aamazing help",
      review: "When I thought I wouldn't make it through my final class and start my new career, I had to contact Nerdify. Nerdify was the best thing that happened to me. Tears of joy came running down my face when they promised to find me an expert Nerd. It was amazing! That extra help eliminated my worries. Thank you!!!",
      nerdify: "Thanks, Dana! We hope you got a chance to invite friends to Nerdify and earn referral bonuses you can use for future tasks, we'll be happy to help you again!"
    },
    {
      name: "Shajada Ali",
      score: "5",
      date: "13 August, 2022",
      title: "Budget-friendly",
      review: "I needed some emergency help, and my budget was only $30. Nerdify accommodated me and got my request completed 6 hours earlier than expected.",
      nerdify: "Happy to help! Please invite friends to Nerdify to earn referral bonuses you can use for future tasks ;)"
    },

    {
      name: "Mark Martinez",
      score: "5",
      date: "3 August, 2022",
      title: "They are experts",
      review: "They went above and beyond to provide expert service to me.",
      nerdify: "Thank you so much!"
    },
    {
      name: "Sandra Bethay",
      score: "5",
      date: "2 August, 2022",
      title: "Grateful for the help you provide.",
      review: "I was struggling with Chemistry and have been out of school for a while. I was grateful to have found Nerdify to help me catch up. They were quick to respond, and the pricing was extremely reasonable.",
      nerdify: "Thanks for your feedback, Sandra!"
    },
    {
      name: "Amy Polly",
      score: "5",
      date: "1 August, 2022",
      title: "I recommend them",
      review: "Fast, friendly and efficient replies and service. Task was completed quickly and correctly. Highly recommend.",
      nerdify: "Thanks, Amy!"
    },
    {
      name: "Maria Hull",
      score: "5",
      date: "29 July, 2022",
      title: "Good review",
      review: "My request was well-handled. I really appreciated the help. First time using Nerdify, and I'll definitely be back.",
      nerdify: "We'll be happy to help you further, Marla!"
    },
    {
      name: "Cutie Pie",
      score: "5",
      date: "27 July, 2022",
      title: "Be back soon",
      review: "I had an amazing experience with Nerdify! They are helpful, fast, and diligent with their work. I would recommend it to any high school/college student. I wish to be using them again soon.",
      nerdify: "Thank you so much! Please invite friends to Nerdify as well so you can earn referral bonuses and use them for future tasks!"
    },
    {
      name: "Mumtaz Ali",
      score: "5",
      date: "23 July 2022",
      title: "Great service",
      review: "Always available to answer questions, polite, and excellent problem-solving. Overall great service.",
      nerdify: "Thank you so much!"
    },
    {
      name: "Saraah Buenaventura",
      score: "5",
      date: "21 July, 2022",
      title: "Nerdify was a great experience",
      review: "I had a great experience with Nerdify. They responded so quickly. I placed an urgent request, and they completed that on time. It was perfect and affordable. I would definitely utilize this service again.",
      nerdify: "Thanks for your feedback, we'll be happy to help you further!"
    },
    {
      name: "Jose Fontanez",
      score: "5",
      date: "18 July, 2022",
      title: "Literally Nerdify is a lifesaver!",
      review: "Nerdify is a lifesaver!! When it comes to challenging projects you need help with, Nerdify is always there and attends to you 24/7. I can be up at 4 AM, and Nerdify will be there to assist me with any request. It's the best app where real people provide the best professional service ever. Thank you, Nerdify!",
      nerdify: "Thanks for your kind feedback, Jose!"
    },
    {
      name: "Maine Lee",
      score: "5",
      date: "15 July, 2022",
      title: "Cool 👍🏻",
      review: "Nerdify is cool. At first, I didn’t know this was really a thing and thought it was a scam, but no. They're here to help everyone that goes through things and needs help catching up. Thank y’all 🙏🏽",
      nerdify: "Thank you for trusting us!"
    },
    {
      name: "Ngounou Ghislain",
      score: "5",
      date: "14 July, 2022",
      title: "Quality service",
      review: "Fast response, the request was completed on time, and quality service was provided.",
      nerdify: "Thanks, we'll be happy to help you further!"
    },
    {
      name: "Kenneth Fields",
      score: "5",
      date: "9 July, 2022",
      title: "They are Experts",
      review: "Service was amazing! The communication was nice and quick. The most important thing is that they gave expert advice!",
      nerdify: "Thanks for sharing your feedback, Kenneth! Hope you got a chance to introduce Nerdify to friends and earn referral bonuses ;)"
    },
    {
      name: "Renee Summers",
      score: "5",
      date: "7 July, 2022",
      title: "Exceptional",
      review: "Exceptional customer service. Very prompt responses.",
      nerdify: "Thank you!"
    },
    {
      name: "Samantha Smith",
      score: "5",
      date: "6 July, 2022",
      title: "Awesome",
      review: "These guys are awesome! Quick response, quick turnaround times, perfect communication. Just all-around awesome. There isn't anything negative to say about Nerdify, and you will not regret making your decision to use them. I wish I'd known about them sooner than I did.",
      nerdify: "Thanks, Samantha! We do hope you got a chance to invite friends to Nerdify and earn your bonuses for future tasks!"
    },
    {
      name: "Alex Guardsmen",
      score: "5",
      date: "4 July, 2022",
      title: "Pretty good",
      review: "The response time was great, and the pricing was fair and worth it. I'm looking forward to contacting them again!",
      nerdify: "We are looking forward to it too!"
    },
    {
      name: "Alejandro Arreola",
      score: "5",
      date: "2 July, 2022",
      title: "Impressed. Hire those Nerds! Not someone else!",
      review: "I had a terrible experience when I hired someone I know to help me with a project, so I searched for a reliable service provider online and found Nerdify. The Nerds did an amazing job on all my four requests. Everything was perfect, and they absolutely knew what to do. I strongly recommend Nerdify!",
      nerdify: "Thank you, Alejandro! Please invite friends to Nerdify using your referral link to earn bonuses."
    },


    {
      name: "Ashley Marie",
      score: "5",
      date: "30 June, 2022",
      title: "Super easy to get help from them",
      review: "Fast response time and easy process to get your request done. When I first texted, a real person replied right away and hooked me up with an expert who helped me with my task. All you do is send a request, give clear directions, pay, and within a short time frame your Nerd will get it done. Great for college students with hectic schedules. I definitely recommend this service!",
      nerdify: "Thanks, Ashley!"
    },
    {
      name: "Sarah Lawrence",
      score: "5",
      date: "25 June, 2022",
      title: "Great helper, perfect time, price, effective services.",
      review: "I tried using Nerdify and liked their service. They were very prompt and delivered on time as promised. I would be using them again for sure. I'd definitely recommend them to any student who needs help with anything. Thank you!",
      nerdify: "Thanks, Sarah! Please introduce Nerdify to friends to earn bonuses you can use for future tasks <3"
    },
    {
      name: "Sharee Williams",
      score: "5",
      date: "23 June, 2022",
      title: "Quick!",
      review: "Great and quick service. They knew exactly how to help with my task.",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Brett Burns",
      score: "5",
      date: "20 June, 2022",
      title: "Great service",
      review: "I was stressing out so much this morning, thinking there was no way I could finish my work on time, but y’all helped me out! I reached out and got a reply right away every time. My task was completed on time.",
      nerdify: "Thank you so much, Brett!"
    },
    {
      name: "Kirian Ruiz",
      score: "5",
      date: "15 June, 2022",
      title: "Avid User",
      review: "The service has been amazing! Communicating with the Nerdify team is as easy as sending a text. I will definitely continue to use their service.",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Anastasiya Skydanyuk",
      score: "5",
      date: "10 June, 2022",
      title: "Will recommend",
      review: "Prompt responses and extremely helpful. The task was completed on time. Definitely would recommend.",
      nerdify: "Thanks, Anastasiya! Hope you got a chance to introduce Nerdify to friends and earn referral bonuses."
    },
    {
      name: "Krish Sharma",
      score: "4",
      date: "4 June, 2022",
      title: "Most convenient service ever!",
      review: "It was literally the fastest and most convenient service I have gotten done in my entire life.",
      nerdify: "Thanks, please tell us how we can earn 5 stars from you ;)"
    },
    {
      name: "Anthony Romana",
      score: "5",
      date: "1 June, 2022",
      title: "PERFECT!",
      review: "I now know after multiple tasks completed by Nerdify, I would recommend their services to everyone!\n\n",
      nerdify: "Thank you so much, Anthony!"
    },
    {
      name: "Razay Dillard",
      score: "5",
      date: "30 May, 2022",
      title: "Highly recommend",
      review: "Great experience with Nerdify. I really appreciate the help. I highly recommend your services 🙏🏽",
      nerdify: "Thank you so much! Please invite friends to Nerdify to get referral bonuses ;)"
    },
    {
      name: "Julianna Konner",
      score: "1",
      date: "15 June, 2022",
      title: "Don’t bother to hire this",
      review: "Don’t bother to hire this. They will confuse your task with someone else's.",
      nerdify: "Hi there, thank you so much for bringing this situation to our attention. This is unacceptable in terms of the level of service we aim to provide! Please add a task number to this review and I assure you that we will investigate your case and compensate you accordingly!"
    },
    {
      name: "A'Layah Richmond",
      score: "5",
      date: "25 May, 2022",
      title: "Handy, big help to me",
      review: "I love Nerdify! These people have been a huge help to me, as I struggle with the English language badly. They come in handy every single time!",
      nerdify: "Thank you, please invite friends to Nerdify to get referral bonuses ;)"
    },
    {
      name: "Francisco Torres",
      score: "5",
      date: "23 May, 2022",
      title: "Love the Nerds",
      review: "I went with these guys, and what they delivered looked pretty good. I will continue to use these Nerds, as they really helped out last minute.",
      nerdify: "Thanks for trusting us, Francisco!"
    },
    {
      name: "Joshua Jennings",
      score: "5",
      date: "21 May, 2022",
      title: "Super Helpful",
      review: "The service was super helpful! They didn't just help me complete a presentation but also explained the process in detail so that I know exactly what they did. Satisfied!",
      nerdify: "Thank you, Joshua! Please invite friends to Nerdify to get referral bonuses ;)"
    },
    {
      name: "Tamara Allivar",
      score: "5",
      date: "19 May, 2022",
      title: "I'm a new fan of Nerdify",
      review: "Very satisfied! They were fast, easy to communicate with, and did an awesome work. They made my life so much easier as I'm juggling work, my personal life and my classes. I will be a returning customer for sure. You need Nerdify to help you out!",
      nerdify: "We're happy you are satisfied with our service, Tamara!"
    },
    {
      name: "Vaishnavi Lall",
      score: "5",
      date: "18 May, 2022",
      title: "100% recommend them",
      review: "I reached out to Nerdify, and they got back to me in no time! I am so happy with the service that they provided me with. 100% recommended!",
      nerdify: "Thanks! If you have friends who might need our help please introduce them to Nerdify. We'll give you referral bonuses ;)"
    },
    {
      name: "Elli Ebue",
      score: "5",
      date: "14 May, 2022",
      title: "Hassle-free",
      review: "Easy and quick ! No hassle. Would use again.",
      nerdify: "Thank you, Elli!"
    },


    {
      name: "Kierra Brown",
      score: "5",
      date: "11 May, 2022",
      title: "Thank you so much!",
      review: "I was eager to find someone to help me, and I was glad I found Nerdify. The Nerds were so helpful and responsive.",
      nerdify: "Thanks for your review, Kierra!"
    },
    {
      name: "Stephanie Cain",
      score: "5",
      date: "10 May, 2022",
      title: "Will use again",
      review: "This website is excellent. I was satisfied with how you handled my request. You will see me again. Thank you so much, Nerdify.",
      nerdify: "Thank you, Stephanie!"
    },
    {
      name: "Zac Tague",
      score: "5",
      date: "9 May, 2022",
      title: "Excellent service!",
      review: "Excellent service every time! Fast responses, quick turnaround, and great workmanship on everything!",
      nerdify: "Thank you!"
    },
    {
      name: "Lanae Robinson",
      score: "5",
      date: "7 May, 2022",
      title: "10/10 Recommend",
      review: "Great help! It's finals week, and I was pressed for time, so I knew I needed to resort to an expert. They went above and beyond to provide me with what I needed. I was quite impressed by the service I've received. Thank you, Nerdify.",
      nerdify: "Thank you so much, please invite friends to earn referral bonuses you can spend at Nerdify!"
    },
    {
      name: "Russell Stewart",
      score: "5",
      date: "5 May, 2022",
      title: "A LIFESAVER",
      review: "Honestly, it's super helpful! I broke my leg in two places and had a major surgery the weekend before classes started, so I fell behind. Nerdify was able to help me catch up and understand the course. 8/10 highly recommend it if you're overwhelmed or in a jam.",
      nerdify: "Thank you, Russell!"
    },
    {
      name: "Lovely Rivers",
      score: "5",
      date: "4 May, 2022",
      title: "I'm a fan",
      review: "Nerdify is excellent! Quick response time. Fast transaction. Easy process. Good quality work.",
      nerdify: "Thank you!"
    },
    {
      name: "Jeniece Collazo",
      score: "5",
      date: "4 May, 2022",
      title: "Nerdify is the best.",
      review: "The tutors at Nerdify are wonderful, and their services are of high quality.",
      nerdify: "Thank you! Maybe you have any friends who might wanna use Nerdify? We'll give you bonuses for referring them ;)"
    },
    {
      name: "Luxe Events",
      score: "5",
      date: "4 May, 2022",
      title: "Nerdify came to my rescue!",
      review: "Nerdify came to my rescue during my last semester before graduation! The quality of service was amazing, as was the time it took to complete my request.",
      nerdify: "Thanks!"
    },
    {
      name: "Saucy Fellow",
      score: "5",
      date: "4 May, 2022",
      title: "Practical choice",
      review: "My experience was great! They managed to respond immediately and do the work efficiently.",
      nerdify: "Thanks!"
    },
    {
      name: "Ginea Vega",
      score: "4",
      date: "4 May, 2022",
      title: "Good service",
      review: "Fair pricing, high-quality work, and good customer service.",
      nerdify: "Thank you!"
    },
    {
      name: "Elizabeth Browning Liz",
      score: "5",
      date: "4 May, 2022",
      title: "Good Work",
      review: "Everything was good. They responded quickly and patiently and were always there for any questions I had.",
      nerdify: "Thank you, Elizabeth!"
    },
    {
      name: "Sonya Norwood",
      score: "5",
      date: "4 May, 2022",
      title: "Saved me!",
      review: "Great website for when emergencies arise! Good job, Nerdify!",
      nerdify: "Thank you, Sonya!"
    },
    {
      name: "Eunice Guerrero",
      score: "5",
      date: "4 May, 2022",
      title: "What a relief!",
      review: "I recently got overwhelmed with tasks, so I jumped to Nerdify. Thankful that they were able to help me and relieve my stress.",
      nerdify: "Thanks! If you have any friends who might wanna use Nerdify, invite them and earn referral bonuses;)"
    },
    {
      name: "Shaunty Rille",
      score: "5",
      date: "4 May, 2022",
      title: "My go-to website",
      review: "Nerdify has helped me this semester, and their service was high-quality and satisfying. The Nerds were fast, helpful, and communicative!",
      nerdify: "Thank you, please invite friends to earn bonuses that you can spend for future tasks ;)"
    },
    {
      name: "Jordan Burnley",
      score: "4",
      date: "4 May, 2022",
      title: "Regular Customer",
      review: "The service I receive is typically high quality. I've only had one or two instances where improvements were needed, but their customer service handled my issues swiftly. The price can be a little steep, but it's worth it.",
      nerdify: "Thanks, Jordan!"
    },
    {
      name: "Tiesha Thomas",
      score: "5",
      date: "1 May, 2022",
      title: "Thank you!",
      review: "I had a pleasant experience. Customer service was communicative and very professional. The Nerds were very knowledgeable and took the job seriously. I highly recommend Nerdify!",
      nerdify: "Thanks, Tiesha! Hope you got a chance to earn referral bonuses for recommending us to friends ;)"
    },
    {
      name: "Javier Marnis",
      score: "5",
      date: "28 April, 2022",
      title: "I liked the service",
      review: "Overall the best experience ever. All of my questions and inquiries were answered fast. My Nerd did a great job on my request even though I placed it last minute and with a short time frame. 10/10 will recommend this to anyone.",
      nerdify: "Thanks, Javier! Please invite friends to earn referral bonuses you can use for future tasks!"
    },
    {
      name: "Z Shell-Thoms",
      score: "5",
      date: "28 April, 2022",
      title: "Great work! Not a scam!",
      review: "Not a scam. Work completed as promised! Fast response times with quality service provided.",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Janine Niles",
      score: "5",
      date: "27 April, 2022",
      title: "Great Experience Using Nerdify",
      review: "My overall experience with Nerdify was great! Communication is 100%. It doesn’t matter what time you contact them, for they will immediately respond. I highly recommend using Nerdify!",
      nerdify: "Thanks!"
    },
    {
      name: "Ebony Hudson",
      score: "5",
      date: "25 April, 2022",
      title: "Good service",
      review: "Great communication. Timely service. Reasonable prices.",
      nerdify: "Thanks, Ebony!"
    },
    {
      name: "Jon Rosco",
      score: "5",
      date: "23 April, 2022",
      title: "Awesome 👌",
      review: "Respect to the Nerds working on our requests. I love that they get the job done on time, and they are very professional.",
      nerdify: "Thank you, please don't forget to invite friends to Nerdify to earn referral bonuses!"
    },
    {
      name: "Zena Omari",
      score: "5",
      date: "21 April, 2022",
      title: "So helpful to me",
      review: "Nerdify was very helpful. I almost dropped my class because I was having a hard time, but thankfully a Nerd helped me out. Top-notch service!\n",
      nerdify: "Thanks!"
    },
    {
      name: "Marisol Armas",
      score: "1",
      date: "20 April, 2022",
      title: "I used to be a fan of the service",
      review: "They didn’t complete my task on time and did not give me my money back! I was very loyal but never again will use Nerdify!",
      nerdify: "Dear Marisol, from what I see in our client management system, the issue has been already solved and the compensation has been agreed with you. We really hope this is the last time you are not satisfied with your experience at Nerdify!"
    },
    {
      name: "Justine Hamilton",
      score: "5",
      date: "19 April, 2022",
      title: "Book them",
      review: "Excellent service! I was helped immediately with no hold-ups or hassles. Great business and fast service! It's a great decision to book with Nerdify. Try it, and you will not regret it.",
      nerdify: "Thanks, Justine!"
    },
    {
      name: "John Fuentes",
      score: "5",
      date: "18 April, 2022",
      title: "Thumbs up!",
      review: "The Nerd I worked with was very helpful and efficient. Thumbs up!",
      nerdify: "Happy we could help, John!"
    },
    {
      name: "Keyonna Albira",
      score: "5",
      date: "17 April, 2022",
      title: "5 STARS",
      review: "I'm giving them a 5-star review because they effectively helped me with my request. I don't know what I would have done without them. Thank you so much, Nerdify!",
      nerdify: "Thank you, Keyonna!"
    },
    {
      name: "Pamela Lopez",
      score: "5",
      date: "14 April, 2022",
      title: "Highly recommend!",
      review: "Absolutely love using their service! Everything is so quick and easy. Their customer service is helpful, and they're always quick to reply if you have any requests. Tasks are always completed on time or earlier and always of great quality. Would highly recommend them!",
      nerdify: "Thanks, Pamela! Please don't forget that we have a very generous referral program ;)"
    },
    {
      name: "Sophia Mcnare",
      score: "5",
      date: "13 April, 2022",
      title: "Worked fast",
      review: "Great work, Nerdify! Thank you!",
      nerdify: "Thank you, Sophia!"
    },
    {
      name: "Roy Abadi",
      score: "5",
      date: "13 April, 2022",
      title: "Not a scam!",
      review: "Great work. Fast and easy transaction. Definitely not a scam. 10/10 service.",
      nerdify: "Thanks, Roy! Please invite friends to use Nerdify and earn referral bonuses!"
    },

    {
      name: "Rachel Florentino",
      score: "5",
      date: "12 April, 2022",
      title: "Had an amazing experience!",
      review: "Amazing experience! They promptly respond whenever you need them. You can check in at any time and see the progress. I think I bothered them a million times, and still, they were always super polite and accommodating. Very efficient Nerds!",
      nerdify: "Thank you, Rachel! We hope to see you back soon <3"
    },
    {
      name: "Ayoub Bouhaddou",
      score: "5",
      date: "10 April, 2022",
      title: "Great service by Nerdify",
      review: "Great service! The Nerds are very polite and provided me with so much help. I definitely recommend Nerdify!",
      nerdify: "Thanks!"
    },
    {
      name: "Adasia Cash",
      score: "5",
      date: "8 April, 2022",
      title: "My Nerd was the best",
      review: "Everything was easy and fast. I got my task on time and as promised. The Nerd I was working with was really great at explaining the process and kept me updated the whole time!",
      nerdify: "Thanks for your feedback, please invite friends who might need our help to earn referral bonuses ;)"
    },
    {
      name: "Jose Saigado",
      score: "5",
      date: "6 April, 2022",
      title: "Super cool service",
      review: "Super cool people! They're super easy to work with. I would highly recommend Nerdify!",
      nerdify: "Thanks, Jose!"
    },
    {
      name: "Chrissy Blumen",
      score: "5",
      date: "3 April, 2022",
      title: "Perfect!",
      review: "My request came out perfect. I would recommend Nerdify to help give you a push if you are struggling with projects.",
      nerdify: "We're glad to hear from you, thanks!"
    },
    {
      name: "Tricia Sanders",
      score: "5",
      date: "1 April, 2022",
      title: "AWESOME",
      review: "Nerdify exceeded my expectations, and the service from both customer services and the Nerds were exceptional. I would recommend this service to anyone who needs help and guidance with any subject they are struggling with.",
      nerdify: "Thank you so much!"
    },
    {
      name: " Noli Worriane",
      score: "5",
      date: "31 March, 2022",
      title: "Literally the best",
      review: "The work was fast and well done.\nLiterally, the best company of its kind created.\nCommunication was open and prompt.\nEverything's done on time.",
      nerdify: "We're glad we could help!"
    },
    {
      name: "Reny Fdez",
      score: "5",
      date: "26 March, 2022",
      title: "A great help!",
      review: "I really have to say that I am very pleased with the quality of each job they offer. Excellent customer service and always responds on time to each of your concerns. I am very glad I found this platform.",
      nerdify: "Thanks, Reny!"
    },
    {
      name: "Nada Frances",
      score: "5",
      date: "25 March, 2022",
      title: "Saved me from stress",
      review: "I highly recommend Nerdify. I needed help with something last minute, and they completed within 3 hours. They responded almost immediately and on time, and their prices were affordable.",
      nerdify: "Thanks! Please invite friends to Nerdify and earn referral bonuses. We'll be happy to help you and your mates further!"
    },
    {
      name: "Melba Foster",
      score: "5",
      date: "23 March, 2022",
      title: "Nerdify is a game-changer!",
      review: "I needed help with a project, and time was of the essence, so I was desperate. Nerdify exemplified extreme professionalism and brilliance. It was a game-changer for me. I was ready to throw in the towel, but my “Nerd” showed me they truly cared about my request through the various questions they asked along the way. They wanted clarification so they could provide me with exactly what I needed. I can’t say enough about this company. They are the real deal in every sense. Thank you, Nerdify, for helping me reach my goal!",
      nerdify: "We are so happy we could help! <3"
    },
    {
      name: "May Davis",
      score: "5",
      date: "22 March, 2022",
      title: "High-quality services",
      review: "My request was completed in 7 hours, which was significantly faster than I had anticipated. It was absolutely high-quality service! Thanks!",
      nerdify: "Thanks, May!"
    },
    {
      name: "Imani Ali",
      score: "5",
      date: "20 March, 2022",
      title: "Better than expected",
      review: "Nerdify did better than I expected. This was my first time seeking outside help with something like this, and the Nerds were phenomenal. Honestly, the experience was fantastic! They were able to complete the request before it's due and still did everything I asked. Thank you, Nerds! 😁",
      nerdify: "Happy to help, Imani! Please invite friends to get referral bonuses ;)"
    },
    {
      name: "Brianne Alquerez",
      score: "5",
      date: "18 March, 2022",
      title: "Reliable services",
      review: "My experience with Nerdify has been great from the jump. No matter the task, help is always quick, and the Nerds are ready to assist in any way needed. It really is a stress reliever to know that I can count on the help of Nerdify and its services. Thank you so much!",
      nerdify: "Thanks! Please don't forget to invite friends ;)"
    },
    {
      name: "Angel Maxwell",
      score: "5",
      date: "18 March, 2022",
      title: "Will use again",
      review: "It was my first time using this service, and it was amazing. Their customer service was excellent and very thorough. The Nerds explained the process and always gave me feedback. My son needed help with Math, and they gave him some tutoring and tips. I had no problem with the process since it's super easy and was a lifesaver. The service overall was great. I will use them again.",
      nerdify: "We'll be happy to help <3"
    },

    {
      name: "Angel Maxwell",
      score: "5",
      date: "17 March, 2022",
      title: "A+ service from Nerdify",
      review: "It was my first time using Nerdify, and it's an A+ experience. Their customer service was excellent and very thorough. They explained the process and always gave me feedback. My son needed help with a math task that they explained very well, as they helped him understand the problems and the answers. I had no problem with the process, as it was super easy and was a lifesaver. The service overall was great. I will use them again.",
      nerdify: "We'll be happy to help <3"
    },
    {
      name: "Egypt Smith",
      score: "5",
      date: "15 March, 2022",
      title: "Great communication!",
      review: "They are great and communicative, and they work with you to complete the task efficiently and perfectly.",
      nerdify: "Thank you!"
    },
    {
      name: "Briana Supga",
      score: "5",
      date: "12 March, 2022",
      title: "Great quality at cheap costs",
      review: "I really like how fast, ready, and polite the Nerds are. I would recommend Nerdify if you need help. It’s cheap and of great quality!",
      nerdify: "Thanks, Briana!"
    },
    {
      name: "Abril Jimenez",
      score: "4",
      date: "10 March, 2022",
      title: "Fantastic Work",
      review: "Their overall work is very good, their responses to questions are almost immediate no matter the time, and they have good customer service. The only thing I feel they need to improve is by carefully reading the requirements of the tasks to avoid misunderstanding, which has happened to me several times. But that aside, their system is very well organized, and the Nerds do an excellent job.",
      nerdify: "Thanks for your honest feedback, Abril! We are constantly working on improving our service and will do everything it takes to provide you with no less than 5-star experience further!"
    },
    {
      name: "Ali Akbari",
      score: "5",
      date: "7 March, 2022",
      title: "Tried and tested service",
      review: "I've been using Nerdify a few times, and I have no complaints so far! They answer pretty quickly, and the price is reasonable. For your academic needs, you can rely on Nerdify. I'm definitely going to use their help again in the future.",
      nerdify: "Thank you! Please invite your friends to get the referral bonuses."
    },
    {
      name: "Edward Castory",
      score: "5",
      date: "4 March, 2022",
      title: "Great work",
      review: "Great service! I appreciate the Nerds that work very hard on the requests that desperately need to be completed in a short timeframe.",
      nerdify: "Thank you, Edward!"
    },
    {
      name: "Luke Burgundy",
      score: "5",
      date: "1 March, 2022",
      title: "I recommend them",
      review: "All good! Good service. I've received a big help from the people at Nerdify. Use them, and you will never regret it.",
      nerdify: "Thanks for sharing your feedback!"
    },
    {
      name: "Cindi Flor",
      score: "5",
      date: "28 February, 2022",
      title: "Satisfied customer",
      review: "I am proud of the service I received. The Nerds did a fantastic job making sure that everything was done according to the instructions. I thank them for their hard work! I will be using Nerdify again.",
      nerdify: "Thanks, we appreciate it so much <3"
    },
    {
      name: "Joyce Fitisemanu",
      score: "5",
      date: "27 February, 2022",
      title: "Beyond Awesome",
      review: "You folks at Nerdify are beyond awesome. Based on my experience, you responded quickly to any question I asked, and you were always professional. The service exceeded my expectations a great deal. Thank you again.",
      nerdify: "Thank you, Joyce!"
    },
    {
      name: "Samantha Brown",
      score: "5",
      date: "22 February, 2022",
      title: "Super nice Nerds",
      review: "Fast and efficient service! Very responsive, super honest and nice Nerds help you out!",
      nerdify: "Thanks, Samantha! Please don't forget that we give referral bonuses for every friend you invite!"
    },
    {
      name: "Sarah Frisky",
      score: "5",
      date: "20 February, 2022",
      title: "A true godsend!",
      review: "Nerdify has been nothing short of a complete godsend and lifesaver for me. The agents who assisted with setting me up with the right Nerd to help complete my tasks were quick to respond within minutes or less. They were always kind and effective with communication, answered all my questions patiently, and the work was always done on time or sooner. I can't recommend their services enough.",
      nerdify: "Thanks for such amazing feedback, Sarah!"
    },

    {
      name: "Kefi Business",
      score: "5",
      date: "14 February, 2022",
      title: "Satisfied",
      review: "Fast service with a fair price. The Nerd that helped me gave me tips that made me understand the task even more. He explained it very well, making it pretty clear to figure out. Satisfied!",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Oscar Mochizaki",
      score: "5",
      date: "13 February, 2022",
      title: "Personalized service!",
      review: "They do a great job of providing services according to what one needs.",
      nerdify: "Thanks, Oscar!"
    },
    {
      name: "Anh Nguyen",
      score: "5",
      date: "11 February, 2022",
      title: "10/10",
      review: "The Nerds did a very good job! They are friendly, quick to respond, and helpful in providing necessary information. I would rate it 10/10!",
      nerdify: "Thank you so much, we'll be happy to see you back soon!"
    },
    {
      name: "Christy Castemero",
      score: "5",
      date: "10 February, 2022",
      title: "Always fast and efficient!",
      review: "Always fast and efficient!! Great service as usual.",
      nerdify: "Thank you! Please refer your friends to get bonuses!"
    },
    {
      name: "Mikee Starr",
      score: "5",
      date: "7 February, 2022",
      title: "Suits my needs",
      review: "Quick response and amazing attention to my needs. 10/10 will definitely use again if needed.",
      nerdify: "Thank you!"
    },
    {
      name: "Rene Salamanca",
      score: "5",
      date: "2 February, 2022",
      title: "Recommended",
      review: "You guys were great! Very professional. Replied quickly. Proactively asked questions about the task. I will definitely be referring you guys to my friends!",
      nerdify: "Feel free to refer friends, Rene! We have great referral bonuses!"
    },
    {
      name: "Misti Bardeloza",
      score: "5",
      date: "31 January, 2022",
      title: "I’d use again!",
      review: "I’ve had anxiety about a task I was working on, so I looked to Nerdify for some advice. They were great to work with, and my request was completed earlier than expected!",
      nerdify: "Thanks, Misti!"
    },
    {
      name: "Syeda Flor",
      score: "5",
      date: "27 January, 2022",
      title: "Amazing",
      review: "My request was completed on time, and the Nerd was very helpful. I appreciate their effort!",
      nerdify: "Thank you so much! Hope you will soon get referral bonuses to spend at Nerdify!"
    },
    {
      name: "LaTeisha Davis",
      score: "4",
      date: "26 January, 2022",
      title: "Pleasant experience",
      review: "The experience was pleasant. Just a little disappointed with the price of the service.",
      nerdify: "Thanks for rating us! Please refer your friends to get bonuses, this way you will save a lot of money on your future tasks ;)"
    },
    {
      name: "Vianky Lev",
      score: "5",
      date: "24 January, 2022",
      title: "Excellent help",
      review: "I had a great experience with Nerdify. I needed advice on something I was working on, and Nerdify was able to provide excellent help!",
      nerdify: "Thank you!"
    },
    {
      name: "Dineen Banks",
      score: "5",
      date: "19 January, 2022",
      title: "I'm a Nerdify fan!",
      review: "Everything was great! This is my second time with Nerdify. I'll be back again and again. Thank you for helping me, I really appreciate it! 😊",
      nerdify: "We really appreciate your feedback, Dineen!"
    },
    {
      name: "Chasity Reynolds",
      score: "5",
      date: "19 January, 2022",
      title: "Nerdify is the BEST!",
      review: "Nerdify was a great experience overall! They took the time to help me understand everything I needed to know and offered useful tips. Not to mention, they're very communicative! I will definitely keep using them!",
      nerdify: "Thanks, Chasity! Feel free to invite friends to get your referral bonuses."
    },
    {
      name: "Amel Hassan",
      score: "5",
      date: "13 January, 2022",
      title: "Delivered what I need!",
      review: "I really appreciate the hard work you guys do. You guys delivered. Thank you very much!",
      nerdify: "Thank you so much!"
    },

    {
      name: "Lily P.",
      score: "5",
      date: "9 January, 2022",
      title: "James is amazing!",
      review: "No doubt, Nerdify is the best company ever. I’m completely satisfied each time I use them. I work with James when I need something done. James is professional, kind, and quick to help with whatever tasks.❤️🙏🏽 There are not enough stars for James. He’s just amazing. ⭐️⭐️ ⭐️ ⭐️ ⭐️",
      nerdify: "Thanks, Lilly! We'll tell James you said hi :)"
    },
    {
      name: "Xiaochun Zhang",
      score: "5",
      date: "4 January, 2022",
      title: "Will surely recommend",
      review: "I would surely recommend Nerdify. High-quality and prompt work. Good communication.",
      nerdify: "Thank you so much!"
    },
    {
      name: "Rose Sands",
      score: "5",
      date: "17 December, 2021",
      title: "Excellent",
      review: "The execution of the work was excellent. I had a little hiccup with how things turned out, and they turned around and fixed it right away! It was excellent. I will recommend and use it again.",
      nerdify: "Thank you so much, Rose!"
    },
    {
      name: "Maria Cardona",
      score: "5",
      date: "16 December, 2021",
      title: "Saved me many times!",
      review: "Nerdify has saved me so many times! I truly recommend it to anyone who needs it. The Nerds have completed my same-day requests before the deadline. Please try Nerdify. You won’t regret it!",
      nerdify: "Thanks, Maria! Please don't forget that you can refer friends and get bonuses!"
    },
    {
      name: "Tyler Bell",
      score: "1",
      date: "10 December, 2021",
      title: "Unreliable",
      review: "You can’t count on work to be completed on time. Although the people who texted me were friendly and the prices weren’t too bad they could not complete my work.",
      nerdify: "Hi there, Tyler! Let me on behalf of the whole Nerdify team apologize for the negative experience you had. We are doing everything we can to make sure our clients get the service on time and of high quality, but as much as we try to prevent any possible issues, in rare cases such delays do happen. We hope you give us a second chance in the future!"
    },
    {
      name: "Roger Eason",
      score: "5",
      date: "13 December, 2021",
      title: "Satisfying results",
      review: "My first experience with Nerdify started a little rocky but was quickly cleared up and corrected. I received awesome help on ten different projects, and the results were satisfying! I will be using them again.",
      nerdify: "We'll be happy to see you back soon, Roger!"
    },
    {
      name: "Joefree Smith",
      score: "5",
      date: "10 December, 2021",
      title: "An A+ for Nerdify",
      review: "My initial experience back in October wasn't the best, but right now I'm glad that after speaking with you guys and trusting the process, my requests have been completed in a timely and efficient manner. Response to inquiries is instantaneous and pleasant. Overall A+ for Nerdify!",
      nerdify: "Thank you, Joefree!"
    },
    {
      name: "Gloria Filomin",
      score: "5",
      date: "7 December, 2021",
      title: "Great job",
      review: "I can assure you that they do a great job! I recommend them to you guys. 😉 They completed my request promptly. Thank you, Nerdify! 😊",
      nerdify: "Thanks, Gloria!"
    },
    {
      name: "Texas Grishay",
      score: "5",
      date: "6 December, 2021",
      title: "Magnificent",
      review: "They were professional and on time! So far, each experience has been magnificent. I recommend Nerdify to anyone.",
      nerdify: "Thanks!"
    },
    {
      name: "Nancy Milton",
      score: "5",
      date: "6 December, 2021",
      title: "Nerdify is great with delivering quality work",
      review: "Nerdify is great at helping you complete perfect projects. They have helped me in many situations. Their customer service is amazing, prompt, and helpful.",
      nerdify: "What an amazing review, Nancy! Thank you!"
    },
    {
      name: "Alexabdra Ryder",
      score: "5",
      date: "4 December, 2021",
      title: "Phenomenal job",
      review: "Nerdify did a phenomenal job making my project look much more professional than what I had attempted. 10/10 would recommend!",
      nerdify: "Thanks, Alexandria!"
    },
    {
      name: "Diego Martinez",
      score: "5",
      date: "3 December, 2021",
      title: "THE REAL DEAL!!!! Story time",
      review: "At first, I was scared of texting their number taken off Google but later found out that Nerdify is the GOAT. They deliver quality output that gets positive outcomes! They have a cool friend referral system too. I racked up $90 in credit for sending my friends to them. You won't regret it!",
      nerdify: "We are happy to help, Diego! Thanks for your feedback and thanks for recommending us!"
    },
    {
      name: "Miguel Romero",
      score: "4",
      date: "3 December, 2021",
      title: "Good service but one thing to note",
      review: "My experience with Nerdify has been good. Their customer service is responsive and solution-oriented. I’ve had four tasks completed by them. The first two tasks I assigned them were executed and delivered well. The last two tasks had slight issues - these tasks needed to be completed in 12 hours, and after adjustments were received in 24 hours, and missed the deadline. So my takeaway: their service is good. Just be aware that deadlines may be missed for those tasks with quick turnaround requests. So, make sure to give them and yourself time. BUT overall solid services!",
      nerdify: "Thanks for your review, Miguel! This is a great tip! We are doing our best to make sure every client gets their request completed on time, but in rare cases, mostly, if the Nerd had some tech issues or personal matter and the deadline is tight, there might be a delay. This is the reason why we always encourage our clients to request help beforehand."
    },
    {
      name: "Shahd Alatwi",
      score: "5",
      date: "2 December, 2021",
      title: "Best app ever",
      review: "Great experience with Nerdify! It's the best app ever. The Nerds respond promptly and are good at what they do. I'm happy that I found them.",
      nerdify: "We are so happy to get such reviews! Please don't forget to refer your friends to get bonuses ;)"
    },
    {
      name: "Sarai Nicole",
      score: "5",
      date: "2 December, 2021",
      title: "My go-to website",
      review: "Nerdify is my go-to website for assistance! The prices are super reasonable, I get responses right away when I have questions, and I receive perfect outcomes!",
      nerdify: "Thanks, Nicole! Please don't forget to refer your friends to get bonuses ;)"
    },
    {
      name: "Savy Knights",
      score: "5",
      date: "30 November, 2021",
      title: "Great services!!",
      review: "I have been using Nerdify for a while now, and I have referred a number of friends. Great services at great prices. Quick responses to requests and on-time delivery. Definitely recommend. You won’t be disappointed!",
      nerdify: "Thanks for recommending us! We really appreciate your trust <3"
    },
    {
      name: "Sonbreon Clark",
      score: "5",
      date: "29 November, 2021",
      title: "Reasonable price for services",
      review: "Nerdify has effective and reasonably priced services. The Nerds are very polite, nice, and understanding. They complete the tasks in no time.",
      nerdify: "Thanks for such kind review! Please don't forget that we have a very generous referral program ;)"
    },

    {
      name: "Ethan Dacko",
      score: "5",
      date: "24 November, 2021",
      title: "Outstanding service",
      review: "Fast responses. Received what I needed, exactly when they said they would get it done. They were also very flexible on the price. I will definitely use them again.",
      nerdify: "Thanks, Ethan! Please refer your friends to get bonuses ;)"
    },
    {
      name: "Miguel Lacerna",
      score: "5",
      date: "22 November, 2021",
      title: "Very good",
      review: "Very good, on-time, and very responsive!",
      nerdify: "Thanks, Miguel!"
    },
    {
      name: "Coleen Imkamp",
      score: "5",
      date: "19 November, 2021",
      title: "Best stress-reliever ever",
      review: "I had a very urgent task and the window to get it done was very short. I'm also working full-time with young children, so I became very overwhelmed. Nerdify popped up on the search engine, and I reached out. They were very quick on their responsiveness, in addition to being very professional. Anytime I reached out via text, they responded promptly. The work I received was amazing. I will reach out again, in addition to referring or recommending them to anyone in need.",
      nerdify: "Thank you so much for sharing your experience, Colleen!"
    },
    {
      name: "Caroline Williams",
      score: "5",
      date: "17 November, 2021",
      title: "Amazing",
      review: "They did an amazing job! Thank you, Nerdify!",
      nerdify: "Thanks for acknowledging our efforts, Caroline!"
    },
    {
      name: "Evan Murphy",
      score: "1",
      date: "16 November, 2021",
      title: "Will not use",
      review: "They told me they needed 5 more hours and it ended up being 10 hours! Don't ever use this company!!!",
      nerdify: "Dear Evan, we have already issued a refund for this task. We are extremely sorry for failing you down."
    },
    {
      name: "Cynthia Hernandez",
      score: "5",
      date: "15 November, 2021",
      title: "Thank you, Nerds!",
      review: "Great work and received in due time! Thank you, Nerds, for your help.",
      nerdify: "Thank you so much, Cynthia!"
    },
    {
      name: "Amaryllis Garza",
      score: "5",
      date: "14 November, 2021",
      title: "Loved working with my Nerd",
      review: "My Nerd did a great job on the task I needed help with. I'm very grateful and glad that I chose Nerdify. They were very helpful and turned in the work on time.",
      nerdify: "Thanks for such amazing feedback!"
    },
    {
      name: "Christie Almesor",
      score: "5",
      date: "11 November, 2021",
      title: "Life Savers at Work",
      review: "I needed two requests done by the next evening, and they were completed quickly without sacrificing quality. Everything was hassle-free, simple, and very timely. It was an enjoyable experience, and I will definitely use this service again.",
      nerdify: "Thanks, Christie! We'll be happy to help you further."
    },
    {
      name: "Valden Armary",
      score: "5",
      date: "10 November, 2021",
      title: "Overall trustworthy",
      review: "Yes, the experience was really good! What I like about Nerdify is that I can message you for free. As with other service providers, I had to pay an extra fee to reach them. Also, the price for everything was totally fair compared to other service providers. Plus, the Nerds were very knowledgeable in their fields.",
      nerdify: "Thanks, Valden! We are so happy are satisfied with your experience and hope to see you back soon."
    },
    {
      name: "John Chaney",
      score: "5",
      date: "8 November, 2021",
      title: "Very professional",
      review: "They were fast at responding and very professional. I had a lot of work done in just four days.",
      nerdify: "We are glad we met your expectations, John!"
    },
    {
      name: "Kolton Prymula",
      score: "5",
      date: "5 November, 2021",
      title: "Very grateful",
      review: "I utilized this service to help me during a challenging time. I will CERTAINLY use Nerdify again in the future and suggest it to friends.",
      nerdify: "Thanks for your review, Kolton! We'll be happy to have you back."
    },
    {
      name: "Ana Norsky",
      score: "5",
      date: "1 November, 2021",
      title: "Will use again!",
      review: "I’m glad I found your website! Good communication, as questions were answered promptly. I had already received my first order, and it was on time, followed instructions, and of great quality! I highly recommend your services to other students and other people. I will use your services again! :)",
      nerdify: "Thanks for your great feedback! We're ready to help you once again at any time <3"
    },
    {
      name: "Mariah Soto",
      score: "5",
      date: "31 October, 2021",
      title: "Awesome",
      review: "I have nothing bad to say, and I would recommend Nerdify. However, keep in mind that we are all human, and not everything will be perfect. The prices are fair, and the service is awesome. Nerds are amazing at communication and are quick to resolve any issues.",
      nerdify: "Thanks, Mariah! It is true that nothing can be perfect all the time but we will do our best to make sure you get the perfect service from Nerdify each time!"
    },
    {
      name: "Elaine Chavez",
      score: "5",
      date: "28 October, 2021",
      title: "Helped me plenty",
      review: "This is the first time I’ve ever used this website! Thank you so much for helping me, as I was struggling and nervous about getting a project completed. I’m happy to find Nerdify because it has helped me plenty!",
      nerdify: "We are so happy to be helpful, Elaine! Thanks for your feedback and we hope to assist you further."
    },

    {
      name: "Nell Donn",
      score: "5",
      date: "25 October, 2021",
      title: "Amazing Service!",
      review: "Their communication is great. They reply promptly through text that makes everything easier and more comfortable and makes you feel like you're talking to an actual person and not a robot. The service was great and of high quality. Very reliable and trustworthy. I will definitely recommend them to other people.",
      nerdify: "Thanks, Neil! Please do recommend Nerdify to friends to get a referral bonus!"
    },
    {
      name: "Nicole Roh",
      score: "5",
      date: "22 October, 2021",
      title: "The Nerds helped me greatly",
      review: "The Nerds helped me greatly. I’m in an accelerated program and have not been to school in over 15 years. When I reached out to the Nerds they were immediately on my task. I received my task earlier than I expected and learned how to use PowerPoint. Make sure you provide the Nerds with all the correct information for your task. Prices can get a little expensive depending on what you need and the timeframe. I ended up with great results. My overall experience would have to be ⭐️⭐️⭐️⭐️⭐️",
      nerdify: "Thank you so much for such great feedback. We are inspired by people like you who decide to invest time and effort in education after a long break. Please stay sure that we always got your back <3"
    },
    {
      name: "Sonia Goncalves",
      score: "5",
      date: "22 October, 2021",
      title: "Amazing Experience!",
      review: "I’m very careful about what websites I use for my projects due to past experiences, but I love how easy everything was. It was just a quick conversation, and the job was done. Super efficient! I loved it!",
      nerdify: "Thank you, Sonia! We are doing our best to make Nerdify the safest and most convenient service to seek help. We are so grateful to you for acknowledging our effort!"
    },
    {
      name: "Phred Mfalme",
      score: "5",
      date: "21 October, 2021",
      title: "I'm a fan!",
      review: "These guys work quickly and are very communicative during the process. I was skeptical at first, but they have made a thankful customer out of me!",
      nerdify: "Loving your feedback, thanks!"
    },
    {
      name: "Tanya Reudgen",
      score: "5",
      date: "19 October, 2021",
      title: "They did exactly what they said!",
      review: "I think that Nerdify did exactly what they said they would. Unfortunately, I wasn't able to ask them when I needed a bit more explanation. Overall, I do thank them, and I think it's great service.",
      nerdify: "Hey there, Tanya! Should you have any questions, please contact us 24/7! We are happy to help you with any inquiries."
    },
    {
      name: "Anyely Nichola",
      score: "5",
      date: "18 October, 2021",
      title: "Legit website",
      review: "It’s not a scam, and the work is done as requested. Very convenient if you don’t have time. Excellent customer service.",
      nerdify: "Thank you so much for acknowledging our customer support team's work!"
    },
    {
      name: "Maria Vasquez",
      score: "5",
      date: "15 October, 2021",
      title: "Worth it!",
      review: "Nerdify's customer service was great. Their work was credible and followed the guidelines.",
      nerdify: "We're happy you are satisfied, Maria!"
    },
    {
      name: "Deanna Bardeloza",
      score: "5",
      date: "12 October, 2021",
      title: "Legit services",
      review: "My first time using Nerdify, and it was great! Fast responses. I actually got my task early, so overall an awesome experience! We're working on my second one now! These people are legit!",
      nerdify: "Thanks for sharing your experience, Deanna!"
    },
    {
      name: "Shanmugam Murugappan",
      score: "1",
      date: "16 October, 2021",
      title: "Beware!",
      review: "These positive reviews are fake! I used their service and lost money. You will never get your money back. These are buch of real crooks. Stay away!",
      nerdify: "Hello there, we are very sorry to see you're so upset! From what I see in our client management system a full refund with a bonus was added to your Nerdify account which was preliminary approved with you. If you wish to receive funds back to your bank account, please let us know. We really hope you give Nerdify a second chance in the future! Also, please be aware that all reviews on all review platforms are real. You can be sure in that because our clients usually add their unique order numbers. We appreciate honest feedback cuz that's how we get insights on how to improve our service, and yours is not an exception. Thank you!"
    },
    {
      name: "Melly Nichols",
      score: "5",
      date: "12 October, 2021",
      title: "Great Experience!",
      review: "Overall, my experience was great. I was actually surprised that this is real! I was literally taking a chance, and I'm glad I did! Response time was quick, and the task was completed on time. I've already told a few of my friends about Nerdify.",
      nerdify: "Thanks, Melly! Did you already get your referral bonus? :)"
    },
    {
      name: "Yajaira Ramirez",
      score: "5",
      date: "11 October, 2021",
      title: "Prompt service",
      review: "Very fast at replying, and very good work on my projects!",
      nerdify: "Thanks, we'll be happy to help you further!"
    },
    {
      name: "Dana Moon",
      score: "5",
      date: "8 October, 2021",
      title: "Value for money",
      review: "Great work! Reasonable price. You get the results fast!",
      nerdify: "Thanks, Dana!"
    },
    {
      name: "Nick Ingles",
      score: "5",
      date: "6 October, 2021",
      title: "The best!",
      review: "People, if you want trustworthy, fast, and intelligent people to deal with, then this is the right, legit website you can trust. They really are worth your time, and they will provide quality work valuing your money spent. Their price is also reasonable.",
      nerdify: "Thank you so much for such a great review!"
    },
    {
      name: "Munyegerwa Anastasie",
      score: "5",
      date: "3 October, 2021",
      title: "Real help exist",
      review: "I did not know that real help existed until I started working with Nerdify. The Nerds are professional, and they get the task done on time. Satisfaction is guaranteed, or you get your money back. I would 100% refer someone to Nerdify. ❤️❤️🔥",
      nerdify: "Hi there, thanks for writing a review about Nerdify! Please don't hesitate to refer your friends, cuz you gonna get a yummy bonus to your account ;)"
    },
    {
      name: "Famous Aranilla",
      score: "5",
      date: "2 October, 2021",
      title: "Love the experience",
      review: "The response to my inquiry was quick, and the communication was outstanding. They were very courteous.",
      nerdify: "Thank you for acknowledging the effort of our amazing customer support representatives!"
    },
    {
      name: "Marquitta Daniel",
      score: "5",
      date: "30 September, 2021",
      title: "5 stars",
      review: "Nerdify offers great service. I gave them 5 stars because of the great communication of the staff, and the quality and accuracy of the work. My only concern was, when I paid the extra fee to get the preliminary results of my project, I didn't get anything until the final stages. If it weren't for that, I would have tipped. Thank you all for everything!",
      nerdify: "Dear Marquitta, we are so sorry that we failed to send you the draft on time. Thank you so much for your feedback, we'll be more attentive next time. Have a good one!"
    },
    {
      name: "Jasmine Rampey",
      score: "5",
      date: "27 September, 2021",
      title: "Will use again",
      review: "My experience was awesome. Everything was great, the Nerds responded super fast, and they got the task completed way before time was up! I'll be using Nerdify again for sure.",
      nerdify: "We'll be happy to see you back, Jasmine!"
    },


    {
      name: "Denise Zam",
      score: "5",
      date: "25 September, 2021",
      title: "Highly Recommended!",
      review: "Hello to whoever is reading this! I highly recommend Nerdify as they're very responsive, helpful, and punctual! Mentoring from Nerds could help you improve your academic performance!",
      nerdify: "Thanks for recommending us, Denise!"
    },
    {
      name: "Bray Io",
      score: "5",
      date: "21 September, 2021",
      title: "It was a great experience",
      review: "It was a great experience! This was my first time trying Nerdify, and it was very easy! The process was hassle-free.",
      nerdify: "Thank you, we'll be happy to support you this semester!"
    },
    {
      name: "Claudia Rodriguez",
      score: "5",
      date: "18 September, 2021",
      title: "Very happy with results!",
      review: "They are very professional and fast! I am extremely happy with the result, and I will continue to use Nerdify!",
      nerdify: "Loving your feedback, Claudia!"
    },
    {
      name: "Quancentia Lewis",
      score: "5",
      date: "14 September, 2021",
      title: "Wonderful Job",
      review: "A wonderful job by Nerdify! I really appreciate the Nerd that helped me complete my project. I will definitely use Nerdify again!",
      nerdify: "Thanks for your feedback, we'll be waiting for you ;)"
    },
    {
      name: "Lindsay Vandenberg",
      score: "1",
      date: "13 September, 2021",
      title: "I spent the rest of the money I had",
      review: "I spent the rest of the money I had in my bank account for the Nerds and they could't find me a Nerd in quantum physics who could help me. I would like a refund back now!",
      nerdify: "Hey, Lindsay! We understand your frustration and are planning to hire more Nerds in this subject. Your refund is on its way, please allow 3-5 business days to get it back to your bank account (unfortunately, we can't speed up the process of the transaction). Hope you give us a second chance in the future!"
    },
    {
      name: "Happy Feet",
      score: "5",
      date: "14 September, 2021",
      title: "I definitely recommend",
      review: "I wish I've found Nerdify sooner! All requests were completed on time! The work was reasonably priced, and I felt it was well worth the money spent! I'm not a huge fan of Math, but I needed to complete an analytics course, and Nerdify made it very easy to complete projects. Nerdify stays in constant contact with you from start to finish. Tip: Be specific with requesting the Nerd you need to help you out and include as many course details as you can so you get someone with that background. I highly recommend their services!\n",
      nerdify: "Hi there, thanks for your awesome feedback! Thumbs up for a great advice you gave, it will definitely come in handy for others <3"
    },
    {
      name: "Jay Mandy",
      score: "5",
      date: "11 September, 2021",
      title: "Nerds are knowledgeable",
      review: "The service was great, and the staff responded to my questions very quickly. The Nerds are well-prepared and very knowledgeable, as reflected in the tasks.\n",
      nerdify: "Thank you! Our customer support team tries to reply as quickly as possible to ensure you get the most prompt service."
    },
    {
      name: "Andreesha Morrison",
      score: "5",
      date: "6 September, 2021",
      title: "On time work",
      review: "The work was completed earlier than expected, and my Nerd did a really great job! I will definitely use Nerdify again in the future!",
      nerdify: "We'll very happy to see you back soon!"
    },
    {
      name: "Leslie Gibson",
      score: "5",
      date: "5 September, 2021",
      title: "Bang for the Buck",
      review: "Nerdify has been helping me achieve better results! If you’re worried about getting scammed, don’t be! Nerdify only charges fair prices for quality work and amazing service!",
      nerdify: "Thanks, Leslie! You are awesome ;)"
    },
    {
      name: "Brielle Gritten",
      score: "5",
      date: "2 September, 2021",
      title: "Real professionals",
      review: "I was nervous about getting assistance from strangers, but sometimes the extra help is needed. Happy I tried Nerdify! Thank you, Nerdify, and thanks to the Nerds for their kindness & professionalism!",
      nerdify: "Thank you for being so kind and finding time to write feedback!"
    },
    {
      name: "Sharon Brianne",
      score: "5",
      date: "30 August, 2021",
      title: "They're lifesavers",
      review: "My experience was great! I was in a tough spot, and they came through for me! They were very professional and efficient. Thank you so much, Nerdify!",
      nerdify: "Thank you so much, Sharon!"
    },

    {
      name: "Andile Maziya",
      score: "5",
      date: "25 August, 2021",
      title: "Amazing website",
      review: "I didn't trust them at first, but they proved that I have nothing to worry about. Their prices are very low too though their services are worth more than that. I am definitely coming back. Thank you so much, Nerdify 😊",
      nerdify: "We're so happy we won your trust!"
    },
    {
      name: "Sandra Hu",
      score: "5",
      date: "21 August, 2021",
      title: "Their work is on point",
      review: "I tried Nerdify's service upon a relative's recommendation, and I would never regret following it. The Nerds here are very professional. Their works are well thought of and are always on point. I would definitely recommend their services.",
      nerdify: "Thanks, we'll do our best to never disappoint you!"
    },
    {
      name: "Camille Morgan",
      score: "5",
      date: "19 August, 2021",
      title: "Nerdify was amazing!",
      review: "Nerdify was amazing! The Nerds made the conversation comfortable! I wanted to make sure that I could get what I needed, so I tried interviewing them. To my surprise, they answered all of my questions even before I got the chance to ask! For me, communication is vital in any transaction, and the Nerds exceeded my expectations! I will definitely use their services again, and I will recommend this to my friends! Thanks, Nerdify!",
      nerdify: "We are so grateful for your feedback, Camille!"
    },
    {
      name: "Tameria Johnson",
      score: "5",
      date: "16 August, 2021",
      title: "A pleasant experience",
      review: "My overall experience was pleasant. I wasn't sure if it was legitimate or a scam, but it turned out to be the former. The process was seamless; they asked me about my expectations and requirements. After that, they worked on finishing the task, and they even delivered it ahead of the due date. What the Nerds gave me was a beautifully crafted work. Great job!",
      nerdify: "Thanks, Tameria! We can't thank you enough for this review <3"
    },
    {
      name: "Gilbert Smith",
      score: "5",
      date: "9 August, 2021",
      title: "Quick and informative",
      review: "The Nerd was quick in answering all of my questions. She provided clear responses about my needs and expectations, and that made this second use of Nerdify a great one",
      nerdify: "Thank you so much! We'll be happy to assist you further!"
    },
    {
      name: "Aaya Kontos",
      score: "5",
      date: "2 August, 2021",
      title: "Terrific!",
      review: "It was terrific; they responded fast, and the task they helped me was done according to all my instructions. I was hesitant at first, but it turned out fine. Highly recommend!",
      nerdify: "We are really glad that you are satisfied with our response rate and the quality of work!"
    },
    {
      name: "Sinthia Jones",
      score: "3",
      date: "11 August, 2021",
      title: "It's all about the luck",
      review: "Sometimes I get a great Nerd, sometimes I don't. I am so sick playing this game.",
      nerdify: "Hi there, Sinthia! We are so sorry for the unpleasant experience you had. I assure you that we will investigate your order history to get to know whether all Nerds assigned followed your instructions properly. Meanwhile, let me remind you that you can always add the \"TOP Nerd\" service if you wanna make sure your task will be handled by one of our top performers."
    },
    {
      name: "Natalie Arevalo",
      score: "5",
      date: "28 July, 2021",
      title: "Third time's a charm",
      review: "The Nerds assigned to me were honest about the work I mentioned to them. They are very talented and intelligent. I guess, third time's really the charm. The customer service was really great.",
      nerdify: "Hello there, thank you so much for your feedback!"
    },
    {
      name: "Blck. Cinderella",
      score: "5",
      date: "26 July, 2021",
      title: "Exceptional Communication",
      review: "They work fast, and their communication is exceptional. They also followed all requirements correctly.",
      nerdify: "Thanks!"
    },
    {
      name: "Glenda G.",
      score: "5",
      date: "26 July, 2021",
      title: "Game-changer",
      review: "Nerdify is a game-changer! From the moment I contacted them, they were efficient, extremely polite, attentive, and swift to respond. I would recommend this to anyone who wants a task completed. I’m a customer for life. Thank you 🙏🏻",
      nerdify: "Thanks to feedback like this one we know that we are doing something meaningful, that our effort actually means something. Thank you so much!"
    },
    {
      name: "Jennifer Clyde",
      score: "5",
      date: "25 July, 2021",
      title: "5 Stars!",
      review: "Nerdify provided quality assistance compared to all other tutoring programs I've used before. In my opinion, the Nerds and their tutoring service deserve 5 stars. Well worth the price.",
      nerdify: "What a great opinion you have, Jennifer! Thanks!"
    },
    {
      name: "Dania Khan",
      score: "5",
      date: "21 July, 2021",
      title: "10/10 for you, Nerdify!!!!",
      review: "10/10. I would recommend this website to anyone who needs help. I’m definitely going to be back in the future for any assistance I may need. The Nerds are very fast, accommodating, and highly responsive. At first, I was very skeptical and anxious as to when I would receive the finished work, but it ended well. I was delighted with the result 😁 Thank you so much, Nerds!",
      nerdify: "Thank you for such amazing feedback! We will keep up with a good job and hope to see you back soon!"
    },
    {
      name: "Nicole C.",
      score: "5",
      date: "19 July, 2021",
      title: "Highly Recommend Them!",
      review: "Since the communication is well-established, I was always aware of the status of my request. The Nerds also completed everything on time.  I highly recommend their services, and I will most likely use this website again.",
      nerdify: "Hey, Nicole! Thanks for your feedback, we really appreciate it!"
    },
    {
      name: "Janique V.",
      score: "5",
      date: "15 July, 2021",
      title: "Great service!",
      review: "Everything went great, and the tasks were completed on time.",
      nerdify: "Thanks, Janique!"
    },
    {
      name: "Laura Arroyo",
      score: "5",
      date: "14 July, 2021",
      title: "Love it!",
      review: "Last semester was so hard. I had to take statistics which is not my best subject. Because of this, I reached out to Nerdify for help, and they did not disappoint. The Nerds they gave to me were always ready to assist me. They explained the points where I was having a hard time, and I really learned things. I have to take accounting soon, and I hope to use them again. Nerdify is an excellent website, give it a try. Thanks, Nerdify!",
      nerdify: "Thank you so much, Laura! We'll be happy to assist you further!"
    },
    {
      name: "Cheryl Plummer",
      score: "5",
      date: "10 July, 2021",
      title: "Quick and courteous",
      review: "The response was quick and courteous. Thanks for your help, Vanessa.",
      nerdify: "Thanks for your review!"
    },
    {
      name: "Karleigh J.",
      score: "5",
      date: "8 July, 2021",
      title: "Best service ever",
      review: "I’ve been scammed before by other websites that provide similar services but, I was really desperate to get help on a college task that was due within a few hours, so I reached out to Nerdify. It is a legitimate website that helps, and they do not disappoint. The quality was superb. 10/10!",
      nerdify: "Jeez, thank you so much! You have no idea how inspiring it is for us!"
    },
    {
      name: "Arie K.",
      score: "5",
      date: "7 July, 2021",
      title: "Lifesavers!",
      review: "Nerdify has been the best life-saving service ever! Hectic times while trying to be an adult student is demanding, but Nerdify has saved the day more than once with everything I needed and asked for on time! Thank you!",
      nerdify: "Thank YOU so much! Such reviews inspire us to work even harder!"
    },
    {
      name: "Ja Ha",
      score: "5",
      date: "5 July, 2021",
      title: "Excellent as always",
      review: "Nerdify instantly replied to my inquiries, and the communication between us was perfect. The only thing I didn't like is how this website threw up pop-ups asking for me to rate the report and leave a tip. I even had to press x or close the popped-out window before I could even download and read it. The Nerd would probably have gotten a tip if I could have read the report first.",
      nerdify: "Thanks for pointing that out! We really want to avoid annoying you with pop-ups. You can always leave a tip by sending the message to one of our agents!"
    },
    {
      name: "Angelique Edwards",
      score: "5",
      date: "29 June, 2021",
      title: "I enjoyed using Nerdify",
      review: "I enjoyed using Nerdify! This was my first time using this website and I was overwhelmed with the amount of work I had to do. I could seriously use some extra pair of hands. The communication with Nerdify was fast and easy. Thanks to them, I was able to finish my tasks promptly. I will definitely be using them again.",
      nerdify: "We are very happy to help you with everything you have on the plate!"
    },


    {
      name: "Trin",
      score: "5",
      date: "24 June, 2021",
      title: "Amazing help in timely manner!",
      review: "They provide amazing help in a timely manner!! The Nerds are also nice and smart!",
      nerdify: "Thanks!"
    },
    {
      name: "Kay",
      score: "5",
      date: "18 June, 2021",
      title: "The Nerds are really good and approachable!",
      review: "Someone who I trust recommended this website to me, so I tried it. I was skeptical at first, but their customer service is top-notch so, I felt at ease. The Nerds here are really good. You don't always have to request a top Nerd to help you because they are all great. The price they charge for their service is also reasonable even though you are asking for rush assistance. They are also open to making adjustments if you want something changed in the task you asked them for.",
      nerdify: "Thanks, Kay!"
    },
    {
      name: "James",
      score: "5",
      date: "11 June, 2021",
      title: "A prompt and friendly company!",
      review: "Nerdify's service was really good. Idk if an engine or a person is responding to the texts, but whatever it is, they (it) were/was responsive and makes sense. The actual task was great, and the Nerd got back to me about 5 hours before the 48-hour mark.",
      nerdify: "Hey James! Thanks for the feedback! JFYI, the ones you were talking to are real humans :)"
    },
    {
      name: "Gaylon Rolen",
      score: "5",
      date: "9 June, 2021",
      title: "The Nerds are by far the best tutors I came across with!",
      review: "Nerdify is by far the best company that offers tutoring that any student can ask for. They make their expertise available in whatever subject you need help with. 5 stars are not enough to rate the quality assistance they provide. I give this service 10 stars!!",
      nerdify: "Thank you so much, Gaylon!"
    },
    {
      name: "Michael Gonzalez",
      score: "3",
      date: "7 June, 2021",
      title: "A bit disappointed",
      review: "I received remarkable timely service, however I did not receive the updates on the progress as I was promised.",
      nerdify: "Hey Michael, thanks for your honest feedback. We are so sorry for the inconvenience, we experienced a tech glith last week and some clients did not receive our messages. We have already fixed it and hope to see you back! "
    },
    {
      name: "Ana Hernandez",
      score: "5",
      date: "6 June, 2021",
      title: "Wow! Real Life Savers!",
      review: "Wow!!! I got the tasks, and I'm impressed with their quality. The Nerds are true lifesavers. So grateful to whoever helped me with my tasks. God bless you ❤️",
      nerdify: "Thank you so much!"
    },
    {
      name: "William Gresham",
      score: "5",
      date: "3 June, 2021",
      title: "Their service is awesome!",
      review: "What a delightful assistance! Almost too good tbh! I love the service you guys provide!",
      nerdify: "Thanks, William!"
    },
    {
      name: "Nicole Julia",
      score: "5",
      date: "29 May, 2021",
      title: "A quick and polite customer service",
      review: "I was in a time crunch, but I beat it due to the promptness of the help I received from the Nerds. The Nerds who helped me are truly amazing, and they are also very polite.",
      nerdify: "Thanks"
    },

    {
      name: "Danielle Shetler",
      score: "5",
      date: "24 May, 2021",
      title: "Great!",
      review: "I have relied on them for assistance in a big project related to my class and was consequential in my later ratings. Through their help, I managed to get better results. Thank you so much. For those who struggle with this kind of service, you need to be very clear on what assistance you need.  They are not with you in the class, so they need specific explanations on how they can help.",
      nerdify: "Thanks, Danielle! Your advice is right to the point!"
    },
    {
      name: "Fedline",
      score: "5",
      date: "22 May, 2021",
      title: "Top-tier communication",
      review: "The communication was top tier, and the enthusiasm showed to help me in such a dire moment was greatly appreciated.",
      nerdify: "Thank you so much!"
    },
    {
      name: "HC",
      score: "5",
      date: "21 May, 2021",
      title: "Always prompt and on time.",
      review: "The Nerds were always prompt and on time! Communicating with them is also smooth and superb.",
      nerdify: "Thanks!"
    },
    {
      name: "HalfandHalf",
      score: "5",
      date: "19 May, 2021",
      title: "Fantastic Nerd",
      review: "My Nerd answered quickly at all times and did great work. Remained patient with me even as I had to take a step away multiple times to find required materials and other reasons. Good customer experience, all thanks to them!",
      nerdify: "Thanks!"
    },
    {
      name: "KickBoyz ENT",
      score: "5",
      date: "18 May, 2021",
      title: "Great experience!",
      review: "My overall experience with this website is beyond great!",
      nerdify: "Thanks!"
    },
    {
      name: "Dyna Ajax",
      score: "5",
      date: "17 May, 2021",
      title: "They nailed it!",
      review: "They are amazing. I made a great presentation on the projects they helped me with.",
      nerdify: "We'll be happy to help you further!"
    },
    {
      name: "Lisa Ann",
      score: "5",
      date: "16 May, 2021",
      title: "I had a great experience with Nerdify",
      review: "I had a great experience with Nerdify, they were always available when I needed them, and they really helped me out when I was on a time crunch! I definitely would recommend them to others!",
      nerdify: "Thank you so much!"
    },
    {
      name: "Jay",
      score: "5",
      date: "15 May, 2021",
      title: "Great Help!",
      review: "Asking for the help of a Nerd made my work great! I will definitely come back again.",
      nerdify: "Great, can't wait!"
    },
    {
      name: "SAUDI STYLE COOKING",
      score: "5",
      date: "12 May, 2021",
      title: "Thanks for enlightening me",
      review: "Thanks to Nerdify, I was able to finish my task in time for submission. I had an assignment in English, and I was completely lost, but the Nerd was able to help me. He explained things I do not understand and was able to do exactly what the instructor asked! Thank you for the fruitful communication, and I will be coming back next semester.",
      nerdify: "We'll be happy to see you back!"
    },
    {
      name: "Ania",
      score: "5",
      date: "9 May, 2021",
      title: "Wonderful job!",
      review: "Wonderful job! I will be working with you guys again pretty soon.",
      nerdify: "Thanks, Ania!"
    },
    {
      name: "ANDY SANTORUM",
      score: "5",
      date: "8 May, 2021",
      title: "I love the kind of work they provide",
      review: "I love how quickly they work! This website has lots of potentials, and maybe one day, I will no longer be the one in the receiving end of help but instead the one who is providing it.",
      nerdify: "Thank you, Andy!"
    },
    {
      name: "Eli D",
      score: "5",
      date: "7 May, 2021",
      title: "Wonderful partnership!",
      review: "The product of my brainstorming with a Nerd was the well-written output that I was able to make. The information I provided lines up with the expectation of the tasks due to the assistance of the Nerd. The Nerd assigned to me helped me with the conceptualization and research of supporting details. Worth the money, and I plan on using this platform again.",
      nerdify: "Thanks!"
    },
    {
      name: "India Newborn",
      score: "5",
      date: "6 May, 2021",
      title: "Highly recommend!!",
      review: "My experience was very great. Everything went easy, and I appreciate every bit of it. No complaints, I will be coming back.",
      nerdify: "Looking forward to it!"
    },
    {
      name: "TheADotBot",
      score: "4",
      date: "3 May, 2021",
      title: "Great Experience!",
      review: "Great experience. Some of my work wasn’t 100% completed, but I let the Nerds know, and they fixed it right away.",
      nerdify: "Thanks!"
    },
    {
      name: "Bobbi Shadowens",
      score: "5",
      date: "3 May, 2021",
      title: "A magnificent work considering the price",
      review: "The Nerds here in Nerdify are easy to work with. They work fast, and the overall rate was reasonable.",
      nerdify: "Thanks, Bobbi!"
    },
    {
      name: "Yuberkis Paredes",
      score: "5",
      date: "1 May, 2021",
      title: "Good job!",
      review: "They're very responsible and are working super fast. The Nerds really do great jobs.",
      nerdify: "Thank you!"
    },
    {
      name: "Chrystal Cammarata",
      score: "5",
      date: "29 April, 2021",
      title: "Amazing service!",
      review: "Everything is always on time and accurate. The text responses are also quick and efficient. This service is truly amazing!",
      nerdify: "Thank you, Crystal!"
    },
    {
      name: "Melissa Rodriguez",
      score: "5",
      date: "27 April, 2021",
      title: "Very helpful despite the tight schedule",
      review: "I was in a tight spot for my statistics class and had a project assigned during finals week, which gave me very little time to do. So I searched for homework help, and Nerdify was the first one to pop up. I asked them for help regarding my task. I also told them the tight deadline. And yet, they still replied and assisted me in completing my tasks. I would definitely recommend this!",
      nerdify: "Thanks for your feedback, Melissa!"
    },
    {
      name: "naomi mays",
      score: "5",
      date: "26 April, 2021",
      title: "Great Job!",
      review: "The Nerds do a great job, and they're very punctual.",
      nerdify: "Thank you!"
    },
    {
      name: "Maria",
      score: "5",
      date: "25 April, 2021",
      title: "Highly recommend!",
      review: "The Nerds here at Nerdify are excellent in helping us, busy college students, finish our tasks and achieve the performance we are striving for! I am completely satisfied by the help I received from my Nerd and the result I got. Thank you so much!",
      nerdify: "Thanks, Maria!"
    },
    {
      name: "Annissa Heslope",
      score: "5",
      date: "22 April, 2021",
      title: "Amazing customer service",
      review: "The team was communicative and effective. They were very responsive and handled my questions with ease. I immediately recommended them, and I would use their services again.",
      nerdify: "Thank you so much!"
    },
    {
      name: "Oscar Dino",
      score: "5",
      date: "20 April, 2021",
      title: "Awesome!",
      review: "Great! I had an awesome experience.",
      nerdify: "Thank you, Oscar!"
    },
    {
      name: "A.D.",
      score: "5",
      date: "18 April, 2021",
      title: "Worth trying",
      review: "I was initially skeptical but my Nerdify experience turned out great! I was in a pinch, and have too much on my plate. My Nerd demonstrated excellent service and delivered the work on the date agreed upon. I will be using their services again. I highly recommend Nerdify if you’re in need of some help!",
      nerdify: "Thanks for such amazing feedback!"
    },
    {
      name: "Arlinda wilson",
      score: "5",
      date: "16 April, 2021",
      title: "Nerds are the best",
      review: "Excellent tutors. My tasks were perfect, and I’m glad the Nerds were here to help me. Thank you, and I will always be using your service.",
      nerdify: "Thank you so much!"
    },
    {
      name: "Madi",
      score: "1",
      date: "14 April, 2021",
      title: "I never write reviews but…",
      review: "I never write reviews but this time is different. The customer service itself (scheduling a task, follow-up, etc.) was fantastic but the actual task itself was done incorrectly. The Nerd made a mistake in the calculation which I think is very unprofessional.",
      nerdify: "Dear Madi, thank you very much for pointing our attention to the issue. The situation that happened requires a thorough investigation from our side as we want to make sure the Nerd followed your instructions and delivered high-quality work. Meanwhile, we decided to give you a 100% refund, as a token of our appreciation of your feedback. Please allow 3-5 business days to receive the money."
    },
    {
      name: "Lucxxi",
      score: "5",
      date: "12 April, 2021",
      title: "Fast and Polite",
      review: "They are very polite and fast to respond. The Nerds also do anything they can to help those who reached them. And they are excellent at what they do.",
      nerdify: "Thanks for your kind feedback!"
    },
    {
      name: "Sab",
      score: "5",
      date: "15 April, 2021",
      title: "Quality assistance",
      review: "I am not the type of person to leave comments but, I am so satisfied with their services that I had to express it. From the text messages to the completion of the task, everything was just well taken cared of, including the timeframe. Thank you very much.",
      nerdify: "Thanks for such great feedback!"
    },
    {
      name: "Derek Frank",
      score: "5",
      date: "7 April, 2021",
      title: "The response time was incredibly swift",
      review: "The response time was incredibly swift, and I was impressed. The Nerds did everything flawlessly, and on schedule so, I will surely be back.",
      nerdify: "Thank you, Derek!"
    },
    {
      name: "Shoroq Alvarez",
      score: "5",
      date: "3 April, 2021",
      title: "They respond quickly",
      review: "Their response was quick, and they have a great attitude. They did more and better than what I asked them for. Thank you so much.",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "D. C.",
      score: "5",
      date: "1 April, 2021",
      title: "An efficient and effective partner",
      review: "Good experience. I have used this service a couple of times, and Nerds always help me whenever I'm short of time. The communication between me and my Nerd was also efficient and effective.",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Anonymous",
      score: "5",
      date: "28 March, 2021",
      title: "Will definitely use this again",
      review: "I have already used Nerdify three times, and each time has been a great experience. The Nerds were very efficient and knowledgeable so I will continue working with them.",
      nerdify: "Thank you!"
    },

    {
      name: "Nate Estrada",
      score: "5",
      date: "22 March, 2021",
      title: "Honest review about Nerdify",
      review: "Honestly, everything about Nerdify is awesome. It's so easy to use! They don’t waste your time and they get straight to the point, time crunches are never an issue and they always bring great feedback. Me being a college student this helped tremendously. At first I was nervous, but after a one-time use they won me over! Simply amazing!",
      nerdify: "Thanks, Nate!"
    },
    {
      name: "Maria Bonilla",
      score: "5",
      date: "18 March, 2021",
      title: "I am so impressed with Nerdify",
      review: "I am so impressed with Nerdify! They finished my last-minute task in record time! I will 100% use them again and again!",
      nerdify: "Can't wait to see you back, Maria!"
    },
    {
      name: "Reid",
      score: "5",
      date: "17 March, 2021",
      title: "Nerdify is amazing",
      review: "Nerdify is amazing. They are very quick and professional. The price is right!",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Fadil",
      score: "5",
      date: "16 March, 2021",
      title: "Performance booster",
      review: "It’s been a few weeks now that I've been using the website and I like it. It’s a true performance booster.",
      nerdify: "We'll be glad to help you further!"
    },
    {
      name: "Saleem",
      score: "1",
      date: "15 March, 2021",
      title: "The worst customer experience I have ever received!",
      review: "I had a set of pre-exam questions that I needed to be done which were set on multiple documents. I contacted Nerdify and presented them with these questions. They refused to help me as this violates their policies! This was absolutely the worst experience I have ever received!",
      nerdify: "Dear Saleem, my name is Ann and I am the QA specialist at Nerdify. Let me apologize for the terrible misunderstanding that caused you so much stress. The full refund must have already appeared on your bank account. If there is something we can do you make you feel better, let us know!"
    },
    {
      name: "Ryan Roberts",
      score: "5",
      date: "11 March, 2021",
      title: "Easy & friendly to communicate with",
      review: "Easy & friendly to communicate with and very fair prices!",
      nerdify: "Thanks, Ryan!"
    },
    {
      name: "Hashir Mohmand",
      score: "5",
      date: "9 March, 2021",
      title: "I had a very good experience with them",
      review: "I had a very good experience with them. They are really polite and really helpful. I also got a good deal with them so I am really satisfied with it.",
      nerdify: "Thanks!"
    },
    {
      name: "RG",
      score: "5",
      date: "8 March, 2021",
      title: "They helped me out tremendously!",
      review: "They helped me out tremendously!!! The quality of work was excellent and they are very professional.",
      nerdify: "We will be happy to see you back soon!"
    },
    {
      name: "T.M.",
      score: "5",
      date: "5 March, 2021",
      title: "Nerdify has never let me down!",
      review: "Nerdify has never let me down! Amazing service!!!",
      nerdify: "Thank you, Tiara!"
    },
    {
      name: "Emma Olsberg",
      score: "5",
      date: "2 March, 2021",
      title: "Thank you!",
      review: "I was having such a busy week and needed to catch a break. My Nerd did an amazing job! He exceeded everything I expected. I will definitely be coming back for more help. Thank you, guys!",
      nerdify: "We are looking forward to helping you again!"
    },
    {
      name: "Michelle",
      score: "5",
      date: "2 March, 2021",
      title: "I literally love Nerdify",
      review: "I literally love Nerdify. Fast, reliable, accurate, and courteous service!",
      nerdify: "Thanks, Michelle"
    },
    {
      name: "Kenny",
      score: "5",
      date: "28 February, 2021",
      title: "Excellent service!",
      review: "Excellent service! The professional and highly communicative process from start to finish. Everything was completed on time! High recommended, don't hesitate!",
      nerdify: "Thanks, Kenny!"
    },
    {
      name: "Yesha Patel",
      score: "5",
      date: "26 February, 2021",
      title: "I love these guys!",
      review: "I love these guys, they are really professional and do the work on time. They also issue a refund immediately if you are not satisfied with the work.",
      nerdify: "Thanks for the review!"
    },
    {
      name: "Sherry Kula",
      score: "5",
      date: "25 February, 2021",
      title: "Nerdify is simply the best.",
      review: "Nerdify is simply the best. They have very knowledgeable people who follow the requirements. They are also timely. Thank you, Nerdify!",
      nerdify: "You are the best, thank you!"
    },
    {
      name: "Juan",
      score: "5",
      date: "25 February, 2021",
      title: "Amazing",
      review: "Amazing! They help out with any subject and always guarantee the best results.",
      nerdify: "Thanks for your feedback, Juan!"
    },
    {
      name: "Von",
      score: "5",
      date: "22 February, 2021",
      title: "Always my go-to",
      review: "It's always my go-to. For the most part, they are completely understanding.",
      nerdify: "We really hope you will be coming back!"
    },
    {
      name: "Jerome Ordes",
      score: "5",
      date: "21 February, 2021",
      title: "Great overall",
      review: "Great overall, sometimes the answers are unclear but support gets back right away.",
      nerdify: "Thanks for your feedback! We will strive to make your experience even better!"
    },
    {
      name: "Letycia Oliveira",
      score: "5",
      date: "20 February, 2021",
      title: "I was lost before I found them",
      review: "I wasn’t doing great in the class. My class was online so I didn’t have a lot of attention from the professor and then I found these guys. They helped me get better in class and understand more!",
      nerdify: "We're really glad to learn that your life became a little bit easier, thank you!"
    },
    {
      name: "Luz Deniz",
      score: "5",
      date: "19 February, 2021",
      title: "It was fast and easy",
      review: "It was fast and easy. I am 100% satisfied. I'm coming back again.",
      nerdify: "We'll be waiting for you ;)"
    },
    {
      name: "Sindy Applegate",
      score: "5",
      date: "18 February, 2021",
      title: "Fast and professional",
      review: "Fast and professional approach.",
      nerdify: "Thanks, Sindy!"
    },
    {
      name: "Alexis",
      score: "5",
      date: "15 February, 2021",
      title: "This has been an absolute lifesaver!",
      review: "This has been an absolute lifesaver! They were so prompt in getting back to me and worked with me all the way. I fell short on time and couldn't juggle all three projects, so they stepped up and helped me out in a pinch! They were very diligent. I will continue to use this service and absolutely recommend this to all of my friends and colleagues in the future!",
      nerdify: "Thank you very much for your feedback, Alexis!"
    },
    {
      name: "Harold",
      score: "3",
      date: "13 February, 2021",
      title: "I don't trust them anymore",
      review: "I have dealt with them for a few years. I often tipped the Nerd to show my appreciation. It went well the first few year, but I didn't like my last task.",
      nerdify: "Dear Harold, the most upsetting thing for any service is to see that once a loyal and satisfied client got disappointed and lost faith in receiving the experience he deserves. On behalf of the whole Nerdify team I apologize for the frustration we brought instead of making your life easier and stress-free as we aim. We were not able to find you in the client management system by the name, could you please add any of your order numbers? Rest assured that the case will be passed to our QA team and we will get to the bottom of this."
    },
    {
      name: "Keita",
      score: "5",
      date: "8 February, 2021",
      title: "I found Nerdify when I was swamped",
      review: "I found Nerdify when I was swamped in classes. That was 2 years ago and I still use them! Very convenient, easy to use, fair pricing, on time, and quality service! I would recommend it to anyone!",
      nerdify: "Thank you, Keita!"
    },
    {
      name: "LivL",
      score: "5",
      date: "6 February, 2021",
      title: "Seriously, a top-notch service",
      review: "Seriously, these guys are good! They have helped me with so many classes. I have full trust in the service they provide. Plus they are super quick to respond. Top-notch! 😁",
      nerdify: "Thanks for such kind words!"
    },
    {
      name: "Bangely Garcia",
      score: "5",
      date: "3 February, 2021",
      title: "My Nerd was very helpful",
      review: "My Nerd was very helpful and attentive.",
      nerdify: "Thanks, hope to see you back soon!"
    },
    {
      name: "Sally Alkhayat",
      score: "5",
      date: "31 January, 2021",
      title: "Love it!",
      review: "I loved the task they helped me with. The service was so professional and I loved the way they treated me.",
      nerdify: "Thanks, Sally!"
    },
    {
      name: "Wheeler",
      score: "5",
      date: "30 January, 2021",
      title: "The Nerd did an amazing job",
      review: "The Nerd did an amazing job. This is my number one trusted website.",
      nerdify: "Thanks for such great feedback!"
    },
    {
      name: "Syncere",
      score: "5",
      date: "27 January, 2021",
      title: "I couldn’t be happier",
      review: "I had an amazing experience. The customer service was courteous. My task exceeded my expectations. I will definitely continue using them.",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "JJJ",
      score: "5",
      date: "25 January, 2021",
      title: "Nerdify is very professional",
      review: "Nerdify is very professional, they are easy to communicate with and always get the job done on time! I would highly recommend Nerdify if you need online help.",
      nerdify: "Thank you so much!"
    },
    {
      name: "Katherine Chhabra",
      score: "5",
      date: "22 January, 2021",
      title: "On spot!",
      review: "I had an urgent task and these guys came through - I will def use them again!",
      nerdify: "Thanks, Katherine!"
    },
    {
      name: "Demi Rosario",
      score: "5",
      date: "11 January, 2021",
      title: "Great experience",
      review: "Great experience! Quick and efficient work. Highly impressed with Nerdify's work!",
      nerdify: "Thanks, Demi!"
    },
    {
      name: "Ashley Diane Howard",
      score: "5",
      date: "21 December, 2020",
      title: "My Go-To Source",
      review: "Every time I'm in a bind or can't devote the time needed to manage my workload, Nerdify is the trusted source I can turn to!",
      nerdify: "Thank you, Ashley!"
    },
    {
      name: "Nicole W.",
      score: "1",
      date: "17 December, 2020",
      title: "Not going to use it",
      review: "I will not recommend this website. I requested a refund for the task they failed to complete on time and still haven't gotten a response back.",
      nerdify: "Dear Nicole, I am so sorry for the upsetting experience you had at Nerdify. We've put the Nerd who was assigned to your task on probation. From what I see in our client management system, the refund for your order + bonus will be issued to your balance in the next few hours. Hope you give us a second chance to meet your expectations!"
    },
    {
      name: "Tania",
      score: "5",
      date: "15 December, 2020",
      title: "LIFE SAVERS!",
      review: "LIFE SAVERS!!! I couldn’t finish my Math class due to getting surgery & they provided professional assistance so that I could pass it with proper knowledge of the subject! This isn’t the first time I needed Nerdify to help me. I love these guys and will forever depend on them!!! And no I am not one to write reviews but these guys earned and deserve it! The pricing is also reasonable! They always work with you and keep you updated on things. Love them! Thank you!!!",
      nerdify: "Love your feedback, Tania!"
    },
    {
      name: "Freddy Johnson",
      score: "5",
      date: "14 December, 2020",
      title: "Best Company!!!",
      review: "This company is very organized! The Nerds are always there when I need help. I will recommend it to everyone!",
      nerdify: "Thanks, Freddy!"
    },
    {
      name: "Cherrie T.",
      score: "5",
      date: "11 December, 2020",
      title: "Excellent customer service",
      review: "Excellent customer service. Affordable prices and the Nerds are responsible and helpful. I can always count on them with my school stuff.",
      nerdify: "Thanks, Cherrie!"
    },
    {
      name: "Jackeline Lopez",
      score: "5",
      date: "10 December, 2020",
      title: "Absolutely the best!!!",
      review: "They really helped out when I needed it the most! Even with all the good reviews at first, I was still somewhat doubtful to give them a try but decided to go for it anyway and I don’t regret it! They were very efficient and I will definitely be using their help again.",
      nerdify: "Thank you so much!"
    },
    {
      name: "Tayler M.",
      score: "2",
      date: "13 December, 2020",
      title: "Disappointed!",
      review: "It’s like they do not even read or pay attention to instructions. Do not waste your time.",
      nerdify: "Dear Tayler, let me apologize for the upsetting experience you had at Nerdify. We are doing our best to meet our clients' expectations regarding the level of service and quality work. As I see in our client management system a full refund was issued to your card. I wish there was something we could do to make you change your mind and give us a second chance!"
    },
    {
      name: "Widley",
      score: "3",
      date: "8 December, 2020",
      title: "Never had a problem with them until…",
      review: "Never had a problem with them until just now. They canceled my request because they couldn’t find a Nerd. I've been with them for 3 months and never expected this!",
      nerdify: "Dear Jose, we so sorry for the situation that occurred with your task! This is a very upsetting concatenation of circumstances and we hope you give us a second chance in the future!"
    },
    {
      name: "Rigs",
      score: "5",
      date: "8 December, 2020",
      title: "Great!",
      review: "My Nerd did a great job!",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "AJ",
      score: "5",
      date: "8 December, 2020",
      title: "I love Nerdify",
      review: "I love Nerdify! They were able to work with me very quickly and they were so professional. I got everything I needed for much less than on other websites. I will definitely be using Nerdify again and recommend it to everyone.",
      nerdify: "Thank you so much!"
    },
    {
      name: "Eugene Boaventura",
      score: "5",
      date: "7 December, 2020",
      title: "Nerdify is the best",
      review: "Nerdify is the best. They’re honest with you whenever you contact them. Sometimes the prices are a bit high but it’s definitely worth it, tho. They’ll do their best to help you in the right direction.",
      nerdify: "Thanks for your kind words, Eugene!"
    },
    {
      name: "Emily Faye",
      score: "5",
      date: "6 December, 2020",
      title: "Very pleased",
      review: "Very pleased with my Nerdify experience. The customer service is great! It’s so nice to be able to just send a text with questions and get them answered immediately. The quality is good. Definitely plan on using it again!",
      nerdify: "Thank you, Emily!"
    },
    {
      name: "Annam Bawani",
      score: "5",
      date: "6 December, 2020",
      title: "AMAZING service!",
      review: "I was very wary when I first started working with Nerdify. I read all 1-star reviews and everything! But I’m here to say - don’t trust those reviews!!! I had an amazing experience! The person who I was texting was very prompt in the responses. My request was completed on time (I had to fix a few things and they did it for free, no hassles). It’s worth every penny!",
      nerdify: "Wow, thank you so much!"
    },
    {
      name: "Cami Cane",
      score: "5",
      date: "5 December, 2020",
      title: "24/7 support you can count on",
      review: "Very thorough, very top-notch service. Nerds know what they are doing. Educated professionals. Honest and trustworthy. And VERY COOPERATIVE AND RESPONSIVE!!!",
      nerdify: "Thank you!"
    },
    {
      name: "Grazy Gomes",
      score: "5",
      date: "4 December, 2020",
      title: "Great experience with Nerdify",
      review: "I had a really great experience with Nerdify. Definitely worth it!",
      nerdify: "Thank you!"
    },
    {
      name: "Tecca “TTV BY THE WAY” Bell",
      score: "5",
      date: "3 December, 2020",
      title: "100% Recommend",
      review: "I had a test coming up that I was completely unprepared for and I decided to take a leap and try Nerdify so that they give me a Nerd for urgent tutoring. I was very skeptical at first and I asked many questions. My questions were answered and I was reassured that I was in good hands. I was shocked by the level of tutoring. I mean, you can actually see that someone takes time and put in the effort to get you prepared for an exam. I will 100% use them again in the future (if it comes down to it again). And I’d like to personally thank them for what they do and the great experience that I had.",
      nerdify: "Hope to see you back soon!"
    },
    {
      name: "Stephanie Ahanotu",
      score: "5",
      date: "2 December, 2020",
      title: "They help!",
      review: "I was in a tight corner and they helped me out completely. The customer service is brilliant. I would definitely recommend it!",
      nerdify: "Thank you for your kind words, Stephanie!"
    },
    {
      name: "Saad",
      score: "5",
      date: "1 December, 2020",
      title: "Great service",
      review: "Great service! My presentation notes were completed with perfection and delivered on time.",
      nerdify: "Thanks!"
    },
    {
      name: "Joshua",
      score: "5",
      date: "29 November, 2020",
      title: "Perfect!",
      review: "I managed to improve my performance since I started using these guys. The Nerds will go above and beyond to explain the subject to you!",
      nerdify: "We're so glad!"
    },
    {
      name: "William Womble",
      score: "5",
      date: "29 November, 2020",
      title: "Nerdify is your go-to!",
      review: "Nerdify is your go-to when you need an extra hand with those frustrating school tasks. I’ve used them numerous times and I am truly thankful.",
      nerdify: "Truly thankful to you, William!"
    },
    {
      name: "Happygirl",
      score: "5",
      date: "28 November, 2020",
      title: "I found this website when I was searching for online tutoring help",
      review: "I found this website when I was struggling and stressing to beat deadlines for one particular class. They have been a real savior for me during this stressful school year. They helped me by walking me through the process and eased my fear of failing the class. Now I am confident and ready to face any difficult course. Thanks to the Nerds 🤓🤗",
      nerdify: "We are so grateful for your kind words!"
    },
    {
      name: "Caro",
      score: "4",
      date: "27 November, 2020",
      title: "Recommended!",
      review: "I recommend Nerdify if you’re a student like me that doesn’t have time to complete all your tasks from A to Z. I recommend getting a Top Nerd too because that gives you less of a chance of messing up! If your needs are not met they will refund you.",
      nerdify: "Thanks for your feedback. How can we get 5 stars from you, tho? ;)"
    },
    {
      name: "Gehovell Rounds",
      score: "5",
      date: "25 November, 2020",
      title: "Literally the best",
      review: "Omg, I can't believe I didn't find this website before. Thank you so much. I got the most professional help with my task! Thank you!",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Reina Rivero",
      score: "5",
      date: "23 November, 2020",
      title: "Great experience here",
      review: "I had a great experience with Nerdify. They are really friendly and flexible. I received assistance with my task right on time. I’m pretty sure that my results will be excellent from now on since I have this kind of assistance. I recommend them!",
      nerdify: "Thanks <3"
    },
    {
      name: "Ana",
      score: "5",
      date: "21 November, 2020",
      title: "Amazing!",
      review: "I started getting help from these amazing Nerds when I started college and just had too much going on and did not have time to finish everything. They do everything on time and the quality is great. I will continue hiring them!",
      nerdify: "Thank you <3"
    },
    {
      name: "Allona c",
      score: "5",
      date: "19 November, 2020",
      title: "The best homework assistance app",
      review: "Nerdify is a really great app. It helped me with my homework a lot and if I wouldn’t have known about this app, I would’ve probably failed. I would really recommend getting this app - it’s totally worth it! if you’re a procrastinator like me, this will really help!",
      nerdify: "Happy you like our app! <3"
    },
    {
      name: "Cynthia",
      score: "5",
      date: "19 November, 2020",
      title: "Great, fast service and very helpful",
      review: "It is enough to say that Nerdify is a great, fast service. The Nerds are helpful. I will definitely use them again in the future!",
      nerdify: "Hope to see you back soon, Cynthia!"
    },
    {
      name: "Tate Seidewand",
      score: "5",
      date: "17 November, 2020",
      title: "Good service to help you avoid the stress",
      review: "Exactly what I was looking for and needed! Took away loads of stress!",
      nerdify: "Thanks, Tate!"
    },
    {
      name: "Jules",
      score: "4",
      date: "14 November, 2020",
      title: "Great Customer Sevice",
      review: "They keep their promise, if you are not satisfied with the final product they will review it, and keep reviewing it till you are satisfied. They helped me out tremendously. I highly recommend them.",
      nerdify: "Thanks, Jules!"
    },
    {
      name: "R.D.",
      score: "5",
      date: "12 November, 2020",
      title: "Why they deserve 5 stars!",
      review: "I have used Nerdify for 6 tasks so far. Half of these tasks were stressful. Of course, the Nerds have made sure that all of them were correct. If you want to get good results, you have to be on top of watching and ensuring what was needed and asking for it to be revised. Nerdify was extremely helpful, accommodating, and they have also strived for an outcome to provide me with complete satisfaction.  They worked with me and were the liaison, making sure that my Nerd ensures success! You might think why 5 stars then... it is well deserved. Though we worked together to attain the positive outcomes in some tasks, the others were stress-free and exceeded my minimal desired outcome! Thank you Nerdify! Keep up the great work! I appreciate it!",
      nerdify: "Wow, thank you so much for your feedback! I really hope that our future collaboration will leave only positive impressions <3"
    },
    {
      name: "Vikki",
      score: "5",
      date: "11 November, 2020",
      title: "Best website ever",
      review: "Perhaps, the best website ever when you need an expert to help you understand your tasks.  Whenever needed, Nerdify is always there for me 24/7. I mean it, literally. I’ve already recommended this website to my cousins who are also in college and told all my friends about them.",
      nerdify: "Thank you <3"
    },
    {
      name: "Richy Mathew",
      score: "3",
      date: "10 November, 2020",
      title: "Great customer support but not so good Nerd",
      review: "The customer service was good but the Nerd wasn’t that good...he messed up my task and had to redo it. I like the final result but why not do it the first time?",
      nerdify: "Dear Richy, I am so sorry that the Nerd failed to deliver 100% flawless work the first time and had to revise it to meet your expectations. This caused additional stress for you and this fail is totally on us. I hope you come back soon so that we can change your impression.  We will never fail you again!"
    },
    {
      name: "Ishaq Muhammad",
      score: "5",
      date: "9 November, 2020",
      title: "Great exam assistance!",
      review: "They were very helpful and caring while explaining those things that I did not know. Thanks to them, I could earn 100 on my course task. I could never do it before, but they have trained me so well that I had no more trouble. I contacted them around 8 pm. I already had my notes ready by 10:35 after talking to my tutor. I advise people to use them for the help of any kind because they are patient and caring. I will use them again for upcoming classes and tasks.",
      nerdify: "Thank you so much for your feedback! Hope to see you back in the nearest future!"
    },
    {
      name: "John Nguyen",
      score: "5",
      date: "8 November, 2020",
      title: "Very friendly and helpful",
      review: "Very friendly and helpful. Really easy to use service and they are always on time.",
      nerdify: "Lots of love to you from the whole Nerdify team!"
    },
    {
      name: "GL",
      score: "5",
      date: "3 November, 2020",
      title: "Nerdify is amazing!",
      review: "Nerdify has been super helpful. They helped me with my task very fast. Great communication even after they provided me with great results, amazing!",
      nerdify: "Thank you, your feedback is much appreciated!"
    },
    {
      name: "Nick Brazy",
      score: "5",
      date: "2 November, 2020",
      title: "They assisted me with my task mad fast!",
      review: "They assisted me with my task mad fast! And the pricing was very good too.",
      nerdify: "Thank you so much for your feedback! Hope to see you back in the nearest future!"
    },
    {
      name: "Rae",
      score: "5",
      date: "1 November, 2020",
      title: "Great work and always done on time",
      review: "They provide superb assistance 24/7!",
      nerdify: "Thanks! Can't wait to help you further <3"
    },
    {
      name: "Brian Tran",
      score: "5",
      date: "Oct 30, 2020",
      title: "Fast and excellent customer service",
      review: "Nerdify is very trustworthy. I have been using Nerdify for a lot of tasks and every time they finish the work fast. Also, the quality of assistance is very good."
    },
    {
      name: "Natali Rivera",
      score: "5",
      date: "29 October, 2020",
      title: "Literally the best tutors",
      review: "Literally the best “assist me online” service providers. They have helped me to complete 8 quizzes by training me with their custom templates. It has saved my statistics class. Since that day, I continue using their services."
    },
    {
      name: "J Ross",
      score: "5",
      date: "26 October, 2020",
      title: "I've used Nerdify twice and can't complain",
      review: "I've used Nerdify on two separate occasions for 3 tasks. When you have a clear explanation and good templates, you are bound to receive 100% for each. It is exactly what has happened to me. I plan on approaching my Nerd again and hope for the same great service quality!"
    },
    {
      name: "Mudi",
      score: "5",
      date: "25 October, 2020",
      title: "Brilliant and excellent service",
      review: "Absolutely best of the best!"
    },
    {
      name: "Iloveplaysoccer Gaming",
      score: "5",
      date: "24 October, 2020",
      title: "Reliable!",
      review: "This service is very reliable and very affordable. They get some of the best people to do the work, and they are very great. I 100% recommend this service for every single piece of work you need help with."
    },
    {
      name: "Ky Gibson",
      score: "5",
      date: "24 October, 2020",
      title: "Nerdify has been amazing to me",
      review: "Nerdify has been amazing to me. The customer service is always pleasant. The Nerds that are hired to complete tasks always get it done the time you expect it to be done or even sooner. More importantly, their assistance is of very high quality!"
    },
    {
      name: "Josh",
      score: "3",
      date: "23 October, 2020",
      title: "Hit or miss!",
      review: "Was told my task could be done within 24 hours. Then they proceed to ask for extra 2-3 hours which I couldn't agree to. I’ve had good experience in the past but also not so good now. It’s a hit or miss with this service.",
      nerdify: "Dear Josh, the situation you described is definitely not the level of service we aim to provide to our users. Please specify your order number or your email so that we could investigate this particular case and compensate you accordingly."
    },
    {
      name: "Alex",
      score: "4",
      date: "21 October, 2020",
      title: "So far so good!",
      review: "Having asked for assistance more than once, I can say that it is quite good so far! My only critique is that they still have some things to improve as they do struggle with some responses to some of the questions you may have. I know, some tasks are complex, so they have to train themselves too as they assist you with your requests and provide explanations. All in all, they are good!"
    },
    {
      name: "Megan M",
      score: "5",
      date: "15 October, 2020",
      title: "Hesitant for the first time, excited for the second time!",
      review: "After sending the instructions to them, I could not be happier! They met the deadlines EARLY and were very easy to work with, I look forward to using them again!"
    },
    {
      name: "Edwin Yong",
      score: "5",
      date: "19 October, 2020",
      title: "They gave me what I needed",
      review: "The notes are written with full details and the Nerd perfectly analyzed answers for all my questions."
    },
    {
      name: "Eddie Gotay",
      score: "5",
      date: "18 October, 2020",
      title: "Very reliable company - they got your back.",
      review: "This place is the best  - they took care of me from the first time I texted and updated me until the very last day of the project. They did a great job and I will definitely come back for more and recommend it to all my friends and family. Thank you!"
    },
    {
      name: "MAURICE",
      score: "5",
      date: "16 October, 2020",
      title: "Honestly, I really thought it was kinda sketchy",
      review: "Honestly, I really thought it was kinda sketchy because you have to pay first before you get the service but it was a smart decision for me to make and buy it. Also, the prices are really good."
    },
    {
      name: "Jones",
      score: "5",
      date: "13 October, 2020",
      title: "Very impressed with the good service and…",
      review: "Very impressed with the good service and the quick response I get from this website. Amazing! Thanks for your help. You are the best!"
    },
    {
      name: "Shobha Palreddy",
      score: "3",
      date: "10 October, 2020",
      title: "I have been using Nerdify for over a year…",
      review: "I have been using Nerdify over a year but in the past one month I tried to reach them and I haven't heard back or just got random codes.",
      nerdify: "Hey, Shobha! There must have been some kind of a tech glitch, please request a textback on our website once again:)"
    },
    {
      name: "Nene Rawls",
      score: "5",
      date: "10 October, 2020",
      title: "Customer service is always friendly and…",
      review: "Customer service is always friendly and professional. Meets all my requirements on time."
    },
    {
      name: "Nicole Cruz",
      score: "5",
      date: "7 October, 2020",
      title: "Very professional",
      review: "Very professional! I struggle with philosophy and work full time, I did not have enough time to understand the concepts and they helped me a lot. I recommend Nerdify 100%!"
    },
    {
      name: "Olawunmi Olanrewaju",
      score: "5",
      date: "6 October, 2020",
      title: "Good job and good customer service",
      review: "Nerdify is the best website to get help with college stuff! Thank you guys so much!"
    },
    {
      name: "Tonisha Smith",
      score: "5",
      date: "5 October, 2020",
      title: "BEST SERVICE BY FAR!!!",
      review: "BEST SERVICE BY FAR!!!! Fast, precise, very understanding, and trustworthy. I have used Nerdify for quite some time and all I will say is: they never FAILED to meet my expectations! THANKS, NERDIFY!"
    },
    {
      name: "Ally Marroquin",
      score: "5",
      date: "3 October, 2020",
      title: "My experience was amazing",
      review: "My experience was amazing! They respond quickly, understood exactly what I wanted, and I will definitely be coming back soon."
    },
    {
      name: "Student",
      score: "5",
      date: "30 September, 2020",
      title: "Good",
      review: "Good work and great customer service!"
    },
    {
      name: "Erica Long",
      score: "1",
      date: "24 September, 2020",
      title: "No 5 stars for them",
      review: "I asked for additional formatting and they did not follow the instructions.",
      nerdify: "Dear Erica, I am so sorry that you were not satisfied with the formatting your Nerd provided. Our QA team will investigate your case and make sure this will not happen again. Meanwhile, please stay aware that the full refund was initiated to your payment method. Please expect your money back in 3-5 business days."
    },
    {
      name: "Red",
      score: "5",
      date: "24 September, 2020",
      title: "Great work",
      review: "Great work. Very reachable if you have questions. I would definitely recommend this to anyone who is super busy and needs a bit of help, or if you just can't keep up with the workload."
    },
    {
      name: "Melanie",
      score: "5",
      date: "22 September, 2020",
      title: "My friend suggested this website…",
      review: "My friend suggested this website because I failed my course. They are very professional and great to work with. I am pleased with their services."
    },
    {
      name: "Bryan Torres",
      score: "5",
      date: "21 September, 2020",
      title: "Quality for better prices",
      review: "Yo, this website is fire. It will solve all your problems.  Good quality work for really good prices. "
    },
    {
      name: "Desire",
      score: "5",
      date: "19 September, 2020",
      title: "I have had a great experience",
      review: "I have had a great experience! But I  would like for the Nerd assigned to read the course content more thoroughly. Any issues I have had were immediately addressed and solved!!!"
    },
    {
      name: "Anonymous",
      score: "5",
      date: "11 September, 2020",
      title: "Safe. Friendly. Knowledgeable",
      review: "They are friendly and have staff that is knowledgeable in all subjects. I trust them completely. Don't hesitate to use Nerdify."
    },
    {
      name: "Vivian Herrera",
      score: "5",
      date: "8 September, 2020",
      title: "Trustworthy and worth your time",
      review: "Everyone has those weeks where it’s just hard to get to everything at once. I needed assistance with one of my classes and I was desperate. I found Nerdify and gave it a shot, and I’m SO glad I did! It’s not a scam and is totally trustworthy with reasonable prices. For sure will be coming back!"
    },
    {
      name: "Dana Uncu",
      score: "5",
      date: "7 September, 2020",
      title: "I like working with them because it's…",
      review: "I like working with them because it's easy. You can text your task and a representative will answer you right away. They will find you a Nerd to help with your task very fast, compared to other companies that I worked with in the past. They also have an app that makes everything very easy. You can track the progress. I also like the fact that they can review the Nerd's work and make the necessary changes at no extra cost. I'm very happy I found this company and would recommend it to anyone who's looking for this type of service."
    },
    {
      name: "Sharon",
      score: "5",
      date: "4 September, 2020",
      title: "They rock!",
      review: "Using Nerdify for the first time I was a bit skeptical but decided to give it a go. I was incredibly pleasantly surprised as they helped me out greatly with my college task! I would highly recommend them to all my friends and colleagues!"
    },
    {
      name: "Maysa",
      score: "5",
      date: "26 August, 2020",
      title: "I highly",
      review: "I highly recommend Nerdify: great and fast service 24/7, very professional. Just a quick message and you will get the best help with any task!"
    },
    {
      name: "Eb",
      score: "5",
      date: "24 August, 2020",
      title: "The “Nerds” are LIFESAVERS",
      review: "The “Nerds” are LIFESAVERS! Hopefully, I won’t fall behind again with my course, but if I do...NERDIFY, here I come!"
    },
    {
      score: "5",
      name: "Ismael Gonzalez",
      title: "I was really skeptical about trusting the service at first",
      review: "I was really skeptical about trusting them to help with my task that I had already failed once. But thank God these people aren’t scammers and I can honestly say I’m very satisfied and will use them again in the near future! They text back almost instantly and answer all questions. Happy I gave them a chance!",
      nerdify: "",
      date: "19 August, 2020"
    },
    {
      score: "5",
      name: "Tanairis Hernandez",
      title: "Great experience with Nerdify",
      review: "I had a great experience with Nerdify. They were very professional and very fast with helping me out. Great job and great company.",
      nerdify: "",
      date: "3 August, 2020"
    },
    {
      score: "3",
      name: "Dianne G.",
      title: "Maybe it's good, but I did not have a chance to try",
      review: "How come that the service that claims to text back in seconds did not text me in the whole two hours!?",
      nerdify: "Dear Dianne, we have experienced a tech glitch recently which resulted in not being able to text a certain amount of our clients. Everything is fixed now and we assure that once you contact us again we will reply asap!",
      date: "29 July, 2020"
    },

    {
      score: "5",
      name: "Yoshe",
      title: "Perfect job done right on time!",
      review: "I would say: perfect job done on the time, good results, professional tutors, also perfectful customer service that is available 24/7! It was a perfect experience, nice job guys!",
      nerdify: "",
      date: "26 July, 2020"
    },
    {
      score: "5",
      name: "Lisa",
      title: "Nerdify is absolutely the best!",
      review: "I have been using Nerdify for the last 3 weeks and I actually love them! My tasks are done in a timely manner! If you are juggling work and school definitely book Nerdify! You won’t regret it:-)",
      nerdify: "",
      date: "16 July, 2020"
    },
    {
      score: "5",
      name: "Tee",
      title: "Nerdify is just great!",
      review: "Nerdify is great not only in supplying great work but great prices. They are quick to respond with requests and are typically done with tasks ahead of allowed time. Highly recommend them!!!",
      nerdify: "",
      date: "4 July, 2020"
    },
    {
      score: "5",
      name: "None",
      title: "Awesome service",
      review: "Nerdify is an excellent service for me! Very professional Nerds get your request done on time when needed. They would work with you every step of the way with any problems you are having.",
      nerdify: "",
      date: "22 June, 2020"
    },
    {
      score: "5",
      name: "DoubleKmama",
      title: "Nerdify is my lifesaver!",
      review: "If you're thinking about it, just go for it. I second-guessed it but the results I’ve gotten are amazing. Helped me understand my class! The Nerds are very smart and they answer texts all day. I’m grateful for their help. Go for it!",
      nerdify: "",
      date: "14 June, 2020"
    },
    {
      score: "5",
      name: "Courtney Oubre",
      title: "I am very satisfied with them!",
      review: "I am very satisfied with my task! The person who helped me with it was great. I plan to continue to use this website and will recommend them to anyone who struggles with classes, because they always have my back! Thanks again.",
      nerdify: "",
      date: "8 June, 2020"
    },
    {
      score: "5",
      name: "Shake AB",
      title: "Very quick and easy",
      review: "Very quick and easy. Just message them on messenger and all will be sorted 😍",
      nerdify: "",
      date: "22 May, 2020"
    },
    {
      score: "2",
      name: "Kevin Le",
      title: "No sure if I love it or hate it",
      review: "Don't get me wrong, Nerdify is awesome for what they do. But the Nerds you will get? It is a hit or miss. I used this site many times, and spent a lot of money on them, but not sure if I will be using this again as my Nerd failed me with my last task.",
      nerdify: "Dear Kevin, unfortunately, we were unable to identify you in our client management system. Could you be so kind to specify any order number or the email you used? The situation that you brought to our attention is of great importance for us to investigate. I assure you that we will put effort into analyzing your order history and experience to prevent such cases in the future.",
      date: "18 May, 2020"
    },
    {
      score: "5",
      name: "Liz",
      title: "Was really scared to proceed, but so happy I did!",
      review: "Honestly, I was so scared to proceed but the representative was so helpful. Everything went smoothly. 10/10",
      nerdify: "",
      date: "13 May, 2020"
    },
    {
      score: "5",
      name: "Ibis Rodriguez",
      title: "Super helpful",
      review: "Super helpful! To be honest they are awesome, assistance is always great and on time. Nerdify has helped me so much with my college needs, and I will continue using them forever and ever, lol!",
      nerdify: "",
      date: "8 May, 2020"
    },
    {
      score: "5",
      name: "Adel Serhan-Rodriguez",
      title: "Love Nerdify",
      review: "Hey, Nerdify is great. I love using their service. It's fair and if there is an issue they fix it right away - highly recommended. Use them!",
      nerdify: "",
      date: "6 May, 2020"
    },
    {
      score: "5",
      name: "Ariel Smith",
      title: "The service was impeccable.",
      review: "Guys are understanding and handled my task with no problems. They asked important questions to get the editing in a timely fashion.",
      nerdify: "",
      date: "1 May, 2020"
    },
    {
      score: "5",
      name: "Annie Duong",
      title: "Extremely good quality service!",
      review: "Extremely good quality service! If you aren't satisfied with the result, giving them a bit of extra time and some guidelines on what you want will get you what you want. They are always so polite and I've been using Nerdify for years.",
      nerdify: "",
      date: "26 April, 2020"
    },
    {
      score: "5",
      name: "Nana",
      title: "I am so grateful for their help",
      review: "I am so grateful for your help. I was going through so much anxiety wondering how I was going to do this task, but Nerdify really saved me. I was so surprised with the final results. I could not be more satisfied. Nerdify is amazing!! I will definitely be going to them again.",
      nerdify: "",
      date: "23 April, 2020"
    },
    {
      score: "5",
      name: "Ross Whitlock",
      title: "I was skeptical about this website when I…",
      review: "I was skeptical about this website when I first used it, but the results turned out to be amazing. They are very good when it comes to communicating and are very helpful.",
      nerdify: "",
      date: "19 April, 2020"
    },
    {
      score: "5",
      name: "Laura M.",
      title: "Legit service and worth your time!",
      review: "This is my first time using this website and I've never been so relieved that I found a legit website to use. They're very professional, really kind, and they provide progress tracking. I highly recommend for people to give them time and book an appointment beforehand. It's less stress on everyone and it gives you more free time. Overall I'm just really impressed and I'll definitely be using this website for more work.",
      nerdify: "",
      date: "15 April, 2020"
    },
    {
      score: "5",
      name: "Tammy D",
      title: "AMAZING!",
      review: "Honestly, they did great quality work! If you have anxiety - don’t worry, because they will literally answer all of your questions in a timely manner!",
      nerdify: "",
      date: "10 April, 2020"
    },
    {
      score: "3",
      name: "Maya Duncal",
      title: "No Nerd for me:(",
      review: "I hired Nerdify and made sure to be very specific with all my instructions. They failed to provide me the Nerd in the next few hours. I did email the company and  received a proper refund, though.",
      nerdify: "Dear Maya, we are terribly sorry that you've had such an experience. You needed a Nerd with a very rare qualification and the ones that we have were unavailable due to the heavy workload. We hope you give us a second chance!",
      date: "4 April, 2020"
    },
    {
      score: "5",
      name: "Freida Mee",
      title: "Professional service",
      review: "I decided to try Nerdify thanks to a recommendation from a friend. Everything was done according to terms. I will continue to use them and send out recommendations to other friends.",
      nerdify: "",
      date: "1 April, 2020"
    },
    {
      score: "5",
      name: "Brandon Wallace",
      title: "Amazing Work!!! Highly Recommended",
      review: "Nothing in life is perfect and neither are they - but they are damn sure close to perfection. I've had a dozen of tasks and 11 of them were perfect and 1 wasn't, but they refunded my money and gave me an option to revise it for free if I wanted. They have excellent response time, amazing customer service and do good quality work whenever you need it. I would definitely recommend this company: they are legit and the real deal.",
      nerdify: "",
      date: "29 March, 2020"
    },
  ],
};

export default trustpilot;
