const google = {
  google: [
    {
      "name": "Helen Soleimanirad",
      "score": "5",
      "date": "1 week ago",
      "review": "This is the third time I've received help from the Nerdify team. They answer very fast and really want to help. Last time, James helped me with my task—he was professional, polite, and knowledgeable. I'm so glad I found Nerdify! 😍",
      "nerdify": "Thank you for your trust, Helen! We appreciate your positive review and look forward to helping you more in the future :)"
    },
    {
      "name": "Desy Gato",
      "score": "5",
      "date": "2 weeks ago",
      "review": "I’ve had a good experience with Nerdify so far. The guidance I received helped me earn an A on my task. The assistance was right on time, so I managed to meet my deadline. ",
      "nerdify": "Desy, thank you so much for sharing your positive experience with us!"
    },
    {
      "name": "Annani Sophia Kelley",
      "score": "5",
      "date": "2 weeks ago",
      "review": "I have used Nerdify twice, and they provided wonderful assistance each time. I highly recommend them!",
      "nerdify": "Thank you, Annani! We're delighted to hear that we met your expectations!"
    },
    {
      "name": "Shawinna Patel",
      "score": "5",
      "date": "3 weeks ago",
      "review": "The Nerdify service comes in handy when you're stuck on a task. It's incredibly convenient and the level of service provided is truly awesome! Additionally, the team at Nerdify is highly knowledgeable and dedicated to helping you succeed. Thank you😊",
      "nerdify": "Thank you for your kind words, Shawinna! Your positive feedback means a lot to us!"
    },
    {
      "name": "Tynia Levesy",
      "score": "5",
      "date": "a month ago",
      "review": "I was fortunate to work with the best—James and Anna responded in seconds! Nerdify offers an excellent professional service. Don’t hesitate to reach out. I'm feeling so relieved since I've found them!",
      "nerdify": "Tynia, thank you, your kind words motivate us to keep delivering our best!"
    },
    {
      "name": "Mia Davis",
      "score": "5",
      "date": "a month ago",
      "review": "Nerdify is very outstanding service. I appreciate them so much, especially since they were able to help me at the very last minute. I'm satisfied with their assistance. ",
      "nerdify": "Mia, thanks for the great feedback!"
    },
    {
      "name": "Megan Tenney",
      "score": "5",
      "date": "2 months ago",
      "review": "I needed help with the project and the assistance I received was perfect, efficient, and fast! Good pricing as well. Thank you!",
      "nerdify": "Thank you for taking the time to share your experience with us, Megan!"
    },
    {
      "name": "Gloria Udsen",
      "score": "5",
      "date": "2 months ago",
      "review": "I'm very pleased with Nerdify. They alwya provide quite detailed feedback on any subject. Highly recommend 💯",
      "nerdify": "Gloria, your satisfaction is our top priority, and we greatly appreciate your recommendation :)"
    },
    {
      "name": "Esther Salisbury",
      "score": "5",
      "date": "2 months ago",
      "review": "Love Nerdify! The customer service is outstanding and Anna is awesome! Thanks to the Nerd's guidance I got a good grade on my task. I have no complaints and I’ll be using them again :)",
      "nerdify": "Thank you so much for your wonderful feedback, Esther! We look forward to helping you again in the future :)"
    },
    {
      "name": "Prathyusha Reddy",
      "score": "5",
      "date": "2 months ago",
      "review": "So good! Anna was very attentive and responsive. My Nerd understood the problem set well and showed me how to solve it. Thank you!",
      "nerdify": "Prathyusha, thank you, it's great to know we met your expectations!"
    },
    {
      "name": "Patrick Brandon",
      "score": "5",
      "date": "3 months ago",
      "review": "This is an amazing service! Communication back and forth was top notch. Anything that needed attention was addressed quickly. Will continue to use them over and over again.",
      "nerdify": "Thank you, Patrick! We appreciate your positive feedback :)"
    },
    {
      "name": "Rosetta Billingslea",
      "score": "5",
      "date": "4 months ago",
      "review": "Nerdify provided me with excellent, professional and speedy service. I will definitely be using them again in the near future and will recommend them to others.",
      "nerdify": "Thank you for the great review, Rosetta!"
    },

    {
      "name": "Patrick Brandon",
      "score": "5",
      "date": "5 months ago",
      "review": "This is an amazing service! Communication back and forth was top notch. Minor edits were fixed very quickly. Will continue to use Nerdify over and over again.",
      "nerdify": "Thank you, Patrick! Should you need any further assistance, please don't hesitate to reach out :)"
    },
    {
      "name": "Helly Morgan",
      "score": "5",
      "date": "5 months ago",
      "review": "Love Nerdify and absolutely would recommend it to everyone because they are so amazing with complex tasks! They follow every guideline given and go above and beyond to assist you in the best way possible. Thank you, Nerdify!",
      "nerdify": "Thank you, Helly! It’s our pleasure to help you with your studies! "
    },
    {
      "name": "Shantelle Branam",
      "score": "5",
      "date": "6 months ago",
      "review": "Nerdify really helped me out so much with my project! I was able to complete the task quickly and correctly with their assistance! I am very grateful for the helpful and friendly service!",
      "nerdify": "Shantelle, thank you for your kind words! "
    },
    {
      "name": "Vikram Manishankar",
      "score": "5",
      "date": "7 months ago",
      "review": "Nerdify helped me to complete the task accurately and in a timely manner. I was very satisfied with how professional they were. I highly recommend using this platform in the future.",
      "nerdify": "Vikram, your satisfaction is our top priority. If you ever need assistance again, we'll be more than happy to help :)"
    },
    {
      "name": "Alyssa Bentz",
      "score": "5",
      "date": "7 months ago",
      "review": "I’ve had Nerdify assist me with two of my Math tasks so far and both times have been extremely helpful. They are a reputable website and have a great response time :)",
      "nerdify": "Hey, Alyssa! We appreciate your positive review and your trust in us!"
    },
    {
      "name": "Alex Strong",
      "score": "5",
      "date": "7 months ago",
      "review": "Nerdify did an incredible job helping me complete a complex task. I had a tight deadline but they made sure I get the assistance I need right on time. I couldn’t recommend this service enough!",
      "nerdify": "Alex, thank you so much, for taking the time to share your positive experience!"
    },
    {
      "name": "Christina Kilby",
      "score": "5",
      "date": "7 months ago",
      "review": "Wonderful experience! I needed quick help with Physics and I got it! Nerdify provided me with clear and concise explanations of the process involved in solving my problems!",
      "nerdify": "Thank you, Christina! Share this experience with friends via your Nerdify referral link and get bonuses :)"
    },
    {
      "name": "Melinda Mandujano",
      "score": "5",
      "date": "8 months ago",
      "review": "Amazing service! I was in a pinch and was really hoping for this to go smoothly. Everything exceeded my needs and expectations! I'm 100% happy with the help they provided, it was exactly what I asked for! Thanks to Nerdify I was able to catch a small break, so I will recommend them to everyone I know. No issues whatsoever!",
      "nerdify": "Thank you for your kind words, Melinda! We're delighted that Nerdify was able to meet your expectations."
    },
    {
      "name": "Theresa Jordan",
      "score": "5",
      "date": "8 months ago",
      "review": "Absolutely professional work! The extent of time management is off the charts! You won't regret it!",
      "nerdify": "Theresa, thank you, we’re happy that you loved your Nerdify experience :)"
    },
    {
      "name": "Anjana Kushwaha",
      "score": "5",
      "date": "9 months ago",
      "review": "My experience so far has been very good. Thanks to Nerdify my Algebra knowledge became much better. I couldn't have done it by myself.",
      "nerdify": "Thanks for your feedback, Anjana! We're happy to help :)"
    },

    {
      name: "Rowan Walker",
      score: "5",
      date: "10 months ago",
      review: "I'm so excited! Nerdify helped me create a detailed plan for my marketing research. It made my project well-structured and manageable. I'm sure I'll get a good grade with it!",
      nerdify: "Thank you, Rowan!"
    },
    {
      name: "Anaya Gray",
      score: "5",
      date: "10 months ago",
      review: "Lifesavers! Nerdify saved me countless times. They are coming to the rescue every time I need help with preparation for my history classes and I can't thank them enough.",
      nerdify: "Thank you, Anaya!"
    },
    {
      name: "Terence Judkins",
      score: "5",
      date: "10 months ago",
      review: "I had an issue with advanced statistics questions and I needed a consultation in a tight time frame. They found a Nerd very quickly and he explained to me everything I needed.",
      nerdify: "Thank you, Terence!"
    },
    {
      name: "Emilio Muzquiz",
      score: "5",
      date: "11 months ago",
      review: "I had a very good experience with Nerdify! Highly recommended!",
      nerdify: "Thank you, Emilio!"
    },
    {
      name: "Rihanna Anderson",
      score: "5",
      date: "11 months ago",
      review: "At first, I was skeptical, but once I started the process it was easy and I got the help I needed from my Nerd. I'd recommend this app.",
      nerdify: "Thank you, Rihanna!"
    },
    {
      name: "Val Jefferson",
      score: "5",
      date: "11 months ago",
      review: "Couldn't be happier with the results! They had helped me when I most needed it. The team's expertise and dedication truly shone through, and I couldn't have asked for a better experience. Thank you for your outstanding assistance!",
      nerdify: "Thank you so much, Val! We're thrilled to know you loved your Nerdify experience :)"
    },
    {
      name: "Kristina Eaton",
      score: "5",
      date: "a year ago",
      review: "James was my tutor. I appreciate all his hard work. The service exceeded my expectations. I highly recommend it and will use it again in the future. Thanks.",
      nerdify: "Kristina, thank you for the review! We'll say your thanks to James too :)"
    },
    {
      name: "Olivia Steiman",
      score: "5",
      date: "a year ago",
      review: "Friendly and super smart people help you with studies. Highly recommended!",
      nerdify: "Thank you, Olga ;)"
    },
    {
      name: "Lisa Lilac",
      score: "5",
      date: "a year ago",
      review: "I personally use the premium service at Nerdify and I'm satisfied! They answer me extremely fast and help every time. As a college student having this service to help me has definitely been beneficial. As well as this, I feel that the assistance I get is always professional and the Nerd's explanations help me learn how to do it myself!",
      nerdify: "Thank you so much for your kind review, Lisa!"
    },
    {
      name: "Taylor McCain",
      score: "5",
      date: "a year ago",
      review: "Quick, professional, and very helpful!",
      nerdify: "Thanks a lot, Taylor!"
    },
    {
      name: "Shaffika Tannous",
      score: "5",
      date: "a year ago",
      review: "The best help I could get with my studies! Will be back soon!",
      nerdify: "Thanks, Shaffika, don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Kaye Mayers",
      score: "5",
      date: "a year ago",
      review: "Nerdify is one of the best tutoring services available. Their service is truly exceptional. They provide step-by-step explanations which makes it easier to understand. They are professional, friendly, reliable, and have a fast response time. We would recommend this service to others.",
      nerdify: "Thank you so much, Kaye! <3"
    },
    {
      name: "Béni Halász",
      score: "5",
      date: "a year ago",
      review: "Nerdify is the best in business. They are always fast, precise, and reliable. Very easy to work with them because they respond in minutes and start working on tasks asap!:)",
      nerdify: "Thanks, Beni! Don’t hesitate to contact us if you need more help!"
    },

    {
      name: "April Totten",
      score: "5",
      date: "a year ago",
      review: "I reached out to Nerdify when I needed help with my History class. My Nerd assisted me with preparing notes and research plan for my task. With his help I finished the research on time and found time for going to the movies.",
      nerdify: "April, thanks for the review! We're glad we could help you :)"
    },
    {
      name: "Erika Yanez",
      score: "5",
      date: "2 years ago",
      review: "Great service and superior customer support!",
      nerdify: "Thank you, Erika!"
    },
    {
      name: "Miranda Rys",
      score: "5",
      date: "2 years ago",
      review: "Great experience! Nerdify is very helpful!",
      nerdify: "Thank you, Miranda! :)"
    },
    {
      name: "Jannatul Tithe",
      score: "5",
      date: "2 years ago",
      review: "Wonderful experience! Nerd answered all my questions and explained to me the basics of Statistics. Thanks to it I completed my task on time.",
      nerdify: "Thanks for your feedback :)"
    },
    {
      name: "Voloji Fanton",
      score: "5",
      date: "2 years ago",
      review: "They are very helpful and understanding. Their prices are quite fair and reasonable. 10/10. I would recommend Nerdify to everyone.",
      nerdify: "Thanks, we're glad to know you're satisfied with your Nerdify experience!"
    },
    {
      name: "Winston Cesear",
      score: "5",
      date: "2 years ago",
      review: "Excellent work! They are very professional and diverse in their skill set. I would definitely use Nerdify again.",
      nerdify: "Thank you, Winston!"
    },

    {
      name: "Sean Echeverri",
      score: "5",
      date: "2 years ago",
      review: "Good job! My Nerd helped me to collect data for my research and with that information I prepared a great project. I'm so happy I used this app!",
      nerdify: "Thanks, Sean!"
    },
    {
      name: "Emma Ruth Lopez",
      score: "5",
      date: "2 years ago",
      review: "Quick responses and the service is really exceptional!",
      nerdify: "Thank you, Emma!"
    },
    {
      name: "Naey Johnson",
      score: "5",
      date: "2 years ago",
      review: "My experiences with Nerdify have been great. Their customer support and the response times are on point along with prices. I have no complaints at the moment so, that’s a plus!",
      nerdify: "Thank you for your great review, Naey! :)"
    },
    {
      name: "Stacey Adams",
      score: "5",
      date: "2 years ago",
      review: "Awesome experience! They answered really fast and helped me with everything I asked for. Highly recomended!",
      nerdify: "Thank you, Stacey!"
    },
    {
      name: "Wendy Rios",
      score: "5",
      date: "2 years ago",
      review: "Unparalleled service. Timely and very efficient. Nerdify exceeded my expectations and I am forever grateful for their service.",
      nerdify: "Thanks, Wendy! We're always here to help!"
    },
    {
      name: "Dominic Barnwell",
      score: "5",
      date: "2 years ago",
      review: "I just started using Nerdify, and I'm satisfied with this service so far! All the Nerds are doing an extraordinary job. The quick responses and feedback I got every time were so beneficial and reassuring. Anne was the one who helped me with my task, and she did a phenomenal job! She was welcoming, genuine, and extremely helpful! Nerdify's service is fantastic, and I was satisfied with my task.",
      nerdify: "Dominic, thanks for your feedback, we appreciate it :)"
    },
    {
      name: "Luis Llanes",
      score: "5",
      date: "2 years ago",
      review: "Amazing service!",
      nerdify: "Thanks for your amazing feedback, Luis!"
    },
    {
      name: "Ida Mteulwa",
      score: "5",
      date: "2 years ago",
      review: "Thanks to my Nerd I completed my task on time and got good results :)",
      nerdify: "Thank you <3"
    },
    {
      name: "Olori Aminat Fayanmira",
      score: "5",
      date: "2 years ago",
      review: "These guys are good. I can recommend them. Nice job, Nerdify!",
      nerdify: "We appreciate your positive feedback, Olori. Thank you"
    },
    {
      name: "Renee Miles",
      score: "5",
      date: "2 years ago",
      review: "Great experience. A major help with data collection for my coursework. Exactly what I needed.",
      nerdify: "Thanks, Renee! We're always here to help :)"
    },
    {
      name: "Hope Harrington",
      score: "5",
      date: "2 years ago",
      review: "Amazing work and really easy to use.",
      nerdify: "Thanks for your amazing review!"
    },

    {
      name: "Dave Latchman",
      score: "5",
      date: "2 years ago",
      review: "I love Nerdify. They are very polite and quickly reply to any questions. They complete the tasks on the agreed time.",
      nerdify: "Thank you, Dave! We always do our best to help :)"
    },
    {
      name: "Melody Bony",
      score: "5",
      date: "2 years ago",
      review: "My communication with Nerdify from beginning to end was amazing! I’ve received overall great customer service and quick feedback from my Nerd and task manager. The communication was clear and effective. I've bought a premium subscription as a result and will continue to use this service.",
      nerdify: "Thank you for the amazing review! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks! ;)"
    },
    {
      name: "Morgan Appel",
      score: "5",
      date: "2 years ago",
      review: "Don’t get me wrong, but I'm thankful to these guys for helping me boost my study results. Their support helped me to pull myself together and get things done. I understood my Math course better which helped me to complete it and graduate. I will recommend this service to everyone.",
      nerdify: "Thanks for your feedback, Morgan!"
    },
    {
      name: "Faila Theodord",
      score: "5",
      date: "2 years ago",
      review: "They are my lifesavers! Thanks for helping me out!",
      nerdify: "Thank you so much, Faila! <3"
    },
    {
      name: "Ava Kayes",
      score: "5",
      date: "2 years ago",
      review: "Nerdify is one of the best websites I have used. Due to health issues, I had some problems with my study process, so I reached out to a few websites that didn’t help me. Then I reached out to Nerdify and they did help! They not only provided me with guidelines and examples that helped me to complete my tasks, but also they were very kind to me, and offered me a discount too, just to help me out. I completed everything on time. I can’t thank them enough. Thank you, guys!",
      nerdify: "Thank you, Ava! We're thrilled to know you're satisfied with your Nerdify experience!"
    },
    {
      name: "Catherine Cornwell",
      score: "5",
      date: "2 years ago",
      review: "Nerdify is an extremely helpful service for students. Communication is personalized, timely, and specific. Great job!",
      nerdify: "Thank you so much for your kind words, Catherine! <3"
    },
    {
      name: "Laylo Makhkamdjanova",
      score: "5",
      date: "2 years ago",
      review: "You did amazing. At first, I was scared that it was just a scam. But when I opened the email with the completed task I was impressed. The service is absolutely wonderful. Very fast, efficient, and concise. Exactly what I needed.",
      nerdify: "We're happy that you had a wonderful Nerdify experience, Laylo! Thanks for your feedback! <3"
    },
    {
      name: "Samantha Cavaliere",
      score: "5",
      date: "2 years ago",
      review: "Honestly, my experience is 10/10. Very helpful when I was struggling with my task and was overwhelmed. The quality of work is worth the price. And they are a very good place when you need help with your studies.",
      nerdify: "We’re so happy you’re satisfied with our services, Samantha!"
    },
    {
      name: "Ciara Myers",
      score: "5",
      date: "2 years ago",
      review: "I used this platform to get assistance with my Math tasks. Nerds were absolutely amazing and answered my questions efficiently, and most importantly helped me to understand Math! I highly recommend Nerdify to anyone truly struggling to complete any tasks in a timely matter. Great service!",
      nerdify: "Thank you so much, Ciara! We're always here to help you!"
    },
    {
      name: "Shalanda Kettle",
      score: "5",
      date: "2 years ago",
      review: "I used Nerdify for help with a task I was having trouble completing and they were so great in communicating back and forth with me. They were quick and did everything I asked of them. The customer service was great. I will definitely be using Nerdify again!",
      nerdify: "We’re so happy you’re satisfied with our services, Shalanda! Share this experience with friends via your referral link and get bonuses!\n"
    },
    {
      name: "Yamilex Rivero Ventura",
      score: "5",
      date: "2 years ago",
      review: "I was struggling with my task and my Nerd guided me through it step-by-step. Thanks for your help!",
      nerdify: "Thank you! <3"
    },
    {
      name: "Toyosi Obasoyin",
      score: "5",
      date: "2 years ago",
      review: "The Nerds are awesome! With their help and guidelines I managed to prepare my project on time. They provide really great services.",
      nerdify: "Thank you!"
    },
    {
      name: "Tony Fives",
      score: "5",
      date: "2 years ago",
      review: "Nerds provided me excellent detailed notes for my project. They gave me necessary directions and explained all the pitfalls. I will definitely use Nerdify again.",
      nerdify: "Thank you for the excellent review! Don’t forget that you can invite your friends to Nerdify via a referral link and get bonuses for your next tasks!"
    },
    {
      name: "Sara Gordy",
      score: "5",
      date: "2 years ago",
      review: "The experience was great! They completed a task on time! I liked the result and it was worth the money and wait time. Thanks again!!!",
      nerdify: "Thanks for your great review, Sara! <3"
    },
    {
      name: "Sirena Spotter",
      score: "5",
      date: "2 years ago",
      review: "Great service! Quick response time and quality of work. Definitely a service I would use again. The agent I spoke with via text was very helpful and made the whole process super simple. Highly recommended!",
      nerdify: "We appreciate your feedback! Thanks for your kind words!"
    },
    {
      name: "Dawn Allen",
      score: "5",
      date: "2 years ago",
      review: "Great experience. Nerds provided me with detailed notes explaining how to solve my mathematical problem. I didn’t think I would ever do it myself. They provided me with the opportunity to see what I was doing wrong. Very well done and thank you so much!",
      nerdify: "Dawn, thank you so much! Don't hesitate to contact us if you need more help!"
    },
    {
      name: "Sonia Gamboa",
      score: "5",
      date: "2 years ago",
      review: "Nerds are very friendly. They text back so fast and the pricing is reasonable. They helped me to complete my task by the due date. I am impressed with this experience.",
      nerdify: "We're glad we could help you, Sonia! Don't hesitate to contact us if you need more help!"
    },
    {
      name: "Luly Serrano",
      score: "5",
      date: "2 years ago",
      review: "I had the best experience with Nerdify. They made me feel confident throughout the whole process. They communicated with me and tried to work on finding out the best way to help me. I will definitely keep coming back to them.",
      nerdify: "Thank you for your review, Luly! We’re over the moon to hear that you’re happy <3"
    },
    {
      name: "Anallely Montero",
      score: "5",
      date: "2 years ago",
      review: "It’s an awesome help! I really recommend this app because they can help you with your college tasks. Thanks to it I managed to improve my study results.",
      nerdify: "Thank you, Anallely, for your awesome review!"
    },

    {
      name: "Beatriz Gonzalez",
      score: "5",
      date: "2 years ago",
      review: "Super easy process. Their services are provided with good quality and high professionalism. Great experience 👍",
      nerdify: "Thank you for taking the time to share your great experience with us, Beatriz! "
    },
    {
      name: "Ernest Adams",
      score: "5",
      date: "2 years ago",
      review: "I found Nerdify on Google when I was looking for help with my project. The information and guidelines Nerds provided were really useful. I will definitely use this service again!",
      nerdify: "Thank you for your excellent feedback!"
    },
    {
      name: "Abraham Koroma",
      score: "5",
      date: "2 years ago",
      review: "Nerdify is the most efficient study service that I ever used!",
      nerdify: "Thank you, Abraham!"
    },
    {
      name: "Justin Lacy",
      score: "5",
      date: "2 years ago",
      review: "I was very skeptical at first. However, the customer service is top notch and the services couldn’t be better.",
      nerdify: "We're happy you are satisfied with your experience, Justin!"
    },
    {
      name: "Charles Pond",
      score: "5",
      date: "2 years ago",
      review: "Very helpful and timely. Highly recommend!",
      nerdify: "Thanks, Charles!"
    },
    {
      name: "Maria Santana",
      score: "5",
      date: "2 years ago",
      review: "Best service ever! Very fast and very reliable. I’ve received all the necessary materials both times!",
      nerdify: "Happy to help you, Maria!"
    },
    {
      name: "Andi Rowe",
      score: "5",
      date: "2 years ago",
      review: "Very helpful!",
      nerdify: "Happy to hear back from you, Andi!"
    },
    {
      name: "Kadeem Brothers",
      score: "5",
      date: "2 years ago",
      review: "The best decision I ever made.",
      nerdify: "Written by the best client we ever had <3"
    },
    {
      name: "Nathaniel Bissonnette",
      score: "5",
      date: "2 years ago",
      review: "Well, I was suspicious in the beginning. I thought I'd spend my money on something that would give no results. Guess what, I spent my money on really high quality services which helped me with learning. I greatly recommend Nerdify to anyone who needs help with any type of task!",
      nerdify: "Thanks for your feedback, Nathaniel!"
    },
    {
      name: "Tyrese Baffour",
      score: "5",
      date: "2 years ago",
      review: "If you are looking for assistance with your projects, I recommend you to try Nerdify. These Nerds can explain all the problematic issues and help you with completing your tasks.",
      nerdify: "Thanks for your rating!"
    },
    {
      name: "Babatunde Moshood",
      score: "5",
      date: "2 years ago",
      review: "Best service, 100% guarantee.",
      nerdify: "Thanks!"
    },
    {
      name: "Andrea Bent",
      score: "5",
      date: "2 years ago",
      review: "I didn't have much time to prepare my project, that’s why I was looking for some help. I'm so glad I came across Nerdify. They gave me clear guidelines for my task and their help was great. I definitely recommend Nerdify.",
      nerdify: "Thank you so much, Andrea!"
    },
    {
      name: "Jamie Botma",
      score: "1",
      date: "2 years ago",
      review: "Nerds were very friendly, but they didn't help me to reach the results I expected. I'm not sure if I'll use them again.",
      nerdify: "Hi there, Jamie! Thank you so much for your feedback. We're sorry that you weren't satisfied with your results, but we hope you could give us a second chance."
    },

    {
      name: "Courtney Jones",
      score: "5",
      date: "2 years ago",
      review: "Quick responses. Helped with what's needed in a timely manner. Overall excellent work!",
      nerdify: "Thanks, Courtney!"
    },
    {
      name: "Kimberly Smith",
      score: "5",
      date: "2 years ago",
      review: "Happy with the service I have received. Nerdify is amazing!",
      nerdify: "Thanks, Kimberly!"
    },
    {
      name: "Clash Boy",
      score: "4",
      date: "2 years ago",
      review: "Hmm yeah, they are good 👍",
      nerdify: "Thank you!"
    },
    {
      name: "Whitney Marie",
      score: "5",
      date: "2 years ago",
      review: "Nerdify did an amazing job on my request. They were able to deliver within the same day! Price was great too! Definitely will be using them again!!!",
      nerdify: "Thanks, Whitney! Please don't forget to invite friends and earn referral bonuses you can use for future tasks!"
    },
    {
      name: "Renee Cunningham",
      score: "5",
      date: "2 years ago",
      review: "Help is timely and performed well.",
      nerdify: "Thank you!"
    },

    {
      name: "Mariam Beshy",
      score: "5",
      date: "2 years ago",
      review: "I like Nerdify. A great tool to have on hard days.",
      nerdify: "Thank you so much, Mariam!"
    },
    {
      name: "Aditya Yadav",
      score: "5",
      date: "2 years ago",
      review: "Amazing stuff. Nerdify helps you find perfect and on-time solutions. Thank you!",
      nerdify: "Thank you so much for your kind feedback!"
    },
    {
      name: "Armond Miranda",
      score: "5",
      date: "2 years ago",
      review: "Nerdify's customer service is so good that it's crazy. Amazing.",
      nerdify: "Thanks for appreciating our customer support, we are really proud of our team!\n"
    },
    {
      name: "Tam Leo",
      score: "5",
      date: "3 years ago",
      review: "I love how Nerdify's service gives me so much peace of mind! Thank you!",
      nerdify: "Thanks!"
    },
    {
      name: "Denakpon Gbegan",
      score: "4",
      date: "3 years ago",
      review: "They are prompt and helpful.",
      nerdify: "We'll be glad to help you further!"
    },
    {
      name: "Regina Capps",
      score: "5",
      date: "3 years ago",
      review: "I was very pleased with my experience. The whole process was easy and fast. They followed directions, and it was perfect! I would definitely use again.",
      nerdify: "Thanks, Regina! Hope you also got a chance to invite friends to Nerdify and earn bonuses for future tasks!"
    },
    {
      name: "Valerie Turner",
      score: "5",
      date: "3 years ago",
      review: "Nerdify provides impeccable services. They make your request a top priority and help you gain clarity when it comes to the most challenging problems in any course. If you need help, do not hesitate to get connected with them.",
      nerdify: "Thanks for your kind words, Valerie!"
    },
    {
      name: "Kenneth Fields",
      score: "5",
      date: "3 years ago",
      review: "Glad I found Nerdify. Makes life so much easier.",
      nerdify: "Thanks!"
    },
    {
      name: "Suzette Hamilton Yearwood",
      score: "5",
      date: "3 years ago",
      review: "What can I say? Nerdify is a legit company that provides fast and reliable service to people like me. Recommended.",
      nerdify: "Thank you so much!"
    },
    {
      name: "Dion “Dion” Wan",
      score: "5",
      date: "3 years ago",
      review: "Outstanding customer service. They're very responsive and go beyond expectations.",
      nerdify: "Thank you, we'll keep up with the good work!"
    },
    {
      name: "Fabiola Lopez",
      score: "5",
      date: "3 years ago",
      review: "Nerdify is always there to help! Extremely quick to get back to you and provide excellent work!",
      nerdify: "Thanks!"
    },

    {
      name: "Dion \"Dion\" Wan",
      score: "5",
      date: "3 years ago",
      review: "Outstanding customer service. They are very responsive and go beyond expectations.",
      nerdify: "Thank you, we'll keep up with the good work!"
    },
    {
      name: "Fabiola Lopez",
      score: "5",
      date: "3 years ago",
      review: "Nerdify is always there to help! Extremely quick to get back to you and provide great work!",
      nerdify: "Thanks!"
    },
    {
      name: "Jeffery Driscoll",
      score: "5",
      date: "3 years ago",
      review: "Highly recommend this service. They are quick to reply, and quick to deliver. 100% professional and knowledgeable.",
      nerdify: "Thank you, hope to see you back soon!"
    },
    {
      name: "Joyce Ramos",
      score: "5",
      date: "3 years ago",
      review: "Amazing work! I appreciate how quickly you get a response and how helpful everyone is. Thank you, Nerdify 😊",
      nerdify: "Thanks, Joyce!"
    },
    {
      name: "Starli Maynards",
      score: "4",
      date: "3 years ago",
      review: "Nerdify is a really great service. However, there are times when the order requested is not top quality, which happens a few times. I believe it depends on the Nerd’s availability and stress factors. Overall, I appreciate the hard work and effort they put into the tasks, as they always ensure they meet the client’s needs.",
      nerdify: "Thanks for your honest feedback! We are working hard to make sure every client gets the top quality service and are striving to provide the best experience. Thank you so much for trusting us and giving a second chance, we'll not disappoint you!"
    },
    {
      name: "Kevin Marin",
      score: "5",
      date: "3 years ago",
      review: "No issues, just great service!",
      nerdify: "Thanks, Kevin!"
    },
    {
      name: "Navneet Kaur",
      score: "5",
      date: "3 years ago",
      review: "I have never seen anything compared to Nerdify. Their service is outstanding. Whenever I had a question, I received their response within seconds. They're very professional, they worked around my budget, and they got my request done on time. If you ever need help with tasks, don't think twice and use Nerdify. THANK YOU, NERDIFY TEAM ❤️❤️",
      nerdify: "Thanks for such amazing feedback!"
    },
    {
      name: "Dave Leavitt",
      score: "5",
      date: "3 years ago",
      review: "Nerdify is a great resource! Glad I found it.",
      nerdify: "Thanks, Dave!"
    },


    {
      name: "Mary Posen",
      score: "5",
      date: "3 years ago",
      review: "I've received prompt service. They are easy to work with, concise, and thorough. Very happy with everything.",
      nerdify: "Thank you, Mary!"
    },
    {
      name: "Jennifer Rojas",
      score: "5",
      date: "3 years ago",
      review: "Finally found somewhere to turn to when I'm in a pinch! Thanks, Nerdify!",
      nerdify: "Thanks, Jennifer!"
    },
    {
      name: "Kathia Quintanilla",
      score: "5",
      date: "3 years ago",
      review: "I loved the service! They completed my request on time, treated me well, and were trustworthy. It was a wonderful experience! 👌👌",
      nerdify: "Thanks for your feedback, Kathia!"
    },
    {
      name: "Kelly Dawson",
      score: "5",
      date: "3 years ago",
      review: "I had to share my experience with both accounts because Nerdify definitely deserves another rating! It was my first time receiving service from them, and they did not let me down. They were fast, prompt and very nice! I definitely would recommend to anyone!",
      nerdify: "Awww, that is so sweet. Thank you! <3"
    },
    {
      name: "Hawa Salima",
      score: "5",
      date: "3 years ago",
      review: "Can't thank Nerdify enough for the awesome service! Thank you!",
      nerdify: "Thank you!"
    },
    {
      name: "Luke Franklin",
      score: "5",
      date: "3 years ago",
      review: "I love that they kept me well-informed of the process and updated on what was going on. I love Nerdify!",
      nerdify: "Thanks!"
    },
    {
      name: "Cari Ferguson",
      score: "5",
      date: "3 years ago",
      review: "Never thought I'd find something as helpful as Nerdify online! Good find!",
      nerdify: "Thanks!"
    },
    {
      name: "Evelyn Pha",
      score: "5",
      date: "3 years ago",
      review: "I've had a great experience with Nerdify! Will use again.",
      nerdify: "Thanks for rating us, Evelyn!"
    },

    {
      name: "Lucas Tedesco",
      score: "5",
      date: "3 years ago",
      review: "It's not every day that you find a website that can be as reliable as Nerdify. Nerdify is truly awesome.",
      nerdify: "Thanks!"
    },
    {
      name: "Veyoncé Goldmine",
      score: "5",
      date: "3 years ago",
      review: "I've had many things that I was working on at the same time for school and my job, and I needed some help. I found Nerdify from a simple Google search on Saturday 04/09/22 at around 2 PM, and I got a swift response once I reached out. I sent over my requests and made a payment of $114, which was a great price for the two orders that were due the next night. They delivered before the due date and I was satisfied and relieved because Nerdify came through in the clutch. I will definitely be keeping Nerdify in my back pocket and would recommend it to anyone else. Thanks, Nerdify!",
      nerdify: "Thanks for such amazing feedback! Please invite friends to earn referral bonuses ;)"
    },
    {
      name: "Christina Figueras",
      score: "5",
      date: "3 years ago",
      review: "I recommend the Nerds 100%. I am a mother, wife, and boss, in addition to being a student. It’s hard to get some things done when you are so busy. The Nerds can definitely help for a reasonable price, so I use them now and again. One time, I had an issue with one of my requests, but they fixed it immediately. If you get the Nerds to help you, you will not regret it!",
      nerdify: "Christina, thank you so much for sharing your feedback! We really appreciate it <3"
    },
    {
      name: "Syntishe Ndau",
      score: "5",
      date: "3 years ago",
      review: "Nerdify is the best service for anyone in school. Customer service is always excellent, tasks are completed quickly, and the prices are definitely reasonable.",
      nerdify: "Thank you! Please recommend us to friends who might need our help so you can earn bonuses to spend on your future tasks!"
    },
    {
      name: "Valerie Youssef",
      score: "5",
      date: "3 years ago",
      review: "Amazing experience with Nerdify. Definitely worth it!",
      nerdify: "Thanks, Valerie!"
    },
    {
      name: "Trudy Wiona",
      score: "5",
      date: "3 years ago",
      review: "Positive, efficient, and professional! Excellent and timely communication at all times. Thank you, Nerdify!"
    },
    {
      name: "Tiffany Nguyen",
      score: "5",
      date: "3 years ago",
      review: "Nerdify is awesome! It's my third time working with them, and they're worth it! The whole team was extremely communicative, and they responded within seconds! They even went out of their way to find relevant sources so I'd get exactly what I needed. If you need any help, go here! Nerdify is GREAT!",
      nerdify: "Thanks, Tiffany! Please invite friends to earn referral bonuses ;)"
    },

    {
      name: "Leysh Leysh",
      score: "5",
      date: "3 years ago",
      review: "Go with Nerdify! Definitely worth it.",
      nerdify: "Thank you!"
    },
    {
      name: "Mishelina Mifunga",
      score: "5",
      date: "3 years ago",
      review: "Prompt service. I recommend Nerdify!",
      nerdify: "Thanks!"
    },
    {
      name: "Elba Mojica",
      score: "5",
      date: "3 years ago",
      review: "I'm so glad I found Nerdify. It has been very convenient to use it for tasks. The process is easy and convenient, and the communication is great and available at any time, which makes me feel secure. I have become a loyal customer!",
      nerdify: "Thanks, please invite our friends to Nerdify to get bonuses!"
    },
    {
      name: "Jason Barbarosa",
      score: "5",
      date: "3 years ago",
      review: "I didn't know what to expect at first, but I was blown away by the experience.",
      nerdify: "Thanks, Jason!"
    },
    {
      name: "Scottian Washington",
      score: "5",
      date: "3 years ago",
      review: "Nerdify deserves more recognition, to be honest!",
      nerdify: "Thanks for your rating!"
    },
    {
      name: "Morgan Jones",
      score: "5",
      date: "3 years ago",
      review: "Nerdify was a very efficient website. They helped me complete a task that I had a very difficult time with. I would definitely recommend it to anyone in need of help!",
      nerdify: "Thanks, Morgan! Please recommend us to friends to get referral bonuses ;)"
    },

    {
      name: "Dewi Addo",
      score: "5",
      date: "3 years ago",
      review: "Budget-friendly yet excellent quality service! Glad I've found this website!",
      nerdify: "Thank you!"
    },
    {
      name: "Pretty Memeyou",
      score: "5",
      date: "3 years ago",
      review: "The Nerds are definitely experts! Glad I asked for their help!",
      nerdify: "Thanks!"
    },
    {
      name: "Robin Landing",
      score: "5",
      date: "3 years ago",
      review: "I would highly recommend Nerdify for your needs, as they were fast, efficient, and very professional. They responded quickly to my request and went above and beyond to deliver. I would definitely use them again.",
      nerdify: "Thanks, Robin! Feel free to bring your friends to get referral bonuses ;)"
    },
    {
      name: "Sara Williams",
      score: "5",
      date: "3 years ago",
      review: "Thankful to my friend for recommending this website. Such a great find!",
      nerdify: "Thank you, Sara!"
    },
    {
      name: "Maria Pavlatos",
      score: "5",
      date: "3 years ago",
      review: "Prompt and professional communication from start to finish. They've completed my request earlier than expected. I'm satisfied that all instructions were followed perfectly.",
      nerdify: "Thanks for your feedback, Maria!"
    },

    {
      name: "Nyiesha Jones",
      score: "5",
      date: "3 years ago",
      review: "Nerdify is a godsend!",
      nerdify: "Thank you!"
    },
    {
      name: "Joseph Rodriguez",
      score: "5",
      date: "3 years ago",
      review: "Love this service! Nerdify has awesome customer service and provides quality work! I would definitely recommend it to friends and anyone in need! It’s as easy as pulling out your phone and shooting a text! Try it, and you won’t be sorry!",
      nerdify: "Thanks for such great feedback, Joseph!"
    },
    {
      name: "Kira Barnes",
      score: "5",
      date: "3 years ago",
      review: "My experience with Nerdify was excellent! Great communication and customer service. I was able to get the help I needed. I can’t wait to use Nerdify again! Thank you!",
      nerdify: "We'll be very happy to see you back!"
    },
    {
      name: "Aliah Reid",
      score: "5",
      date: "3 years ago",
      review: "Nerdify rocks! I will always be back when I'm in a pinch!",
      nerdify: "Thank you, Aliah!"
    },
    {
      name: "David Kalish",
      score: "5",
      date: "3 years ago",
      review: "The best service provider of its kind that I've tried so far. The Nerd that helped me did a great job.",
      nerdify: "Thanks, David!"
    },

    {
      name: "Isaak Lane",
      score: "5",
      date: "4 years ago",
      review: "Fantastic communication, fast responses, I actually could hardly tell if they were automated, and even if they were, they were incredibly personalized even so. They asked detailed questions and got my request completed in a timely fashion.",
      nerdify: "Thank you so much, Isaak!"
    },
    {
      name: "Kenzie Huffaker",
      score: "5",
      date: "4 years ago",
      review: "Great service! They communicate with you every step of the way and provide excellent quality of work! I absolutely recommend Nerdify!",
      nerdify: "Thank you so much, Kenzie!"
    },
    {
      name: "Lindsey Crider",
      score: "5",
      date: "4 years ago",
      review: "My experience with Nerdify was absolutely amazing! My Nerd did a wonderful job and completed my task promptly. The response time is insane! Really good communication! I greatly appreciate all the hard work and effort they put into completing my order. It’s definitely well worth the money spent! Thank you so much! I definitely recommend and love Nerdify! I will be back!",
      nerdify: "We absolutely love your review, Lindsey! Please don't forget to refer your friends to get bonuses ;)"
    },
    {
      name: "PGM Micky",
      score: "5",
      date: "4 years ago",
      review: "It's my first time with Nerdify, and I don't regret using it. They respond quickly, and Nerds are always there to help you with whatever you need. Nerdify is amazing, and I definitely recommend it.",
      nerdify: "Thanks! You can also recommend Nerdify to friends to get bonuses ;)"
    },
    {
      name: "Blake Guthery",
      score: "5",
      date: "4 years ago",
      review: "I always receive my order on or before the due date. The Nerds are very transparent and communicate with you to discuss any questions or concerns.",
      nerdify: "Thanks, Blake!"
    },

    {
      name: "Sabrina Morgan",
      score: "4",
      date: "4 years ago",
      review: "Timely work from the Nerd who helped me.",
      nerdify: "Hey, Sabrina! What should we do better to get 5 stars from you?"
    },
    {
      name: "ReeAnn Burnett",
      score: "5",
      date: "4 years ago",
      review: "The experience was absolutely perfect! If I could rate Nerdify, it would be a 100/10! It's the best thing, and I absolutely love it. The response time is out of this world. I accidentally got charged $90 from my account, but it was resolved immediately. Not many apps offer support like this. Big help for college students in need of that extra help!",
      nerdify: "You can't even imagine how important these words are for our team. Thank you so much for finding time to share your experience <3"
    },
    {
      name: "Adam Khader",
      score: "5",
      date: "4 years ago",
      review: "They were very quick as they completed my order an hour before the expected time. I definitely recommend Nerdify!",
      nerdify: "Thanks, Adam!"
    },
    {
      name: "Beauty Torres",
      score: "5",
      date: "4 years ago",
      review: "Fantastic service from Nerdify!",
      nerdify: "Thanks!"
    },
    {
      name: "Kristofa Wyllie",
      score: "5",
      date: "4 years ago",
      review: "Very helpful website. I love it!",
      nerdify: "Thank you for rating us, Kristofa!"
    },
    {
      name: "Miya Quaye",
      score: "5",
      date: "4 years ago",
      review: "Thank you for the help, Nerdify!",
      nerdify: "Thanks for rating us, Miya!"
    },
    {
      name: "Jescika Harbert",
      score: "5",
      date: "4 years ago",
      review: "Smooth work with the Nerd that helped me!",
      nerdify: "Thanks for rating us!"
    },

    {
      name: "Asia Belt",
      score: "5",
      date: "4 years ago",
      review: "Thank you for all your help, Nerdify!",
      nerdify: "Thank YOU for finding time to rate us!"
    },
    {
      name: "Christina Philipp",
      score: "5",
      date: "4 years ago",
      review: "You guys did an amazing job. I will definitely use Nerdify again.",
      nerdify: "We will be happy to have you back, Christina! Thanks!"
    },
    {
      name: "Suman Jot",
      score: "5",
      date: "4 years ago",
      review: "Try their services! You won't be disappointed.",
      nerdify: "Thanks for your rating!"
    },
    {
      name: "Yaw Twumasi",
      score: "5",
      date: "4 years ago",
      review: "Nerds are experts at what they do. I'm satisfied.",
      nerdify: "Thanks for rating us <3"
    },
    {
      name: "Lindsey Miller",
      score: "5",
      date: "4 years ago",
      review: "I love Nerdify! Can't say that enough!",
      nerdify: "Thanks for your rating, Lindsey!"
    },
    {
      name: "Diana Galindo",
      score: "5",
      date: "4 years ago",
      review: "I am a student, mother, and wife who also juggles a career in the mix. I balance so many things in my daily life. Sometimes no matter how hard I work, I just don’t have the time to double-check my work. Nerdify definitely understands that. Nerds were super kind and very responsive. If I get stuck on a task, they're always there to help me out. They're amazing! I'm a returning client. Thank you, Nerdify!",
      nerdify: "Dear Diana, we are so happy to become a helping hand for such a \"Wonder Woman\" like you. Wishing you all the best and hope to continue being your go-to <3"
    },
    {
      name: "Yassah Mehn",
      score: "5",
      date: "4 years ago",
      review: "I'm glad I discovered Nerdify!",
      nerdify: "Thank you! Please use your referral link when recommending us to friends to get bonuses!"
    },
    {
      name: "Amy Ramey",
      score: "5",
      date: "4 years ago",
      review: "Great service from Nerdify. I will use again for sure!",
      nerdify: "Thanks, Amy! We'll be happy to assist you further."
    },

    {
      name: "Erica Hutchinson",
      score: "5",
      date: "4 years ago",
      review: "I appreciate Nerdify for coming up with services that give solutions to students' challenges! That said, I now know where to turn when I need expert assistance!",
      nerdify: "Thanks for the highest rating, Erica!"
    },
    {
      name: "Chuka Ndukwe",
      score: "5",
      date: "4 years ago",
      review: "Nerdify makes it easy to get support when you need it the most! They're quick and responsive!",
      nerdify: "Thanks!"
    },
    {
      name: "Ellouise Olson",
      score: "5",
      date: "4 years ago",
      review: "They're great from start to finish! Consistent and quick to help with adjustments. It felt great to have experts helping you!",
      nerdify: "Reviews like yours inspire us to do our job even better, thanks!"
    },
    {
      name: "Jasmine Sodhi",
      score: "5",
      date: "4 years ago",
      review: "Once you try Nerdify, you will always come back! Worth every penny!",
      nerdify: "Thanks, Jasmine!"
    },
    {
      name: "Mae Ferreyro",
      score: "5",
      date: "4 years ago",
      review: "Nerdify offers excellent services for a very reasonable price! It may seem a bit pricey at first, but if you consider the time response, communication, and most importantly, the quality of the work you receive, the fees are justifiable. I truly recommend their services!",
      nerdify: "Thanks, Mae! We offer special deals from time to time, please make sure you open all emails from us ;)"
    },
    {
      name: "Janera Harvey",
      score: "5",
      date: "4 years ago",
      review: "I keep coming back to Nerdify for all my needs! Life as a student can be so hard sometimes; good thing they're always available to help out!"
    },
    {
      name: "Chelsea K.",
      score: "5",
      date: "4 years ago",
      review: "Nerdify is amazing! I love that they have very knowledgeable and professional Nerds!",
      nerdify: "Thank you, Chelsea!"
    },
    {
      name: "Joleen Rordiguez",
      score: "5",
      date: "4 years ago",
      review: "I was hesitant to try their service, but it turned out that it was for nothing. Nerdify is a legitimate company that offers legitimate services. The Nerds are brilliant, and their customer service is impeccable. Good job!",
      nerdify: "Thanks for your feedback, Joleen!"
    },
    {
      name: "OuZeifath Soulemane",
      score: "5",
      date: "4 years ago",
      review: "I give five stars for this fantastic website. The Nerds saved me from a real failure. I was on the brink of failing on one of my subjects, but I managed to pass thanks to the Nerds who tutored me. They were very patient and smart, and I do not know how to thank them enough.",
      nerdify: "Thanks for your review!"
    },
    {
      name: "Evan Bang",
      score: "5",
      date: "4 years ago",
      review: "Awesome! I will definitely use this company's services again. The service was of high quality and, at the same time, affordable. It was a good deal, so you'd better enlist their help too.",
      nerdify: "Thanks for such high rating!"
    },
    {
      name: "Lindsay Mack",
      score: "5",
      date: "4 years ago",
      review: "The customer service of this company is one of the best I experienced. Whoever manages it is an asset to them. I contacted them late at night, but still, somebody answered to my request.",
      nerdify: "Thanks, Lindsay!"
    },
    {
      name: "Danielle Barron",
      score: "5",
      date: "4 years ago",
      review: "Good job! The Nerds did a great job concerning my request. They followed all of my requirements and adhered strictly to them. Highly recommend.",
      nerdify: "Thanks for your rating, Danielle!"
    },
    {
      name: "Maricza Ojeda",
      score: "5",
      date: "4 years ago",
      review: "Nerdify helped me save my performance in one of my courses. To the Nerds who helped me -, thank you very much.",
      nerdify: "We are very happy you are satisfied with the result!"
    },
    {
      name: "Adriana Mangual",
      score: "4",
      date: "4 years ago",
      review: "It's sometimes a gamble. The tasks that the Nerds can do range from average to the most challenging requests. It mainly depends on the Nerd who will do it, but, in general, they are competent and knowledgeable about the things they do.",
      nerdify: "Thanks for your feedback, Adriana! We are doing our best to constantly upgrade the quality of the services we provide. We do hope to meet your expectations next time!"
    },
    {
      name: "Nakita Baker",
      score: "5",
      date: "4 years ago",
      review: "Nerdify delivered my request swiftly. I will definitely enlist ask for their help again in the future.",
      nerdify: "We'll be happy to assist you further!"
    },
    {
      name: "Serena Palomino",
      score: "5",
      date: "4 years ago",
      review: "I needed assistance with a task, and the Nerds came through. They arranged a good deal and helped me solve my problem. I appreciate their service, their swift performance and delivery, and so, I highly recommend them.",
      nerdify: "We really appreciate your feedback, Serena! Thank you."
    },
    {
      name: "Diana Enriquez",
      score: "5",
      date: "4 years ago",
      review: "This website is a lifesaver. I was in a pinch with too much on my plate lately. A friend referred this website to me, so I tried it. And I don't regret my decision because the Nerds helped me a lot. The assistance they provided is more than I could ask for: the way they organized things, and how they suggested methods to improve my work.",
      nerdify: "Thanks for rating us, Diana!"
    },
    {
      name: "Christie Ortiz",
      score: "5",
      date: "4 years ago",
      review: "Great experience with Nerdify so far. I work at night, but despite this, they still responded to my texts! They helped me with some tasks in the past, and I couldn’t have been happier with the result. Sometimes we have to acknowledge that we need a little help!",
      nerdify: "Thanks, Christie!"
    },
    {
      name: "Carol Mills",
      score: "3",
      date: "4 years ago",
      review: "I paid 90 dollars, and what they gave me was way below my expectations.",
      nerdify: "Hey, Carol! If you have complaints about the quality of your task, please reach out to us! We have the whole team of experienced QAs who will check everything thoroughly."
    },
    {
      name: "Caroline Gonzalez",
      score: "5",
      date: "4 years ago",
      review: "The Nerds they assigned to me really know their stuff. I was shocked that they managed to finish my request in just a short period despite it being a difficult one. Highly recommended.",
      nerdify: "Thanks, Caroline!"
    },
    {
      name: "Cynthia Johnson",
      score: "5",
      date: "4 years ago",
      review: "I love them. The Nerds kept me informed on all my tasks. I wish I found them a long time ago because everything could have been different and more manageable. They are definitely the best!",
      nerdify: "Thank you so much, Cynthia!"
    },

    {
      name: "Lourdes Rodriguez",
      score: "5",
      date: "4 years ago",
      review: "The Nerds here in Nerdify are reliable people who can help you make your outputs better. A friend recommended this website so I tried it. Now, I have people who I can count on for assistance when I am having a hard time with my studies.",
      nerdify: "Thanks, Lourdes!"
    },
    {
      name: "Philippe Robert",
      score: "5",
      date: "4 years ago",
      review: "The Nerds were very helpful and patient. Their customer service is also fantastic.",
      nerdify: "Thanks!"
    },
    {
      name: "Stephanie Leonor",
      score: "5",
      date: "4 years ago",
      review: "I was amazed by the prompt submission made by the Nerd. I requested him for something as I have other things to do and after just 7 hours, he contacted me and said that he already finished my request. A big thumbs-up for the services of this company. Truly amazing!",
      nerdify: "Thanks, Stephanie!"
    },
    {
      name: "Cynthia Johnson",
      score: "5",
      date: "4 years ago",
      review: "I love them. The Nerds keep me informed on all of my tasks, and their help enabled me to achieve better results. I wish I found them a long time ago. They are the best!",
      nerdify: "Thank you so much, Cynthia!"
    },
    {
      name: "Cathy Pohoreske",
      score: "5",
      date: "4 years ago",
      review: "What I liked the most here is their customer service. The person assigned to customer service did a great job. He explained how their service works, and he was able to provide a solution to my concern.",
      nerdify: "Thanks, Cathy!"
    },
    {
      name: "Nicole Julia",
      score: "5",
      date: "4 years ago",
      review: "Their service is great. The price is a little high but worth it.",
      nerdify: "Don't forget that you can always get a referral bonus for inviting your friends! Thanks for the review."
    },
    {
      name: "Miguel Maciel",
      score: "5",
      date: "4 years ago",
      review: "I am the type of person who does not trust easily. At first, I thought it was sketchy, but as it turned out, it was not. So DON'T THINK TWICE ABOUT THIS. The Nerds who assisted me did everything perfectly. Customer service was great - I felt like I was talking to a friend. 10/10. I am sure to recommend this to my friends and use this again.",
      nerdify: "Thank you so much, Miguel!"
    },
    {
      name: "Loveely Saint-Flaire",
      score: "5",
      date: "4 years ago",
      review: "Thanks to the services offered by them, I was able to beat the due of my tasks. The Nerd forwarded my request way before the time I asked, and he followed all my instructions perfectly. I am sure to use their services again. Highly recommend!",
      nerdify: "Thank you!"
    },

    {
      name: "Kali King",
      score: "5",
      date: "4 years ago",
      review: "Nerdify did a magnificent job of assisting me with my tasks! The Nerds saved me a lot of time and stress! They replied to all of my texts in less than 2 mins! They were very prompt and thorough! I’m VERY impressed!",
      nerdify: "Thank you so much!"
    },
    {
      name: "Novalys Rivas",
      score: "5",
      date: "4 years ago",
      review: "I took risks asking for their help (since it was my first time), and it ended well. I am truly thankful for their assistance.",
      nerdify: "Thank you!"
    },
    {
      name: "Sabrina Kaissi",
      score: "5",
      date: "4 years ago",
      review: "I am so happy with the results! Definitely going to refer this website to everyone I know!:)",
      nerdify: "We'll be happy to help your friends too! Thanks, Sabrina!"
    },
    {
      name: "Artrilee Kennedy",
      score: "5",
      date: "4 years ago",
      review: "I was skeptical at first, but it ended up great. The customer service was magnificent. The Nerds understood the needed requirements, and thus my request were all fulfilled. The effort and assistance they've given were really great. Thumbs up for their dedication and professionalism. The price was reasonable too. Nerdify, thank you very much. I will most definitely use your services again.",
      nerdify: "We're looking forward to it!"
    },
    {
      name: "Christina Ray",
      score: "5",
      date: "4 years ago",
      review: "Nerdify has saved me this semester! I decided to work full time and go to school full time. I didn’t realize the amount of stress it would give to me and those around me. I discovered Nerdify a few months ago, and they have assisted me in finishing multiple tasks! The fee is reasonable, and their Nerds are very quick to reply and very knowledgeable (our collaborations gave me better results). Very pleased with them!",
      nerdify: "We are very happy we could help you out, Christina!"
    },
    {
      name: "Iman Ahmed",
      score: "5",
      date: "4 years ago",
      review: "Fast communication and great service! Definitely coming back to them again.",
      nerdify: "Thank you!"
    },
    {
      name: "Daisy Pimentel",
      score: "5",
      date: "4 years ago",
      review: "I love Nerdify! I would definitely recommend it!",
      nerdify: "Thank you, Daisy!"
    },
    {
      name: "Latasha Williams",
      score: "5",
      date: "4 years ago",
      review: "I asked for their assistance with a report since I was in a time crunch. The communication was magnificent. The Nerd followed the instructions, and there were no issues. I will definitely be using their service again. I would recommend it to anybody who wants quality work. The prices are reasonable too!",
      nerdify: "Thank you, Latasha!"
    },
    {
      name: "Yuliiana",
      score: "5",
      date: "4 years ago",
      review: "Just absolutely fantastic with assisting with tasks. The Nerds helped me so much with my homework that I’ve had trouble working on. Thank you!!",
      nerdify: "Thank you so much, Yuliana!"
    },
    {
      name: "John Waller",
      score: "5",
      date: "4 years ago",
      review: "Pretty solid service, they got me a satisfactory result and performance.",
      nerdify: "We'll be happy to see you back soon, John!"
    },
    {
      name: "Kimberly Mory",
      score: "5",
      date: "4 years ago",
      review: "I had a wonderful experience with this website!",
      nerdify: "Thanks for 5 stars, Kimberly!"
    },
    {
      name: "Yaniece Hasbun",
      score: "5",
      date: "4 years ago",
      review: "I’m a college student and I am working full time. I was looking up any website that helps Statistics and I found Nerdify. At first, I didn’t think this was legit but it is and they managed to help me out when I was in a tight spot. Now I feel a lot more confident about taking my exams!",
      nerdify: "Thank you for such great feedback!"
    },
    {
      name: "Blaze High",
      score: "5",
      date: "4 years ago",
      review: "I was a bit skeptical when contacting Nerdify. I’m in my first year at college and they’ve done remarkable work! I was just in awe at how quickly they’ve found tutors for me and the prices were very affordable. I say: don’t knock it until you try it! I will definitely continue to get help at Nerdify to balance my course load when things are overwhelming.",
      nerdify: "Thanks! We'll be really glad to see you back!"
    },
    {
      name: "PrincezzWayz Wayz",
      score: "5",
      date: "4 years ago",
      review: "I was a little nervous at first as you will with trying anything new. I was very impressed with the customer service, the speed of the work, and the work itself. Very professional and I would definitely be using them again!!!",
      nerdify: "So happy you liked your experience!"
    },
    {
      name: "Jose Justo",
      score: "5",
      date: "4 years ago",
      review: "Good service!",
      nerdify: "Thanks, Jose!"
    },
    {
      name: "Shaniquia Mclachlan",
      score: "5",
      date: "4 years ago",
      review: "Being a first-year college student is extremely hard especially since all my classes are online. It was extremely difficult to understand course materials efficiently. I struggled in silence until I came across Nerdify website. Nerdify has helped me and did a good job communicating the guidelines in order to complete my task. They are really professional and take pride in the type of work they give their customers. If you are hesitant about asking them for help, don’t be and when you ask for help you won’t regret it.",
      nerdify: "Can't wait to help you again <3"
    },
    {
      name: "Lauren Mae",
      score: "5",
      date: "4 years ago",
      review: "Most of the time, the quality of the work met my expectations. It would be considered falsification to say that they never fail, but mistakes happen to everybody. Just to be safe, I would suggest using the service ahead of time so that way the Nerds will have time to fix their mistakes, if necessary. They also have TOP performers as a post-option for an additional fee, which increases the chance of receiving a satisfactory final product if you are short on time and don't want to risk it. Overall, I am pretty satisfied with the service!",
      nerdify: "Thanks, Lauren!"
    },
    {
      name: "Kimia Farsa",
      score: "1",
      date: "4 years ago",
      review: "-",
      nerdify: "Hey-hey, could you please tell us what went wrong?"
    },
    {
      name: "TaiMarie Moore",
      score: "5",
      date: "4 years ago",
      review: "I needed help for my biology class and I didn’t have the energy to stay up all night to read all the materials. My Nerd helped me so much! His attitude and professionalism are totally worth the money.",
      nerdify: "Thanks, we'll be happy to see you back soon!"
    },
    {
      name: "Ethan Scherer",
      score: "5",
      date: "4 years ago",
      review: "I was able get in contact with someone 24/7. Once I made the decision that I would like them to help, they began right away! If you need help with a class, just contact them.",
      nerdify: "Thank you so much for your feedback, Ethan!"
    },
    {
      name: "D A",
      score: "5",
      date: "4 years ago",
      review: "Great service. Completed everything in a timely manner and the quality was great. Would recommend!",
      nerdify: "Thanks, we'll be happy to help you further!"
    },
    {
      name: "Ariana Gr.",
      score: "5",
      date: "4 years ago",
      review: "Sending love to my tutor who helped me a lot when I was struggling with my History course. Try tutoring here and you will not regret it!",
      nerdify: "Thanks! Can't wait to help you further <3"
    },
    {
      name: "Ben Mangus",
      score: "5",
      date: "4 years ago",
      review: "The help I received from Nerdify was top tier. The quality of work and friendliness of their representatives put my mind at ease. Thanks again!",
      nerdify: "Thank you so much for your feedback! Hope to see you back in the nearest future!"
    },
    {
      name: "Nasrin Amini",
      score: "5",
      date: "4 years ago",
      review: "Nerdify's reputation speaks for itself! Whenever I need a tutoring session for my classes I text and they find the best available Nerd to explain what I need or help me solve difficult tasks. Never ever failed me on that!",
      nerdify: "Thank you so much for your feedback! Hope to see you back in the nearest future!"
    },
    {
      name: "ERIC LOUIS",
      score: "5",
      date: "4 years ago",
      review: "It's not that I am too lazy to read my texbooks but you waste so much time on that! Why bother if you can order tutoring from a Nerd who explains everything in detail? Loving it!"
    },
    {
      name: "Shak Ra",
      score: "5",
      date: "4 years ago",
      review: "Because of covid-19, my classes were shifted to zoom and since then it's been really hard to understand the material. I sometimes use tutoring from Nerdify and Nerds help me a lot. I don't know what I'd do without them! Great service."
    },
    {
      name: "James Pitts",
      score: "5",
      date: "4 years ago",
      review: "Best Nerdify tutors are all here. I love their professionalism! Never thought I might tutoring sessions and yet I can't imagine my year without them."
    },
    {
      name: "Bayron R.",
      score: "5",
      date: "4 years ago",
      review: "I failed my course and had to take it all over again. I was desperate for tutoring help and my friend recommended going to Nerdify. God, that was my wisest decision. Their Nerds are amazing tutors! With their help, I managed to cope with all the material. The pricing is affordable, btw."
    },
    {
      name: "Bo Williams",
      score: "5",
      date: "4 years ago",
      review: "Professors teaching online is not the same thing. I lack explanations, examples and a possibility to ask questions. It's great to have Nerds at hand. They are even better than the offline tutoring I was considering for me."
    },
    {
      name: "JEREMY",
      score: "5",
      date: "4 years ago",
      review: "I am attending summer school this year but it seems like my professors do not take into account that I have a life to live aside the studies. I really miss having free tutoring sessions on campus, but gladly Nerdify provides them! They are not free, of course, but if you struggle with the workload - they are the best!"
    },
    {
      name: "Alicia P.",
      score: "1",
      date: "4 years ago",
      review: "Nerdify ignored the instructions I gave them. They asked for more time to fix what was wrong.",
      nerdify: "Hey there, from what I see in our client management system, a full refund was issued to your card right away. Additionally, our representative offered a code for a free service at Nerdify as a token of our apology for not delivering the top-notch quality service. I wish there was something we could do to make you feel better. Hope you give us a second chance to impress you!"
    },
    {
      name: "Jailene Claros",
      score: "5",
      date: "4 years ago",
      review: "They respond quickly to your needs and great communication throughout the whole process! Highly recommend it for any tasks!"
    },
    {
      name: "Harold Tamayo",
      score: "5",
      date: "4 years ago",
      review: "My lifesavers, definitely recommend them 100%!"
    },
    {
      name: "Amber Bernstein",
      score: "5",
      date: "4 years ago",
      review: "Always a pleasure working with them! Wouldn’t go anywhere else."
    },
    {
      name: "Bianca Lemus",
      score: "5",
      date: "4 years ago",
      review: "Great experience! They are fast and effective! Always there willing to help and guide you through any questions or concerns you may have!"
    },
    {
      name: "SUANN CAMPBELL",
      score: "5",
      date: "4 years ago",
      review: "I had an excellent experience using Nerdify. The quality of all services I requested was very good."
    },
    {
      name: "Tionne Allen",
      score: "5",
      date: "4 years ago",
      review: "My first experience was great. I was a little nervous but the person who I texted was really nice and professional. The price was worth it. They were quick and very smart. I was very happy and the most thing I enjoyed was the communication, and how Nerdify handled things appropriately. I will come back because they care and understand."
    },
    {
      score: "5",
      name: "Tiyannah Allen",
      review: "My tutor did great job. I will recommend Nerdify to everyone because they're the ones to go to get help with all classes. I had the top tutor and the price was very reasonable \uD83D\uDE42 He was extremely intelligent in Physiology and Anatomy. I plan on coming back to Nerdify. Thanks for the help \uD83D\uDE0A",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Monique Gooden",
      review: "They are smart and helpful with whatever problems you have. I will definitely be back.",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Lana Abdalmajid",
      review: "They are the best, they send me my materials and all the solutions on time and sometimes even earlier than we agreed upon.",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Conor Cantwell",
      review: "Couldn't have been more pleased with the service I received! The tutors are extremely professional and more than helpful.  The numerous tasks and great results on all of them speak for themselves.",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "2",
      name: "Ngawang Sherpa",
      review: "At first I thought they’re genuine and a good service to use but later found out that they are a waste of time. I provided them the request and they said they’d need 4 hours to complete it. The guy messaged me and said the Nerd declined the work they need 4 more hours. These guys don’t deserve 5 stars.",
      nerdify: "Hey there, I am so sorry about this whole situation that made you feel stressed. It's true that we've experienced a high workload recently and some of our Nerds are overwhelmed. However, this should not in any case affect the positive experience we want you to have each time you want Nerdify to help you out. I assure that this is of a paramount importance for us to investigate your case and compensate you accordingly.",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Venera Manukyan",
      review: "They respond to your requests very quickly & find someone to help you in a very reasonable amount of time! They get the job done exactly how you want it, so be specific when requesting! Thanks!",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Edward Hernandez",
      review: "I have been using Nerdify for 2 years now. To me, their tutors are talented and they do everything on time with professionalism. They always help me and never leave me! Thank you guys, I will always recommend your services.",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Ross Whitlock",
      review: "I wasn't sure this was going to be a good decision to use Nerdify, but it turned out to be the best decision. It's reliable and costs relatively inexpensive. I wil be using Nerdify much more.",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Noelle Grace",
      review: "Highly recommended, super professional and easy! Top-notch service that is literally available any time! I love the customer service and will definitely continue to use Nerdify!",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Sezz Kayyy",
      review: "Awesome and very impressive work they provide! They have excellent response time, amazing customer service and  great quality work. I would definitely recommend them!",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Polo",
      review: "Nerdify is a great resource for any student. They are very attentive and supportive, I can always count on them to help me with all the tasks. I would highly recommend them.",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "renzi v",
      review: "At first I found this service to be very sketchy but the representative was very supportive and helpful. From service to support, everything is great as well as time-efficient.",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Jibi Thomas",
      review: "Respond quickly! Very helpful and polite. Asked for help last minute and they went above and beyond my expectations. Really appreciate them. Will definitely be using their services again!",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Kindra Hembree",
      review: "I have only used Nerdify once and I was very pleased with my experience, I plan on using them again when I’m in a tight spot!",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "HERALD CHARLEY",
      review: "Nerdify is very helpful when it comes to getting assistance for classes. I highly recommend them whenever you need help. They helped me out with a lot of my tasks and I am grateful for their help.",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "Z Mohammed",
      review: "Prices are very fair & quality of work is awesome. I have got help from Nerdify with several tasks. They are always there for you. Thank you, Nerdify!",
      nerdify: "",
      date: "4 years ago"
    },
    {
      score: "5",
      name: "tee h",
      review: "Always there when you need them. Quick response at all times of the night. Great quality work, used them several times!",
      nerdify: "",
      date: "4 years ago"
    },
  ]
};

export default google;
